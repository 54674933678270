
.side-panel-panel {
    margin-top: $branding-top-bar-height;
    display: flex;
    justify-content: flex-end;

    // Causes the component that is added to the CDK overlay to occupy the whole width
    > :first-child {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
    }
}
