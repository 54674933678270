@charset "UTF-8";
meta.foundation-version {
  font-family: "/5.5.1/";
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em;
}

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 39.937em)/";
  width: 0em;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40em)/";
  width: 40em;
}

meta.foundation-mq-medium-only {
  font-family: "/only screen and (min-width:40em) and (max-width:63.397em)/";
  width: 40em;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64em)/";
  width: 64em;
}

meta.foundation-mq-large-only {
  font-family: "/only screen and (min-width:64em) and (max-width:90em)/";
  width: 64em;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em;
}

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:90.063em) and (max-width:120em)/";
  width: 90.063em;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

html, body {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
}

body {
  background: #FAFAFA;
  color: #212121;
  padding: 0;
  margin: 0;
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  position: relative;
  cursor: auto;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 6.25rem;
}
.row:before, .row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}
.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}
.row .row {
  width: auto;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
}
.row .row:before, .row .row:after {
  content: " ";
  display: table;
}
.row .row:after {
  clear: both;
}
.row .row.collapse {
  width: auto;
  margin: 0;
  max-width: none;
}
.row .row.collapse:before, .row .row.collapse:after {
  content: " ";
  display: table;
}
.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  float: left;
}

[class*=column] + [class*=column]:last-child {
  float: right;
}

[class*=column] + [class*=column].end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }
  .small-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }
  .small-push-1 {
    position: relative;
    left: 6.25%;
    right: auto;
  }
  .small-pull-1 {
    position: relative;
    right: 6.25%;
    left: auto;
  }
  .small-push-2 {
    position: relative;
    left: 12.5%;
    right: auto;
  }
  .small-pull-2 {
    position: relative;
    right: 12.5%;
    left: auto;
  }
  .small-push-3 {
    position: relative;
    left: 18.75%;
    right: auto;
  }
  .small-pull-3 {
    position: relative;
    right: 18.75%;
    left: auto;
  }
  .small-push-4 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .small-pull-4 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .small-push-5 {
    position: relative;
    left: 31.25%;
    right: auto;
  }
  .small-pull-5 {
    position: relative;
    right: 31.25%;
    left: auto;
  }
  .small-push-6 {
    position: relative;
    left: 37.5%;
    right: auto;
  }
  .small-pull-6 {
    position: relative;
    right: 37.5%;
    left: auto;
  }
  .small-push-7 {
    position: relative;
    left: 43.75%;
    right: auto;
  }
  .small-pull-7 {
    position: relative;
    right: 43.75%;
    left: auto;
  }
  .small-push-8 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .small-pull-8 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .small-push-9 {
    position: relative;
    left: 56.25%;
    right: auto;
  }
  .small-pull-9 {
    position: relative;
    right: 56.25%;
    left: auto;
  }
  .small-push-10 {
    position: relative;
    left: 62.5%;
    right: auto;
  }
  .small-pull-10 {
    position: relative;
    right: 62.5%;
    left: auto;
  }
  .small-push-11 {
    position: relative;
    left: 68.75%;
    right: auto;
  }
  .small-pull-11 {
    position: relative;
    right: 68.75%;
    left: auto;
  }
  .small-push-12 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .small-pull-12 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .small-push-13 {
    position: relative;
    left: 81.25%;
    right: auto;
  }
  .small-pull-13 {
    position: relative;
    right: 81.25%;
    left: auto;
  }
  .small-push-14 {
    position: relative;
    left: 87.5%;
    right: auto;
  }
  .small-pull-14 {
    position: relative;
    right: 87.5%;
    left: auto;
  }
  .small-push-15 {
    position: relative;
    left: 93.75%;
    right: auto;
  }
  .small-pull-15 {
    position: relative;
    right: 93.75%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
  .small-1 {
    width: 6.25%;
  }
  .small-2 {
    width: 12.5%;
  }
  .small-3 {
    width: 18.75%;
  }
  .small-4 {
    width: 25%;
  }
  .small-5 {
    width: 31.25%;
  }
  .small-6 {
    width: 37.5%;
  }
  .small-7 {
    width: 43.75%;
  }
  .small-8 {
    width: 50%;
  }
  .small-9 {
    width: 56.25%;
  }
  .small-10 {
    width: 62.5%;
  }
  .small-11 {
    width: 68.75%;
  }
  .small-12 {
    width: 75%;
  }
  .small-13 {
    width: 81.25%;
  }
  .small-14 {
    width: 87.5%;
  }
  .small-15 {
    width: 93.75%;
  }
  .small-16 {
    width: 100%;
  }
  .small-offset-0 {
    margin-left: 0% !important;
  }
  .small-offset-1 {
    margin-left: 6.25% !important;
  }
  .small-offset-2 {
    margin-left: 12.5% !important;
  }
  .small-offset-3 {
    margin-left: 18.75% !important;
  }
  .small-offset-4 {
    margin-left: 25% !important;
  }
  .small-offset-5 {
    margin-left: 31.25% !important;
  }
  .small-offset-6 {
    margin-left: 37.5% !important;
  }
  .small-offset-7 {
    margin-left: 43.75% !important;
  }
  .small-offset-8 {
    margin-left: 50% !important;
  }
  .small-offset-9 {
    margin-left: 56.25% !important;
  }
  .small-offset-10 {
    margin-left: 62.5% !important;
  }
  .small-offset-11 {
    margin-left: 68.75% !important;
  }
  .small-offset-12 {
    margin-left: 75% !important;
  }
  .small-offset-13 {
    margin-left: 81.25% !important;
  }
  .small-offset-14 {
    margin-left: 87.5% !important;
  }
  .small-offset-15 {
    margin-left: 93.75% !important;
  }
  .small-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }
  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.small-uncentered,
  .columns.small-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none;
  }
  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left;
  }
  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }
  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
}
@media only screen and (min-width:40em) {
  .medium-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }
  .medium-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }
  .medium-push-1 {
    position: relative;
    left: 6.25%;
    right: auto;
  }
  .medium-pull-1 {
    position: relative;
    right: 6.25%;
    left: auto;
  }
  .medium-push-2 {
    position: relative;
    left: 12.5%;
    right: auto;
  }
  .medium-pull-2 {
    position: relative;
    right: 12.5%;
    left: auto;
  }
  .medium-push-3 {
    position: relative;
    left: 18.75%;
    right: auto;
  }
  .medium-pull-3 {
    position: relative;
    right: 18.75%;
    left: auto;
  }
  .medium-push-4 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .medium-pull-4 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .medium-push-5 {
    position: relative;
    left: 31.25%;
    right: auto;
  }
  .medium-pull-5 {
    position: relative;
    right: 31.25%;
    left: auto;
  }
  .medium-push-6 {
    position: relative;
    left: 37.5%;
    right: auto;
  }
  .medium-pull-6 {
    position: relative;
    right: 37.5%;
    left: auto;
  }
  .medium-push-7 {
    position: relative;
    left: 43.75%;
    right: auto;
  }
  .medium-pull-7 {
    position: relative;
    right: 43.75%;
    left: auto;
  }
  .medium-push-8 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .medium-pull-8 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .medium-push-9 {
    position: relative;
    left: 56.25%;
    right: auto;
  }
  .medium-pull-9 {
    position: relative;
    right: 56.25%;
    left: auto;
  }
  .medium-push-10 {
    position: relative;
    left: 62.5%;
    right: auto;
  }
  .medium-pull-10 {
    position: relative;
    right: 62.5%;
    left: auto;
  }
  .medium-push-11 {
    position: relative;
    left: 68.75%;
    right: auto;
  }
  .medium-pull-11 {
    position: relative;
    right: 68.75%;
    left: auto;
  }
  .medium-push-12 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .medium-pull-12 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .medium-push-13 {
    position: relative;
    left: 81.25%;
    right: auto;
  }
  .medium-pull-13 {
    position: relative;
    right: 81.25%;
    left: auto;
  }
  .medium-push-14 {
    position: relative;
    left: 87.5%;
    right: auto;
  }
  .medium-pull-14 {
    position: relative;
    right: 87.5%;
    left: auto;
  }
  .medium-push-15 {
    position: relative;
    left: 93.75%;
    right: auto;
  }
  .medium-pull-15 {
    position: relative;
    right: 93.75%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
  .medium-1 {
    width: 6.25%;
  }
  .medium-2 {
    width: 12.5%;
  }
  .medium-3 {
    width: 18.75%;
  }
  .medium-4 {
    width: 25%;
  }
  .medium-5 {
    width: 31.25%;
  }
  .medium-6 {
    width: 37.5%;
  }
  .medium-7 {
    width: 43.75%;
  }
  .medium-8 {
    width: 50%;
  }
  .medium-9 {
    width: 56.25%;
  }
  .medium-10 {
    width: 62.5%;
  }
  .medium-11 {
    width: 68.75%;
  }
  .medium-12 {
    width: 75%;
  }
  .medium-13 {
    width: 81.25%;
  }
  .medium-14 {
    width: 87.5%;
  }
  .medium-15 {
    width: 93.75%;
  }
  .medium-16 {
    width: 100%;
  }
  .medium-offset-0 {
    margin-left: 0% !important;
  }
  .medium-offset-1 {
    margin-left: 6.25% !important;
  }
  .medium-offset-2 {
    margin-left: 12.5% !important;
  }
  .medium-offset-3 {
    margin-left: 18.75% !important;
  }
  .medium-offset-4 {
    margin-left: 25% !important;
  }
  .medium-offset-5 {
    margin-left: 31.25% !important;
  }
  .medium-offset-6 {
    margin-left: 37.5% !important;
  }
  .medium-offset-7 {
    margin-left: 43.75% !important;
  }
  .medium-offset-8 {
    margin-left: 50% !important;
  }
  .medium-offset-9 {
    margin-left: 56.25% !important;
  }
  .medium-offset-10 {
    margin-left: 62.5% !important;
  }
  .medium-offset-11 {
    margin-left: 68.75% !important;
  }
  .medium-offset-12 {
    margin-left: 75% !important;
  }
  .medium-offset-13 {
    margin-left: 81.25% !important;
  }
  .medium-offset-14 {
    margin-left: 87.5% !important;
  }
  .medium-offset-15 {
    margin-left: 93.75% !important;
  }
  .medium-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }
  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.medium-uncentered,
  .columns.medium-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none;
  }
  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left;
  }
  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }
  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
  .push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }
  .pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }
  .push-1 {
    position: relative;
    left: 6.25%;
    right: auto;
  }
  .pull-1 {
    position: relative;
    right: 6.25%;
    left: auto;
  }
  .push-2 {
    position: relative;
    left: 12.5%;
    right: auto;
  }
  .pull-2 {
    position: relative;
    right: 12.5%;
    left: auto;
  }
  .push-3 {
    position: relative;
    left: 18.75%;
    right: auto;
  }
  .pull-3 {
    position: relative;
    right: 18.75%;
    left: auto;
  }
  .push-4 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .pull-4 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .push-5 {
    position: relative;
    left: 31.25%;
    right: auto;
  }
  .pull-5 {
    position: relative;
    right: 31.25%;
    left: auto;
  }
  .push-6 {
    position: relative;
    left: 37.5%;
    right: auto;
  }
  .pull-6 {
    position: relative;
    right: 37.5%;
    left: auto;
  }
  .push-7 {
    position: relative;
    left: 43.75%;
    right: auto;
  }
  .pull-7 {
    position: relative;
    right: 43.75%;
    left: auto;
  }
  .push-8 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .pull-8 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .push-9 {
    position: relative;
    left: 56.25%;
    right: auto;
  }
  .pull-9 {
    position: relative;
    right: 56.25%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 62.5%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 62.5%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 68.75%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 68.75%;
    left: auto;
  }
  .push-12 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .pull-12 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .push-13 {
    position: relative;
    left: 81.25%;
    right: auto;
  }
  .pull-13 {
    position: relative;
    right: 81.25%;
    left: auto;
  }
  .push-14 {
    position: relative;
    left: 87.5%;
    right: auto;
  }
  .pull-14 {
    position: relative;
    right: 87.5%;
    left: auto;
  }
  .push-15 {
    position: relative;
    left: 93.75%;
    right: auto;
  }
  .pull-15 {
    position: relative;
    right: 93.75%;
    left: auto;
  }
}
@media only screen and (min-width:64em) {
  .large-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }
  .large-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }
  .large-push-1 {
    position: relative;
    left: 6.25%;
    right: auto;
  }
  .large-pull-1 {
    position: relative;
    right: 6.25%;
    left: auto;
  }
  .large-push-2 {
    position: relative;
    left: 12.5%;
    right: auto;
  }
  .large-pull-2 {
    position: relative;
    right: 12.5%;
    left: auto;
  }
  .large-push-3 {
    position: relative;
    left: 18.75%;
    right: auto;
  }
  .large-pull-3 {
    position: relative;
    right: 18.75%;
    left: auto;
  }
  .large-push-4 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .large-pull-4 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .large-push-5 {
    position: relative;
    left: 31.25%;
    right: auto;
  }
  .large-pull-5 {
    position: relative;
    right: 31.25%;
    left: auto;
  }
  .large-push-6 {
    position: relative;
    left: 37.5%;
    right: auto;
  }
  .large-pull-6 {
    position: relative;
    right: 37.5%;
    left: auto;
  }
  .large-push-7 {
    position: relative;
    left: 43.75%;
    right: auto;
  }
  .large-pull-7 {
    position: relative;
    right: 43.75%;
    left: auto;
  }
  .large-push-8 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .large-pull-8 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .large-push-9 {
    position: relative;
    left: 56.25%;
    right: auto;
  }
  .large-pull-9 {
    position: relative;
    right: 56.25%;
    left: auto;
  }
  .large-push-10 {
    position: relative;
    left: 62.5%;
    right: auto;
  }
  .large-pull-10 {
    position: relative;
    right: 62.5%;
    left: auto;
  }
  .large-push-11 {
    position: relative;
    left: 68.75%;
    right: auto;
  }
  .large-pull-11 {
    position: relative;
    right: 68.75%;
    left: auto;
  }
  .large-push-12 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .large-pull-12 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .large-push-13 {
    position: relative;
    left: 81.25%;
    right: auto;
  }
  .large-pull-13 {
    position: relative;
    right: 81.25%;
    left: auto;
  }
  .large-push-14 {
    position: relative;
    left: 87.5%;
    right: auto;
  }
  .large-pull-14 {
    position: relative;
    right: 87.5%;
    left: auto;
  }
  .large-push-15 {
    position: relative;
    left: 93.75%;
    right: auto;
  }
  .large-pull-15 {
    position: relative;
    right: 93.75%;
    left: auto;
  }
  .column,
  .columns {
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
  .large-1 {
    width: 6.25%;
  }
  .large-2 {
    width: 12.5%;
  }
  .large-3 {
    width: 18.75%;
  }
  .large-4 {
    width: 25%;
  }
  .large-5 {
    width: 31.25%;
  }
  .large-6 {
    width: 37.5%;
  }
  .large-7 {
    width: 43.75%;
  }
  .large-8 {
    width: 50%;
  }
  .large-9 {
    width: 56.25%;
  }
  .large-10 {
    width: 62.5%;
  }
  .large-11 {
    width: 68.75%;
  }
  .large-12 {
    width: 75%;
  }
  .large-13 {
    width: 81.25%;
  }
  .large-14 {
    width: 87.5%;
  }
  .large-15 {
    width: 93.75%;
  }
  .large-16 {
    width: 100%;
  }
  .large-offset-0 {
    margin-left: 0% !important;
  }
  .large-offset-1 {
    margin-left: 6.25% !important;
  }
  .large-offset-2 {
    margin-left: 12.5% !important;
  }
  .large-offset-3 {
    margin-left: 18.75% !important;
  }
  .large-offset-4 {
    margin-left: 25% !important;
  }
  .large-offset-5 {
    margin-left: 31.25% !important;
  }
  .large-offset-6 {
    margin-left: 37.5% !important;
  }
  .large-offset-7 {
    margin-left: 43.75% !important;
  }
  .large-offset-8 {
    margin-left: 50% !important;
  }
  .large-offset-9 {
    margin-left: 56.25% !important;
  }
  .large-offset-10 {
    margin-left: 62.5% !important;
  }
  .large-offset-11 {
    margin-left: 68.75% !important;
  }
  .large-offset-12 {
    margin-left: 75% !important;
  }
  .large-offset-13 {
    margin-left: 81.25% !important;
  }
  .large-offset-14 {
    margin-left: 87.5% !important;
  }
  .large-offset-15 {
    margin-left: 93.75% !important;
  }
  .large-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }
  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .column.large-uncentered,
  .columns.large-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none;
  }
  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left;
  }
  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }
  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    float: left;
  }
  .push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }
  .pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }
  .push-1 {
    position: relative;
    left: 6.25%;
    right: auto;
  }
  .pull-1 {
    position: relative;
    right: 6.25%;
    left: auto;
  }
  .push-2 {
    position: relative;
    left: 12.5%;
    right: auto;
  }
  .pull-2 {
    position: relative;
    right: 12.5%;
    left: auto;
  }
  .push-3 {
    position: relative;
    left: 18.75%;
    right: auto;
  }
  .pull-3 {
    position: relative;
    right: 18.75%;
    left: auto;
  }
  .push-4 {
    position: relative;
    left: 25%;
    right: auto;
  }
  .pull-4 {
    position: relative;
    right: 25%;
    left: auto;
  }
  .push-5 {
    position: relative;
    left: 31.25%;
    right: auto;
  }
  .pull-5 {
    position: relative;
    right: 31.25%;
    left: auto;
  }
  .push-6 {
    position: relative;
    left: 37.5%;
    right: auto;
  }
  .pull-6 {
    position: relative;
    right: 37.5%;
    left: auto;
  }
  .push-7 {
    position: relative;
    left: 43.75%;
    right: auto;
  }
  .pull-7 {
    position: relative;
    right: 43.75%;
    left: auto;
  }
  .push-8 {
    position: relative;
    left: 50%;
    right: auto;
  }
  .pull-8 {
    position: relative;
    right: 50%;
    left: auto;
  }
  .push-9 {
    position: relative;
    left: 56.25%;
    right: auto;
  }
  .pull-9 {
    position: relative;
    right: 56.25%;
    left: auto;
  }
  .push-10 {
    position: relative;
    left: 62.5%;
    right: auto;
  }
  .pull-10 {
    position: relative;
    right: 62.5%;
    left: auto;
  }
  .push-11 {
    position: relative;
    left: 68.75%;
    right: auto;
  }
  .pull-11 {
    position: relative;
    right: 68.75%;
    left: auto;
  }
  .push-12 {
    position: relative;
    left: 75%;
    right: auto;
  }
  .pull-12 {
    position: relative;
    right: 75%;
    left: auto;
  }
  .push-13 {
    position: relative;
    left: 81.25%;
    right: auto;
  }
  .pull-13 {
    position: relative;
    right: 81.25%;
    left: auto;
  }
  .push-14 {
    position: relative;
    left: 87.5%;
    right: auto;
  }
  .pull-14 {
    position: relative;
    right: 87.5%;
    left: auto;
  }
  .push-15 {
    position: relative;
    left: 93.75%;
    right: auto;
  }
  .pull-15 {
    position: relative;
    right: 93.75%;
    left: auto;
  }
}
.accordion {
  margin-bottom: 0;
}
.accordion:before, .accordion:after {
  content: " ";
  display: table;
}
.accordion:after {
  clear: both;
}
.accordion .accordion-navigation, .accordion dd {
  display: block;
  margin-bottom: 0 !important;
}
.accordion .accordion-navigation.active > a, .accordion dd.active > a {
  background: transparent;
}
.accordion .accordion-navigation > a, .accordion dd > a {
  background: transparent;
  color: #212121;
  padding: 0 0 0 0.1875rem;
  display: block;
  font-family: "AvenirNextLTPro", sans-serif;
  font-size: 0.75rem;
}
.accordion .accordion-navigation > a:hover, .accordion dd > a:hover {
  background: #BDBDBD;
}
.accordion .accordion-navigation > .content, .accordion dd > .content {
  display: none;
  padding: 0;
}
.accordion .accordion-navigation > .content.active, .accordion dd > .content.active {
  display: block;
  background: #FFFFFF;
}

.alert-box {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-weight: 400;
  margin-bottom: 1.25rem;
  position: relative;
  padding: 0.875rem 1.5rem 0.875rem 0.875rem;
  font-size: 0.8125rem;
  transition: opacity 300ms ease-out;
  background-color: #2F9ACD;
  border-color: #2884b0;
  color: #FFFFFF;
}
.alert-box .close {
  font-size: 1.375rem;
  padding: 0 6px 4px;
  line-height: 0.9;
  position: absolute;
  top: 50%;
  margin-top: -0.6875rem;
  right: 0.25rem;
  color: #333333;
  opacity: 0.3;
  background: inherit;
}
.alert-box .close:hover, .alert-box .close:focus {
  opacity: 0.5;
}
.alert-box.radius {
  border-radius: 3px;
}
.alert-box.round {
  border-radius: 1000px;
}
.alert-box.success {
  background-color: #8BB741;
  border-color: #789d38;
  color: #FFFFFF;
}
.alert-box.alert {
  background-color: #DF5146;
  border-color: #d73225;
  color: #FFFFFF;
}
.alert-box.secondary {
  background-color: #616161;
  border-color: #535353;
  color: #FFFFFF;
}
.alert-box.warning {
  background-color: #FEA82F;
  border-color: #fe9605;
  color: #FFFFFF;
}
.alert-box.info {
  background-color: #757575;
  border-color: #656565;
  color: #FFFFFF;
}
.alert-box.alert-close {
  opacity: 0;
}

[class*=block-grid-] {
  display: block;
  padding: 0;
  margin: 0 -0.5rem;
}
[class*=block-grid-]:before, [class*=block-grid-]:after {
  content: " ";
  display: table;
}
[class*=block-grid-]:after {
  clear: both;
}
[class*=block-grid-] > li {
  display: block;
  height: auto;
  float: left;
  padding: 0 0.5rem 1rem;
}

@media only screen {
  .small-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }
  .small-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .small-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }
  .small-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .small-block-grid-3 > li {
    width: 33.3333333333%;
    list-style: none;
  }
  .small-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .small-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }
  .small-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .small-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }
  .small-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .small-block-grid-6 > li {
    width: 16.6666666667%;
    list-style: none;
  }
  .small-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .small-block-grid-7 > li {
    width: 14.2857142857%;
    list-style: none;
  }
  .small-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .small-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }
  .small-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .small-block-grid-9 > li {
    width: 11.1111111111%;
    list-style: none;
  }
  .small-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .small-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }
  .small-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .small-block-grid-11 > li {
    width: 9.0909090909%;
    list-style: none;
  }
  .small-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .small-block-grid-12 > li {
    width: 8.3333333333%;
    list-style: none;
  }
  .small-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}
@media only screen and (min-width:40em) {
  .medium-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }
  .medium-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }
  .medium-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-block-grid-3 > li {
    width: 33.3333333333%;
    list-style: none;
  }
  .medium-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }
  .medium-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }
  .medium-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-block-grid-6 > li {
    width: 16.6666666667%;
    list-style: none;
  }
  .medium-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-block-grid-7 > li {
    width: 14.2857142857%;
    list-style: none;
  }
  .medium-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }
  .medium-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-block-grid-9 > li {
    width: 11.1111111111%;
    list-style: none;
  }
  .medium-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .medium-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }
  .medium-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .medium-block-grid-11 > li {
    width: 9.0909090909%;
    list-style: none;
  }
  .medium-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .medium-block-grid-12 > li {
    width: 8.3333333333%;
    list-style: none;
  }
  .medium-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}
@media only screen and (min-width:64em) {
  .large-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }
  .large-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }
  .large-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }
  .large-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }
  .large-block-grid-3 > li {
    width: 33.3333333333%;
    list-style: none;
  }
  .large-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }
  .large-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }
  .large-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }
  .large-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }
  .large-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }
  .large-block-grid-6 > li {
    width: 16.6666666667%;
    list-style: none;
  }
  .large-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }
  .large-block-grid-7 > li {
    width: 14.2857142857%;
    list-style: none;
  }
  .large-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }
  .large-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }
  .large-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }
  .large-block-grid-9 > li {
    width: 11.1111111111%;
    list-style: none;
  }
  .large-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }
  .large-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }
  .large-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }
  .large-block-grid-11 > li {
    width: 9.0909090909%;
    list-style: none;
  }
  .large-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }
  .large-block-grid-12 > li {
    width: 8.3333333333%;
    list-style: none;
  }
  .large-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }
  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}
.breadcrumbs {
  display: block;
  padding: 0.5625rem 0.875rem 0.5625rem;
  overflow: hidden;
  margin-left: 0;
  list-style: none;
  border-style: solid;
  border-width: 1px;
  background-color: #b8b8b8;
  border-color: #a6a6a6;
  border-radius: 3px;
}
.breadcrumbs > * {
  margin: 0;
  float: left;
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  text-transform: uppercase;
  color: #2F9ACD;
}
.breadcrumbs > *:hover a, .breadcrumbs > *:focus a {
  text-decoration: underline;
}
.breadcrumbs > * a {
  color: #2F9ACD;
}
.breadcrumbs > *.current {
  cursor: default;
  color: #333333;
}
.breadcrumbs > *.current a {
  cursor: default;
  color: #333333;
}
.breadcrumbs > *.current:hover, .breadcrumbs > *.current:hover a, .breadcrumbs > *.current:focus, .breadcrumbs > *.current:focus a {
  text-decoration: none;
}
.breadcrumbs > *.unavailable {
  color: #999999;
}
.breadcrumbs > *.unavailable a {
  color: #999999;
}
.breadcrumbs > *.unavailable:hover, .breadcrumbs > *.unavailable:hover a, .breadcrumbs > *.unavailable:focus,
.breadcrumbs > *.unavailable a:focus {
  text-decoration: none;
  color: #999999;
  cursor: not-allowed;
}
.breadcrumbs > *:before {
  content: "/";
  color: #AAAAAA;
  margin: 0 0.75rem;
  position: relative;
  top: 1px;
}
.breadcrumbs > *:first-child:before {
  content: " ";
  margin: 0;
}

/* Accessibility - hides the forward slash */
[aria-label=breadcrumbs] [aria-hidden=true]:after {
  content: "/";
}

button, .button {
  border-style: solid;
  border-width: 0;
  cursor: pointer;
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0;
  position: relative;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  display: inline-block;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5625rem;
  padding-left: 1rem;
  font-size: 1rem;
  background-color: #2F9ACD;
  border-color: #267ba4;
  color: #FFFFFF;
  transition: background-color 300ms ease-out;
}
button:hover, button:focus, .button:hover, .button:focus {
  background-color: #267ba4;
}
button:hover, button:focus, .button:hover, .button:focus {
  color: #FFFFFF;
}
button.secondary, .button.secondary {
  background-color: #616161;
  border-color: #4e4e4e;
  color: #FFFFFF;
}
button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  background-color: #4e4e4e;
}
button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  color: #FFFFFF;
}
button.success, .button.success {
  background-color: #8BB741;
  border-color: #6f9234;
  color: #FFFFFF;
}
button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  background-color: #6f9234;
}
button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  color: #FFFFFF;
}
button.alert, .button.alert {
  background-color: #DF5146;
  border-color: #c82e23;
  color: #FFFFFF;
}
button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  background-color: #c82e23;
}
button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  color: #FFFFFF;
}
button.warning, .button.warning {
  background-color: #FEA82F;
  border-color: #f08d01;
  color: #FFFFFF;
}
button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  background-color: #f08d01;
}
button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  color: #FFFFFF;
}
button.info, .button.info {
  background-color: #757575;
  border-color: #5e5e5e;
  color: #FFFFFF;
}
button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  background-color: #5e5e5e;
}
button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  color: #FFFFFF;
}
button.large, .button.large {
  padding-top: 0.5625rem;
  padding-right: 1.125rem;
  padding-bottom: 0.625rem;
  padding-left: 1.125rem;
  font-size: 1.25rem;
}
button.small, .button.small {
  padding-top: 0.4375rem;
  padding-right: 0.875rem;
  padding-bottom: 0.5rem;
  padding-left: 0.875rem;
  font-size: 0.8125rem;
}
button.tiny, .button.tiny {
  padding-top: 0.3125rem;
  padding-right: 0.625rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  font-size: 0.6875rem;
}
button.expand, .button.expand {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}
button.left-align, .button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}
button.right-align, .button.right-align {
  text-align: right;
  padding-right: 0.75rem;
}
button.radius, .button.radius {
  border-radius: 3px;
}
button.round, .button.round {
  border-radius: 1000px;
}
button.disabled, button[disabled], .button.disabled, .button[disabled] {
  background-color: #2F9ACD;
  border-color: #267ba4;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #267ba4;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  color: #FFFFFF;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #2F9ACD;
}
button.disabled.secondary, button[disabled].secondary, .button.disabled.secondary, .button[disabled].secondary {
  background-color: #616161;
  border-color: #4e4e4e;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #4e4e4e;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  color: #FFFFFF;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #616161;
}
button.disabled.success, button[disabled].success, .button.disabled.success, .button[disabled].success {
  background-color: #8BB741;
  border-color: #6f9234;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #6f9234;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  color: #FFFFFF;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #8BB741;
}
button.disabled.alert, button[disabled].alert, .button.disabled.alert, .button[disabled].alert {
  background-color: #DF5146;
  border-color: #c82e23;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #c82e23;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  color: #FFFFFF;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #DF5146;
}
button.disabled.warning, button[disabled].warning, .button.disabled.warning, .button[disabled].warning {
  background-color: #FEA82F;
  border-color: #f08d01;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #f08d01;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  color: #FFFFFF;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #FEA82F;
}
button.disabled.info, button[disabled].info, .button.disabled.info, .button[disabled].info {
  background-color: #757575;
  border-color: #5e5e5e;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.4;
  box-shadow: none;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #5e5e5e;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  color: #FFFFFF;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #757575;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media only screen and (min-width:40em) {
  button, .button {
    display: inline-block;
  }
}
.button-group {
  list-style: none;
  margin: 0;
  left: 0;
}
.button-group:before, .button-group:after {
  content: " ";
  display: table;
}
.button-group:after {
  clear: both;
}
.button-group.even-2 li {
  margin: 0 -2px;
  display: inline-block;
  width: 50%;
}
.button-group.even-2 li > button, .button-group.even-2 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-2 li:first-child button, .button-group.even-2 li:first-child .button {
  border-left: 0;
}
.button-group.even-2 li button, .button-group.even-2 li .button {
  width: 100%;
}
.button-group.even-3 li {
  margin: 0 -2px;
  display: inline-block;
  width: 33.3333333333%;
}
.button-group.even-3 li > button, .button-group.even-3 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-3 li:first-child button, .button-group.even-3 li:first-child .button {
  border-left: 0;
}
.button-group.even-3 li button, .button-group.even-3 li .button {
  width: 100%;
}
.button-group.even-4 li {
  margin: 0 -2px;
  display: inline-block;
  width: 25%;
}
.button-group.even-4 li > button, .button-group.even-4 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-4 li:first-child button, .button-group.even-4 li:first-child .button {
  border-left: 0;
}
.button-group.even-4 li button, .button-group.even-4 li .button {
  width: 100%;
}
.button-group.even-5 li {
  margin: 0 -2px;
  display: inline-block;
  width: 20%;
}
.button-group.even-5 li > button, .button-group.even-5 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-5 li:first-child button, .button-group.even-5 li:first-child .button {
  border-left: 0;
}
.button-group.even-5 li button, .button-group.even-5 li .button {
  width: 100%;
}
.button-group.even-6 li {
  margin: 0 -2px;
  display: inline-block;
  width: 16.6666666667%;
}
.button-group.even-6 li > button, .button-group.even-6 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-6 li:first-child button, .button-group.even-6 li:first-child .button {
  border-left: 0;
}
.button-group.even-6 li button, .button-group.even-6 li .button {
  width: 100%;
}
.button-group.even-7 li {
  margin: 0 -2px;
  display: inline-block;
  width: 14.2857142857%;
}
.button-group.even-7 li > button, .button-group.even-7 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-7 li:first-child button, .button-group.even-7 li:first-child .button {
  border-left: 0;
}
.button-group.even-7 li button, .button-group.even-7 li .button {
  width: 100%;
}
.button-group.even-8 li {
  margin: 0 -2px;
  display: inline-block;
  width: 12.5%;
}
.button-group.even-8 li > button, .button-group.even-8 li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.even-8 li:first-child button, .button-group.even-8 li:first-child .button {
  border-left: 0;
}
.button-group.even-8 li button, .button-group.even-8 li .button {
  width: 100%;
}
.button-group > li {
  margin: 0 -2px;
  display: inline-block;
}
.button-group > li > button, .button-group > li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group > li:first-child button, .button-group > li:first-child .button {
  border-left: 0;
}
.button-group.stack > li {
  margin: 0 -2px;
  display: inline-block;
  display: block;
  margin: 0;
  float: none;
}
.button-group.stack > li > button, .button-group.stack > li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.stack > li:first-child button, .button-group.stack > li:first-child .button {
  border-left: 0;
}
.button-group.stack > li > button, .button-group.stack > li .button {
  border-top: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-left-width: 0;
  margin: 0;
  display: block;
}
.button-group.stack > li > button {
  width: 100%;
}
.button-group.stack > li:first-child button, .button-group.stack > li:first-child .button {
  border-top: 0;
}
.button-group.stack-for-small > li {
  margin: 0 -2px;
  display: inline-block;
}
.button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
  border-left: 0;
}
@media only screen and (max-width: 39.937em) {
  .button-group.stack-for-small > li {
    margin: 0 -2px;
    display: inline-block;
    display: block;
    margin: 0;
  }
  .button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
    border-left: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
    border-left: 0;
  }
  .button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
    border-top: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-left-width: 0;
    margin: 0;
    display: block;
  }
  .button-group.stack-for-small > li > button {
    width: 100%;
  }
  .button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
    border-top: 0;
  }
}
.button-group.radius > * {
  margin: 0 -2px;
  display: inline-block;
}
.button-group.radius > * > button, .button-group.radius > * .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.radius > *:first-child button, .button-group.radius > *:first-child .button {
  border-left: 0;
}
.button-group.radius > *, .button-group.radius > * > a, .button-group.radius > * > button, .button-group.radius > * > .button {
  border-radius: 0;
}
.button-group.radius > *:first-child, .button-group.radius > *:first-child > a, .button-group.radius > *:first-child > button, .button-group.radius > *:first-child > .button {
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.button-group.radius > *:last-child, .button-group.radius > *:last-child > a, .button-group.radius > *:last-child > button, .button-group.radius > *:last-child > .button {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.button-group.radius.stack > * {
  margin: 0 -2px;
  display: inline-block;
  display: block;
  margin: 0;
}
.button-group.radius.stack > * > button, .button-group.radius.stack > * .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.radius.stack > *:first-child button, .button-group.radius.stack > *:first-child .button {
  border-left: 0;
}
.button-group.radius.stack > * > button, .button-group.radius.stack > * .button {
  border-top: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-left-width: 0;
  margin: 0;
  display: block;
}
.button-group.radius.stack > * > button {
  width: 100%;
}
.button-group.radius.stack > *:first-child button, .button-group.radius.stack > *:first-child .button {
  border-top: 0;
}
.button-group.radius.stack > *, .button-group.radius.stack > * > a, .button-group.radius.stack > * > button, .button-group.radius.stack > * > .button {
  border-radius: 0;
}
.button-group.radius.stack > *:first-child, .button-group.radius.stack > *:first-child > a, .button-group.radius.stack > *:first-child > button, .button-group.radius.stack > *:first-child > .button {
  -webkit-top-left-radius: 3px;
  -webkit-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.button-group.radius.stack > *:last-child, .button-group.radius.stack > *:last-child > a, .button-group.radius.stack > *:last-child > button, .button-group.radius.stack > *:last-child > .button {
  -webkit-bottom-left-radius: 3px;
  -webkit-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
@media only screen and (min-width:40em) {
  .button-group.radius.stack-for-small > * {
    margin: 0 -2px;
    display: inline-block;
  }
  .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
    border-left: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
    border-left: 0;
  }
  .button-group.radius.stack-for-small > *, .button-group.radius.stack-for-small > * > a, .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * > .button {
    border-radius: 0;
  }
  .button-group.radius.stack-for-small > *:first-child, .button-group.radius.stack-for-small > *:first-child > a, .button-group.radius.stack-for-small > *:first-child > button, .button-group.radius.stack-for-small > *:first-child > .button {
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .button-group.radius.stack-for-small > *:last-child, .button-group.radius.stack-for-small > *:last-child > a, .button-group.radius.stack-for-small > *:last-child > button, .button-group.radius.stack-for-small > *:last-child > .button {
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
}
@media only screen and (max-width: 39.937em) {
  .button-group.radius.stack-for-small > * {
    margin: 0 -2px;
    display: inline-block;
    display: block;
    margin: 0;
  }
  .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
    border-left: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
    border-left: 0;
  }
  .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
    border-top: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-left-width: 0;
    margin: 0;
    display: block;
  }
  .button-group.radius.stack-for-small > * > button {
    width: 100%;
  }
  .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
    border-top: 0;
  }
  .button-group.radius.stack-for-small > *, .button-group.radius.stack-for-small > * > a, .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * > .button {
    border-radius: 0;
  }
  .button-group.radius.stack-for-small > *:first-child, .button-group.radius.stack-for-small > *:first-child > a, .button-group.radius.stack-for-small > *:first-child > button, .button-group.radius.stack-for-small > *:first-child > .button {
    -webkit-top-left-radius: 3px;
    -webkit-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .button-group.radius.stack-for-small > *:last-child, .button-group.radius.stack-for-small > *:last-child > a, .button-group.radius.stack-for-small > *:last-child > button, .button-group.radius.stack-for-small > *:last-child > .button {
    -webkit-bottom-left-radius: 3px;
    -webkit-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
.button-group.round > * {
  margin: 0 -2px;
  display: inline-block;
}
.button-group.round > * > button, .button-group.round > * .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.round > *:first-child button, .button-group.round > *:first-child .button {
  border-left: 0;
}
.button-group.round > *, .button-group.round > * > a, .button-group.round > * > button, .button-group.round > * > .button {
  border-radius: 0;
}
.button-group.round > *:first-child, .button-group.round > *:first-child > a, .button-group.round > *:first-child > button, .button-group.round > *:first-child > .button {
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
.button-group.round > *:last-child, .button-group.round > *:last-child > a, .button-group.round > *:last-child > button, .button-group.round > *:last-child > .button {
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}
.button-group.round.stack > * {
  margin: 0 -2px;
  display: inline-block;
  display: block;
  margin: 0;
}
.button-group.round.stack > * > button, .button-group.round.stack > * .button {
  border-left: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
}
.button-group.round.stack > *:first-child button, .button-group.round.stack > *:first-child .button {
  border-left: 0;
}
.button-group.round.stack > * > button, .button-group.round.stack > * .button {
  border-top: 0 solid;
  border-color: rgba(255, 255, 255, 0.5);
  border-left-width: 0;
  margin: 0;
  display: block;
}
.button-group.round.stack > * > button {
  width: 100%;
}
.button-group.round.stack > *:first-child button, .button-group.round.stack > *:first-child .button {
  border-top: 0;
}
.button-group.round.stack > *, .button-group.round.stack > * > a, .button-group.round.stack > * > button, .button-group.round.stack > * > .button {
  border-radius: 0;
}
.button-group.round.stack > *:first-child, .button-group.round.stack > *:first-child > a, .button-group.round.stack > *:first-child > button, .button-group.round.stack > *:first-child > .button {
  -webkit-top-left-radius: 0.5rem;
  -webkit-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.button-group.round.stack > *:last-child, .button-group.round.stack > *:last-child > a, .button-group.round.stack > *:last-child > button, .button-group.round.stack > *:last-child > .button {
  -webkit-bottom-left-radius: 0.5rem;
  -webkit-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
@media only screen and (min-width:40em) {
  .button-group.round.stack-for-small > * {
    margin: 0 -2px;
    display: inline-block;
  }
  .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
    border-left: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
    border-left: 0;
  }
  .button-group.round.stack-for-small > *, .button-group.round.stack-for-small > * > a, .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * > .button {
    border-radius: 0;
  }
  .button-group.round.stack-for-small > *:first-child, .button-group.round.stack-for-small > *:first-child > a, .button-group.round.stack-for-small > *:first-child > button, .button-group.round.stack-for-small > *:first-child > .button {
    -webkit-border-bottom-left-radius: 1000px;
    -webkit-border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
  }
  .button-group.round.stack-for-small > *:last-child, .button-group.round.stack-for-small > *:last-child > a, .button-group.round.stack-for-small > *:last-child > button, .button-group.round.stack-for-small > *:last-child > .button {
    -webkit-border-bottom-right-radius: 1000px;
    -webkit-border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
  }
}
@media only screen and (max-width: 39.937em) {
  .button-group.round.stack-for-small > * {
    margin: 0 -2px;
    display: inline-block;
    display: block;
    margin: 0;
  }
  .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
    border-left: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
    border-left: 0;
  }
  .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
    border-top: 0 solid;
    border-color: rgba(255, 255, 255, 0.5);
    border-left-width: 0;
    margin: 0;
    display: block;
  }
  .button-group.round.stack-for-small > * > button {
    width: 100%;
  }
  .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
    border-top: 0;
  }
  .button-group.round.stack-for-small > *, .button-group.round.stack-for-small > * > a, .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * > .button {
    border-radius: 0;
  }
  .button-group.round.stack-for-small > *:first-child, .button-group.round.stack-for-small > *:first-child > a, .button-group.round.stack-for-small > *:first-child > button, .button-group.round.stack-for-small > *:first-child > .button {
    -webkit-top-left-radius: 0.5rem;
    -webkit-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .button-group.round.stack-for-small > *:last-child, .button-group.round.stack-for-small > *:last-child > a, .button-group.round.stack-for-small > *:last-child > button, .button-group.round.stack-for-small > *:last-child > .button {
    -webkit-bottom-left-radius: 0.5rem;
    -webkit-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.button-bar:before, .button-bar:after {
  content: " ";
  display: table;
}
.button-bar:after {
  clear: both;
}
.button-bar .button-group {
  float: left;
  margin-right: 0.625rem;
}
.button-bar .button-group div {
  overflow: hidden;
}

/* Clearing Styles */
.clearing-thumbs, [data-clearing] {
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
}
.clearing-thumbs:before, .clearing-thumbs:after, [data-clearing]:before, [data-clearing]:after {
  content: " ";
  display: table;
}
.clearing-thumbs:after, [data-clearing]:after {
  clear: both;
}
.clearing-thumbs li, [data-clearing] li {
  float: left;
  margin-right: 10px;
}
.clearing-thumbs[class*=block-grid-] li, [data-clearing][class*=block-grid-] li {
  margin-right: 0;
}

.clearing-blackout {
  background: #333333;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
}
.clearing-blackout .clearing-close {
  display: block;
}

.clearing-container {
  position: relative;
  z-index: 998;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.clearing-touch-label {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #AAAAAA;
  font-size: 0.6em;
}

.visible-img {
  height: 95%;
  position: relative;
}
.visible-img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  max-height: 100%;
  max-width: 100%;
}

.clearing-caption {
  color: #CCCCCC;
  font-size: 0.875em;
  line-height: 1.3;
  margin-bottom: 0;
  text-align: center;
  bottom: 0;
  background: #333333;
  width: 100%;
  padding: 10px 30px 20px;
  position: absolute;
  left: 0;
}

.clearing-close {
  z-index: 999;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 30px;
  line-height: 1;
  color: #CCCCCC;
  display: none;
}
.clearing-close:hover, .clearing-close:focus {
  color: #CCCCCC;
}

.clearing-assembled .clearing-container {
  height: 100%;
}
.clearing-assembled .clearing-container .carousel > ul {
  display: none;
}

.clearing-feature li {
  display: none;
}
.clearing-feature li.clearing-featured-img {
  display: block;
}

@media only screen and (min-width:40em) {
  .clearing-main-prev,
  .clearing-main-next {
    position: absolute;
    height: 100%;
    width: 40px;
    top: 0;
  }
  .clearing-main-prev > span,
  .clearing-main-next > span {
    position: absolute;
    top: 50%;
    display: block;
    width: 0;
    height: 0;
    border: solid 12px;
  }
  .clearing-main-prev > span:hover,
  .clearing-main-next > span:hover {
    opacity: 0.8;
  }
  .clearing-main-prev {
    left: 0;
  }
  .clearing-main-prev > span {
    left: 5px;
    border-color: transparent;
    border-right-color: #CCCCCC;
  }
  .clearing-main-next {
    right: 0;
  }
  .clearing-main-next > span {
    border-color: transparent;
    border-left-color: #CCCCCC;
  }
  .clearing-main-prev.disabled,
  .clearing-main-next.disabled {
    opacity: 0.3;
  }
  .clearing-assembled .clearing-container .carousel {
    background: rgba(51, 51, 51, 0.8);
    height: 120px;
    margin-top: 10px;
    text-align: center;
  }
  .clearing-assembled .clearing-container .carousel > ul {
    display: inline-block;
    z-index: 999;
    height: 100%;
    position: relative;
    float: none;
  }
  .clearing-assembled .clearing-container .carousel > ul li {
    display: block;
    width: 120px;
    min-height: inherit;
    float: left;
    overflow: hidden;
    margin-right: 0;
    padding: 0;
    position: relative;
    cursor: pointer;
    opacity: 0.4;
    clear: none;
  }
  .clearing-assembled .clearing-container .carousel > ul li.fix-height img {
    height: 100%;
    max-width: none;
  }
  .clearing-assembled .clearing-container .carousel > ul li a.th {
    border: none;
    box-shadow: none;
    display: block;
  }
  .clearing-assembled .clearing-container .carousel > ul li img {
    cursor: pointer !important;
    width: 100% !important;
  }
  .clearing-assembled .clearing-container .carousel > ul li.visible {
    opacity: 1;
  }
  .clearing-assembled .clearing-container .carousel > ul li:hover {
    opacity: 0.8;
  }
  .clearing-assembled .clearing-container .visible-img {
    background: #333333;
    overflow: hidden;
    height: 85%;
  }
  .clearing-close {
    position: absolute;
    top: 10px;
    right: 20px;
    padding-left: 0;
    padding-top: 0;
  }
}
/* Foundation Dropdowns */
.f-dropdown {
  position: absolute;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  outline: none;
  width: 100%;
  max-height: none;
  height: auto;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  z-index: 89;
  margin-top: 2px;
  max-width: 200px;
}
.f-dropdown > *:first-child {
  margin-top: 0;
}
.f-dropdown > *:last-child {
  margin-bottom: 0;
}
.f-dropdown:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent transparent #FFFFFF transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 89;
}
.f-dropdown:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 7px;
  border-color: transparent transparent #cccccc transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -14px;
  left: 9px;
  z-index: 88;
}
.f-dropdown.right:before {
  left: auto;
  right: 10px;
}
.f-dropdown.right:after {
  left: auto;
  right: 9px;
}
.f-dropdown.drop-right {
  position: absolute;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  outline: none;
  width: 100%;
  max-height: none;
  height: auto;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  z-index: 89;
  margin-top: 0;
  margin-left: 2px;
  max-width: 200px;
}
.f-dropdown.drop-right > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-right > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-right:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent #FFFFFF transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 10px;
  left: -12px;
  z-index: 89;
}
.f-dropdown.drop-right:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 7px;
  border-color: transparent #cccccc transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 9px;
  left: -14px;
  z-index: 88;
}
.f-dropdown.drop-left {
  position: absolute;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  outline: none;
  width: 100%;
  max-height: none;
  height: auto;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  z-index: 89;
  margin-top: 0;
  margin-left: -2px;
  max-width: 200px;
}
.f-dropdown.drop-left > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-left > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-left:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent transparent transparent #FFFFFF;
  border-left-style: solid;
  position: absolute;
  top: 10px;
  right: -12px;
  left: auto;
  z-index: 89;
}
.f-dropdown.drop-left:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 7px;
  border-color: transparent transparent transparent #cccccc;
  border-left-style: solid;
  position: absolute;
  top: 9px;
  right: -14px;
  left: auto;
  z-index: 88;
}
.f-dropdown.drop-top {
  position: absolute;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  outline: none;
  width: 100%;
  max-height: none;
  height: auto;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  z-index: 89;
  margin-top: -2px;
  margin-left: 0;
  max-width: 200px;
}
.f-dropdown.drop-top > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-top > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-top:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: #FFFFFF transparent transparent transparent;
  border-top-style: solid;
  position: absolute;
  top: auto;
  bottom: -12px;
  left: 10px;
  right: auto;
  z-index: 89;
}
.f-dropdown.drop-top:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset 7px;
  border-color: #cccccc transparent transparent transparent;
  border-top-style: solid;
  position: absolute;
  top: auto;
  bottom: -14px;
  left: 9px;
  right: auto;
  z-index: 88;
}
.f-dropdown li {
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.125rem;
  margin: 0;
}
.f-dropdown li:hover, .f-dropdown li:focus {
  background: #EEEEEE;
}
.f-dropdown li.radius {
  border-radius: 3px;
}
.f-dropdown li a {
  display: block;
  padding: 0.5rem;
  color: #555555;
}
.f-dropdown.content {
  position: absolute;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  outline: none;
  padding: 1.25rem;
  width: 100%;
  height: auto;
  max-height: none;
  background: #FFFFFF;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  z-index: 89;
  max-width: 200px;
}
.f-dropdown.content > *:first-child {
  margin-top: 0;
}
.f-dropdown.content > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.tiny {
  max-width: 200px;
}
.f-dropdown.small {
  max-width: 300px;
}
.f-dropdown.medium {
  max-width: 500px;
}
.f-dropdown.large {
  max-width: 800px;
}
.f-dropdown.mega {
  width: 100% !important;
  max-width: 100% !important;
}
.f-dropdown.mega.open {
  left: 0 !important;
}

.dropdown.button, button.dropdown {
  position: relative;
  outline: none;
  padding-right: 3.5625rem;
}
.dropdown.button::after, button.dropdown::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
  top: 50%;
}
.dropdown.button::after, button.dropdown::after {
  border-width: 0.375rem;
  right: 1.40625rem;
  margin-top: -0.15625rem;
}
.dropdown.button::after, button.dropdown::after {
  border-color: #FFFFFF transparent transparent transparent;
}
.dropdown.button.tiny, button.dropdown.tiny {
  padding-right: 2.625rem;
}
.dropdown.button.tiny:after, button.dropdown.tiny:after {
  border-width: 0.375rem;
  right: 1.125rem;
  margin-top: -0.125rem;
}
.dropdown.button.tiny::after, button.dropdown.tiny::after {
  border-color: #FFFFFF transparent transparent transparent;
}
.dropdown.button.small, button.dropdown.small {
  padding-right: 3.0625rem;
}
.dropdown.button.small::after, button.dropdown.small::after {
  border-width: 0.4375rem;
  right: 1.3125rem;
  margin-top: -0.15625rem;
}
.dropdown.button.small::after, button.dropdown.small::after {
  border-color: #FFFFFF transparent transparent transparent;
}
.dropdown.button.large, button.dropdown.large {
  padding-right: 3.625rem;
}
.dropdown.button.large::after, button.dropdown.large::after {
  border-width: 0.3125rem;
  right: 1.71875rem;
  margin-top: -0.15625rem;
}
.dropdown.button.large::after, button.dropdown.large::after {
  border-color: #FFFFFF transparent transparent transparent;
}
.dropdown.button.secondary:after, button.dropdown.secondary:after {
  border-color: #333333 transparent transparent transparent;
}

.flex-video {
  position: relative;
  padding-top: 1.5625rem;
  padding-bottom: 67.5%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
}
.flex-video.widescreen {
  padding-bottom: 56.34%;
}
.flex-video.vimeo {
  padding-top: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Standard Forms */
form {
  margin: 0 0 1rem;
}

/* Using forms within rows, we need to set some defaults */
form .row .row {
  margin: 0 -0.5rem;
}
form .row .row .column,
form .row .row .columns {
  padding: 0 0.5rem;
}
form .row .row.collapse {
  margin: 0;
}
form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0;
}
form .row .row.collapse input {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: 0.5rem;
}

/* Label Styles */
label, ticket-add-edit-modal dt, add-edit-ticket-modal dt {
  font-size: 0.875rem;
  color: #212121;
  cursor: pointer;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  /* Styles for required inputs */
}
label.right, ticket-add-edit-modal dt.right, add-edit-ticket-modal dt.right {
  float: none !important;
  text-align: right;
}
label.inline, ticket-add-edit-modal dt.inline, add-edit-ticket-modal dt.inline {
  margin: 0 0 1rem 0;
  padding: 0.5625rem 0;
}
label small, ticket-add-edit-modal dt small, add-edit-ticket-modal dt small {
  text-transform: capitalize;
  color: #424242;
}

/* Attach elements to the beginning or end of an input */
.prefix,
.postfix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  border-style: solid;
  border-width: 1px;
  overflow: visible;
  font-size: 0.875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
}

/* Adjust padding, alignment and radius if pre/post element is a button */
.postfix.button {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  border: none;
}

.prefix.button {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  border: none;
}

.prefix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.postfix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.prefix.button.round {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

.postfix.button.round {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

/* Separate prefix and postfix styles when on span or label so buttons keep their own */
span.prefix, label.prefix, ticket-add-edit-modal dt.prefix, add-edit-ticket-modal dt.prefix {
  background: #f2f2f2;
  border-right: none;
  color: #333333;
  border-color: #cccccc;
}

span.postfix, label.postfix, ticket-add-edit-modal dt.postfix, add-edit-ticket-modal dt.postfix {
  background: #f2f2f2;
  border-left: none;
  color: #333333;
  border-color: #cccccc;
}

/* We use this to get basic styling on all basic form elements */
input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=datetime-local], input[type=month], input[type=week], input[type=email], input[type=number], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=color], textarea {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: #FFFFFF;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: #616161;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  height: 2.3125rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.15s linear;
}
input[type=text]:focus, input[type=password]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=month]:focus, input[type=week]:focus, input[type=email]:focus, input[type=number]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=color]:focus, textarea:focus {
  background: #fafafa;
  border-color: #999999;
  outline: none;
}
input[type=text]:disabled, input[type=password]:disabled, input[type=date]:disabled, input[type=datetime]:disabled, input[type=datetime-local]:disabled, input[type=month]:disabled, input[type=week]:disabled, input[type=email]:disabled, input[type=number]:disabled, input[type=search]:disabled, input[type=tel]:disabled, input[type=time]:disabled, input[type=url]:disabled, input[type=color]:disabled, textarea:disabled {
  background-color: #DDDDDD;
  cursor: default;
}
input[type=text][disabled], input[type=text][readonly], fieldset[disabled] input[type=text], input[type=password][disabled], input[type=password][readonly], fieldset[disabled] input[type=password], input[type=date][disabled], input[type=date][readonly], fieldset[disabled] input[type=date], input[type=datetime][disabled], input[type=datetime][readonly], fieldset[disabled] input[type=datetime], input[type=datetime-local][disabled], input[type=datetime-local][readonly], fieldset[disabled] input[type=datetime-local], input[type=month][disabled], input[type=month][readonly], fieldset[disabled] input[type=month], input[type=week][disabled], input[type=week][readonly], fieldset[disabled] input[type=week], input[type=email][disabled], input[type=email][readonly], fieldset[disabled] input[type=email], input[type=number][disabled], input[type=number][readonly], fieldset[disabled] input[type=number], input[type=search][disabled], input[type=search][readonly], fieldset[disabled] input[type=search], input[type=tel][disabled], input[type=tel][readonly], fieldset[disabled] input[type=tel], input[type=time][disabled], input[type=time][readonly], fieldset[disabled] input[type=time], input[type=url][disabled], input[type=url][readonly], fieldset[disabled] input[type=url], input[type=color][disabled], input[type=color][readonly], fieldset[disabled] input[type=color], textarea[disabled], textarea[readonly], fieldset[disabled] textarea {
  background-color: #DDDDDD;
  cursor: default;
}
input[type=text].radius, input[type=password].radius, input[type=date].radius, input[type=datetime].radius, input[type=datetime-local].radius, input[type=month].radius, input[type=week].radius, input[type=email].radius, input[type=number].radius, input[type=search].radius, input[type=tel].radius, input[type=time].radius, input[type=url].radius, input[type=color].radius, textarea.radius {
  border-radius: 3px;
}

form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse select,
form .row .prefix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
form .row .prefix-radius.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse textarea,
form .row .postfix-radius.row.collapse select,
form .row .postfix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
form .row .postfix-radius.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse textarea,
form .row .prefix-round.row.collapse select,
form .row .prefix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}
form .row .prefix-round.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse textarea,
form .row .postfix-round.row.collapse select,
form .row .postfix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
form .row .postfix-round.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

/* Respect enforced amount of rows for textarea */
textarea[rows] {
  height: auto;
}

/* Not allow resize out of parent */
textarea {
  max-width: 100%;
}

/* Add height value for select elements to match text input height */
select {
  -webkit-appearance: none !important;
  border-radius: 0;
  background-color: #FAFAFA;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: #616161;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: "AvenirNextLTPro", sans-serif;
  color: rgba(0, 0, 0, 0.75);
  line-height: normal;
  border-radius: 0;
  height: 2.3125rem;
}
select::-ms-expand {
  display: none;
}
select.radius {
  border-radius: 3px;
}
select:hover {
  background-color: #f3f3f3;
  border-color: #999999;
}
select:disabled {
  background-color: #DDDDDD;
  cursor: default;
}
select[multiple] {
  height: auto;
}

/* Adjust margin for form elements below */
input[type=file],
input[type=checkbox],
input[type=radio],
select {
  margin: 0 0 1rem 0;
}

input[type=checkbox] + label, ticket-add-edit-modal input[type=checkbox] + dt, add-edit-ticket-modal input[type=checkbox] + dt,
input[type=radio] + label,
ticket-add-edit-modal input[type=radio] + dt,
add-edit-ticket-modal input[type=radio] + dt {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline;
}

/* Normalize file input width */
input[type=file] {
  width: 100%;
}

/* HTML5 Number spinners settings */
/* We add basic fieldset styling */
fieldset {
  border: 1px solid #DDDDDD;
  padding: 1.25rem;
  margin: 1.125rem 0;
}
fieldset legend {
  font-weight: 600;
  background: #FFFFFF;
  padding: 0 0.1875rem;
  margin: 0;
  margin-left: -0.1875rem;
}

/* Error Handling */
[data-abide] .error small.error, [data-abide] .error span.error, [data-abide] span.error, [data-abide] small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  background: #DF5146;
  color: #FFFFFF;
}
[data-abide] span.error, [data-abide] small.error {
  display: none;
}

span.error, small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  background: #DF5146;
  color: #FFFFFF;
}

.error input,
.error textarea,
.error select {
  margin-bottom: 0;
}
.error input[type=checkbox],
.error input[type=radio] {
  margin-bottom: 1rem;
}
.error label, .error ticket-add-edit-modal dt, ticket-add-edit-modal .error dt, .error add-edit-ticket-modal dt, add-edit-ticket-modal .error dt,
.error label.error {
  color: #DF5146;
}
.error small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  background: #DF5146;
  color: #FFFFFF;
}
.error > label > small, ticket-add-edit-modal .error > dt > small, add-edit-ticket-modal .error > dt > small {
  color: #424242;
  background: transparent;
  padding: 0;
  text-transform: capitalize;
  font-style: normal;
  font-size: 60%;
  margin: 0;
  display: inline;
}
.error span.error-message {
  display: block;
}

input.error,
textarea.error,
select.error {
  margin-bottom: 0;
}

label.error, ticket-add-edit-modal dt.error, add-edit-ticket-modal dt.error {
  color: #DF5146;
}

.icon-bar {
  width: 100%;
  font-size: 0;
  display: inline-block;
  background: #333333;
}
.icon-bar > * {
  text-align: center;
  font-size: 1rem;
  width: 25%;
  margin: 0 auto;
  display: block;
  padding: 1.25rem;
  float: left;
}
.icon-bar > * i, .icon-bar > * img {
  display: block;
  margin: 0 auto;
}
.icon-bar > * i + label, .icon-bar > * ticket-add-edit-modal i + dt, ticket-add-edit-modal .icon-bar > * i + dt, .icon-bar > * add-edit-ticket-modal i + dt, add-edit-ticket-modal .icon-bar > * i + dt, .icon-bar > * img + label, .icon-bar > * ticket-add-edit-modal img + dt, ticket-add-edit-modal .icon-bar > * img + dt, .icon-bar > * add-edit-ticket-modal img + dt, add-edit-ticket-modal .icon-bar > * img + dt {
  margin-top: 0.0625rem;
}
.icon-bar > * i {
  font-size: 1.875rem;
  vertical-align: middle;
}
.icon-bar > * img {
  width: 1.875rem;
  height: 1.875rem;
}
.icon-bar.label-right > * i, .icon-bar.label-right > * img {
  margin: 0 0.0625rem 0 0;
  display: inline-block;
}
.icon-bar.label-right > * i + label, .icon-bar.label-right > * ticket-add-edit-modal i + dt, ticket-add-edit-modal .icon-bar.label-right > * i + dt, .icon-bar.label-right > * add-edit-ticket-modal i + dt, add-edit-ticket-modal .icon-bar.label-right > * i + dt, .icon-bar.label-right > * img + label, .icon-bar.label-right > * ticket-add-edit-modal img + dt, ticket-add-edit-modal .icon-bar.label-right > * img + dt, .icon-bar.label-right > * add-edit-ticket-modal img + dt, add-edit-ticket-modal .icon-bar.label-right > * img + dt {
  margin-top: 0;
}
.icon-bar.label-right > * label, .icon-bar.label-right > * ticket-add-edit-modal dt, ticket-add-edit-modal .icon-bar.label-right > * dt, .icon-bar.label-right > * add-edit-ticket-modal dt, add-edit-ticket-modal .icon-bar.label-right > * dt {
  display: inline-block;
}
.icon-bar.vertical.label-right > * {
  text-align: left;
}
.icon-bar.vertical, .icon-bar.small-vertical {
  height: 100%;
  width: auto;
}
.icon-bar.vertical .item, .icon-bar.small-vertical .item {
  width: auto;
  margin: auto;
  float: none;
}
@media only screen and (min-width:40em) {
  .icon-bar.medium-vertical {
    height: 100%;
    width: auto;
  }
  .icon-bar.medium-vertical .item {
    width: auto;
    margin: auto;
    float: none;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.large-vertical {
    height: 100%;
    width: auto;
  }
  .icon-bar.large-vertical .item {
    width: auto;
    margin: auto;
    float: none;
  }
}
.icon-bar > * {
  font-size: 1rem;
  padding: 1.25rem;
}
.icon-bar > * i + label, .icon-bar > * ticket-add-edit-modal i + dt, ticket-add-edit-modal .icon-bar > * i + dt, .icon-bar > * add-edit-ticket-modal i + dt, add-edit-ticket-modal .icon-bar > * i + dt, .icon-bar > * img + label, .icon-bar > * ticket-add-edit-modal img + dt, ticket-add-edit-modal .icon-bar > * img + dt, .icon-bar > * add-edit-ticket-modal img + dt, add-edit-ticket-modal .icon-bar > * img + dt {
  margin-top: 0.0625rem;
}
.icon-bar > * i {
  font-size: 1.875rem;
}
.icon-bar > * img {
  width: 1.875rem;
  height: 1.875rem;
}
.icon-bar > * label, .icon-bar > * ticket-add-edit-modal dt, ticket-add-edit-modal .icon-bar > * dt, .icon-bar > * add-edit-ticket-modal dt, add-edit-ticket-modal .icon-bar > * dt {
  color: #FFFFFF;
}
.icon-bar > * i {
  color: #FFFFFF;
}
.icon-bar > a:hover {
  background: #2F9ACD;
}
.icon-bar > a:hover label, .icon-bar > a:hover ticket-add-edit-modal dt, ticket-add-edit-modal .icon-bar > a:hover dt, .icon-bar > a:hover add-edit-ticket-modal dt, add-edit-ticket-modal .icon-bar > a:hover dt {
  color: #FFFFFF;
}
.icon-bar > a:hover i {
  color: #FFFFFF;
}
.icon-bar > a.active {
  background: #2F9ACD;
}
.icon-bar > a.active label, .icon-bar > a.active ticket-add-edit-modal dt, ticket-add-edit-modal .icon-bar > a.active dt, .icon-bar > a.active add-edit-ticket-modal dt, add-edit-ticket-modal .icon-bar > a.active dt {
  color: #FFFFFF;
}
.icon-bar > a.active i {
  color: #FFFFFF;
}
.icon-bar .item.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}
.icon-bar .item.disabled > * {
  opacity: 0.7;
  cursor: not-allowed;
}

.icon-bar.two-up .item {
  width: 50%;
}
.icon-bar.two-up.vertical .item, .icon-bar.two-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.two-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.two-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.three-up .item {
  width: 33.3333%;
}
.icon-bar.three-up.vertical .item, .icon-bar.three-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.three-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.three-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.four-up .item {
  width: 25%;
}
.icon-bar.four-up.vertical .item, .icon-bar.four-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.four-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.four-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.five-up .item {
  width: 20%;
}
.icon-bar.five-up.vertical .item, .icon-bar.five-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.five-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.five-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.six-up .item {
  width: 16.66667%;
}
.icon-bar.six-up.vertical .item, .icon-bar.six-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.six-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.six-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.seven-up .item {
  width: 14.28571%;
}
.icon-bar.seven-up.vertical .item, .icon-bar.seven-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.seven-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.seven-up.large-vertical .item {
    width: auto;
  }
}
.icon-bar.eight-up .item {
  width: 12.5%;
}
.icon-bar.eight-up.vertical .item, .icon-bar.eight-up.small-vertical .item {
  width: auto;
}
@media only screen and (min-width:40em) {
  .icon-bar.eight-up.medium-vertical .item {
    width: auto;
  }
}
@media only screen and (min-width:64em) {
  .icon-bar.eight-up.large-vertical .item {
    width: auto;
  }
}

.inline-list {
  margin: 0 auto 1.0625rem auto;
  margin-left: -1.375rem;
  margin-right: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.inline-list > li {
  list-style: none;
  float: left;
  margin-left: 1.375rem;
  display: block;
}
.inline-list > li > * {
  display: block;
}

/* Foundation Joyride */
.joyride-list {
  display: none;
}

/* Default styles for the container */
.joyride-tip-guide {
  display: none;
  position: absolute;
  background: #333333;
  color: #FFFFFF;
  z-index: 101;
  top: 0;
  left: 2.5%;
  font-family: inherit;
  font-weight: 400;
  width: 95%;
}

.lt-ie9 .joyride-tip-guide {
  max-width: 800px;
  left: 50%;
  margin-left: -400px;
}

.joyride-content-wrapper {
  width: 100%;
  padding: 1.125rem 1.25rem 1.5rem;
}
.joyride-content-wrapper .button {
  margin-bottom: 0 !important;
}
.joyride-content-wrapper .joyride-prev-tip {
  margin-right: 10px;
}

/* Add a little css triangle pip, older browser just miss out on the fanciness of it */
.joyride-tip-guide .joyride-nub {
  display: block;
  position: absolute;
  left: 22px;
  width: 0;
  height: 0;
  border: 10px solid #333333;
}
.joyride-tip-guide .joyride-nub.top {
  border-top-style: solid;
  border-color: #333333;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  top: -20px;
}
.joyride-tip-guide .joyride-nub.bottom {
  border-bottom-style: solid;
  border-color: #333333 !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  bottom: -20px;
}
.joyride-tip-guide .joyride-nub.right {
  right: -20px;
}
.joyride-tip-guide .joyride-nub.left {
  left: -20px;
}

/* Typography */
.joyride-tip-guide h1,
.joyride-tip-guide h2,
.joyride-tip-guide h3,
.joyride-tip-guide h4,
.joyride-tip-guide h5,
.joyride-tip-guide h6 {
  line-height: 1.25;
  margin: 0;
  font-weight: 600;
  color: #FFFFFF;
}

.joyride-tip-guide p {
  margin: 0 0 1.125rem 0;
  font-size: 0.875rem;
  line-height: 1.3;
}

.joyride-timer-indicator-wrap {
  width: 50px;
  height: 3px;
  border: solid 1px #555555;
  position: absolute;
  right: 1.0625rem;
  bottom: 1rem;
}

.joyride-timer-indicator {
  display: block;
  width: 0;
  height: inherit;
  background: #666666;
}

.joyride-close-tip {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #777777 !important;
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
  line-height: 0.5 !important;
}
.joyride-close-tip:hover, .joyride-close-tip:focus {
  color: #EEEEEE !important;
}

.joyride-modal-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: transparent;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  cursor: pointer;
}

.joyride-expose-wrapper {
  background-color: #FFFFFF;
  position: absolute;
  border-radius: 3px;
  z-index: 102;
  box-shadow: 0 0 15px #FFFFFF;
}

.joyride-expose-cover {
  background: transparent;
  border-radius: 3px;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
}

/* Styles for screens that are at least 768px; */
@media only screen and (min-width:40em) {
  .joyride-tip-guide {
    width: 300px;
    left: inherit;
  }
  .joyride-tip-guide .joyride-nub.bottom {
    border-color: #333333 !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    bottom: -20px;
  }
  .joyride-tip-guide .joyride-nub.right {
    border-color: #333333 !important;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    top: 22px;
    left: auto;
    right: -20px;
  }
  .joyride-tip-guide .joyride-nub.left {
    border-color: #333333 !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    top: 22px;
    left: -20px;
    right: auto;
  }
}
.keystroke,
kbd {
  background-color: #ededed;
  border-color: #dddddd;
  color: #222222;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  font-family: "Cousine", monospace;
  font-size: inherit;
  padding: 0.125rem 0.25rem 0;
  border-radius: 3px;
}

.label {
  font-weight: 400;
  font-family: "AvenirNextLTPro", sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  margin-bottom: auto;
  padding: 0.25rem 0.5rem 0.25rem;
  font-size: 0.6875rem;
  background-color: #2F9ACD;
  color: #FFFFFF;
}
.label.radius {
  border-radius: 3px;
}
.label.round {
  border-radius: 1000px;
}
.label.alert {
  background-color: #DF5146;
  color: #FFFFFF;
}
.label.warning {
  background-color: #FEA82F;
  color: #FFFFFF;
}
.label.success {
  background-color: #8BB741;
  color: #FFFFFF;
}
.label.secondary {
  background-color: #616161;
  color: #FFFFFF;
}
.label.info {
  background-color: #757575;
  color: #FFFFFF;
}

[data-magellan-expedition], [data-magellan-expedition-clone] {
  background: #FFFFFF;
  z-index: 50;
  min-width: 100%;
  padding: 0 !important;
}
[data-magellan-expedition] .sub-nav, [data-magellan-expedition-clone] .sub-nav {
  margin-bottom: 0;
}
[data-magellan-expedition] .sub-nav dd, [data-magellan-expedition-clone] .sub-nav dd {
  margin-bottom: 0;
}
[data-magellan-expedition] .sub-nav a, [data-magellan-expedition-clone] .sub-nav a {
  line-height: 1.8em;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Orbit Graceful Loading */
.slideshow-wrapper {
  position: relative;
}
.slideshow-wrapper ul {
  list-style-type: none;
  margin: 0;
}
.slideshow-wrapper ul li,
.slideshow-wrapper ul li .orbit-caption {
  display: none;
}
.slideshow-wrapper ul li:first-child {
  display: block;
}
.slideshow-wrapper .orbit-container {
  background-color: transparent;
}
.slideshow-wrapper .orbit-container li {
  display: block;
}
.slideshow-wrapper .orbit-container li .orbit-caption {
  display: block;
}
.slideshow-wrapper .orbit-container .orbit-bullets li {
  display: inline-block;
}
.slideshow-wrapper .preloader {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  border: solid 3px;
  border-color: #555555 #FFFFFF;
  border-radius: 1000px;
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.orbit-container {
  overflow: hidden;
  width: 100%;
  position: relative;
  background: none;
}
.orbit-container .orbit-slides-container {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-transform: translateZ(0);
}
.orbit-container .orbit-slides-container img {
  display: block;
  max-width: 100%;
}
.orbit-container .orbit-slides-container > * {
  position: absolute;
  top: 0;
  width: 100%;
  margin-left: 100%;
}
.orbit-container .orbit-slides-container > *:first-child {
  margin-left: 0;
}
.orbit-container .orbit-slides-container > * .orbit-caption {
  position: absolute;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.8);
  color: #FFFFFF;
  width: 100%;
  padding: 0.625rem 0.875rem;
  font-size: 0.875rem;
}
.orbit-container .orbit-slide-number {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0);
  z-index: 10;
}
.orbit-container .orbit-slide-number span {
  font-weight: 700;
  padding: 0.3125rem;
}
.orbit-container .orbit-timer {
  position: absolute;
  top: 12px;
  right: 10px;
  height: 6px;
  width: 100px;
  z-index: 10;
}
.orbit-container .orbit-timer .orbit-progress {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  display: block;
  width: 0;
  position: relative;
  right: 20px;
  top: 5px;
}
.orbit-container .orbit-timer > span {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 14px;
  border: solid 4px #FFFFFF;
  border-top: none;
  border-bottom: none;
}
.orbit-container .orbit-timer.paused > span {
  right: -4px;
  top: 0;
  width: 11px;
  height: 14px;
  border: inset 8px;
  border-left-style: solid;
  border-color: transparent;
  border-left-color: #FFFFFF;
}
.orbit-container .orbit-timer.paused > span.dark {
  border-left-color: #333333;
}
.orbit-container:hover .orbit-timer > span {
  display: block;
}
.orbit-container .orbit-prev,
.orbit-container .orbit-next {
  position: absolute;
  top: 45%;
  margin-top: -25px;
  width: 36px;
  height: 60px;
  line-height: 50px;
  color: white;
  background-color: transparent;
  text-indent: -9999px !important;
  z-index: 10;
}
.orbit-container .orbit-prev:hover,
.orbit-container .orbit-next:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.orbit-container .orbit-prev > span,
.orbit-container .orbit-next > span {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
}
.orbit-container .orbit-prev {
  left: 0;
}
.orbit-container .orbit-prev > span {
  border-right-style: solid;
  border-color: transparent;
  border-right-color: #FFFFFF;
}
.orbit-container .orbit-prev:hover > span {
  border-right-color: #FFFFFF;
}
.orbit-container .orbit-next {
  right: 0;
}
.orbit-container .orbit-next > span {
  border-color: transparent;
  border-left-style: solid;
  border-left-color: #FFFFFF;
  left: 50%;
  margin-left: -4px;
}
.orbit-container .orbit-next:hover > span {
  border-left-color: #FFFFFF;
}

.orbit-bullets-container {
  text-align: center;
}

.orbit-bullets {
  margin: 0 auto 30px auto;
  overflow: hidden;
  position: relative;
  top: 10px;
  float: none;
  text-align: center;
  display: block;
}
.orbit-bullets li {
  cursor: pointer;
  display: inline-block;
  width: 0.5625rem;
  height: 0.5625rem;
  background: #CCCCCC;
  float: none;
  margin-right: 6px;
  border-radius: 1000px;
}
.orbit-bullets li.active {
  background: #999999;
}
.orbit-bullets li:last-child {
  margin-right: 0;
}

.touch .orbit-container .orbit-prev,
.touch .orbit-container .orbit-next {
  display: none;
}
.touch .orbit-bullets {
  display: none;
}

@media only screen and (min-width:40em) {
  .touch .orbit-container .orbit-prev,
  .touch .orbit-container .orbit-next {
    display: inherit;
  }
  .touch .orbit-bullets {
    display: block;
  }
}
@media only screen and (max-width: 39.937em) {
  .orbit-stack-on-small .orbit-slides-container {
    height: auto !important;
  }
  .orbit-stack-on-small .orbit-slides-container > * {
    position: relative;
    margin: 0 !important;
    opacity: 1 !important;
  }
  .orbit-stack-on-small .orbit-slide-number {
    display: none;
  }
  .orbit-timer {
    display: none;
  }
  .orbit-next, .orbit-prev {
    display: none;
  }
  .orbit-bullets {
    display: none;
  }
}
ul.pagination {
  display: block;
  min-height: 1.5rem;
  margin-left: -0.3125rem;
}
ul.pagination li {
  height: 1.5rem;
  color: #222222;
  font-size: 0.875rem;
  margin-left: 0.3125rem;
}
ul.pagination li a, ul.pagination li button {
  display: block;
  padding: 0.0625rem 0.625rem 0.0625rem;
  color: #999999;
  background: none;
  border-radius: 3px;
  font-weight: normal;
  font-size: 1em;
  line-height: inherit;
  transition: background-color 300ms ease-out;
}
ul.pagination li:hover a,
ul.pagination li a:focus, ul.pagination li:hover button,
ul.pagination li button:focus {
  background: #e6e6e6;
}
ul.pagination li.unavailable a, ul.pagination li.unavailable button {
  cursor: default;
  color: #999999;
}
ul.pagination li.unavailable:hover a, ul.pagination li.unavailable a:focus, ul.pagination li.unavailable:hover button, ul.pagination li.unavailable button:focus {
  background: transparent;
}
ul.pagination li.current a, ul.pagination li.current button {
  background: #2F9ACD;
  color: #FFFFFF;
  font-weight: 600;
  cursor: default;
}
ul.pagination li.current a:hover, ul.pagination li.current a:focus, ul.pagination li.current button:hover, ul.pagination li.current button:focus {
  background: #2F9ACD;
}
ul.pagination li {
  float: left;
  display: block;
}

/* Pagination centred wrapper */
.pagination-centered {
  text-align: center;
}
.pagination-centered ul.pagination li {
  float: none;
  display: inline-block;
}

/* Panels */
.panel {
  border-style: solid;
  border-width: 1px;
  border-color: #d8d8d8;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f2f2f2;
  color: #333333;
}
.panel > :first-child {
  margin-top: 0;
}
.panel > :last-child {
  margin-bottom: 0;
}
.panel h1, .panel h2, .panel h3, .panel h4, .panel h5, .panel h6, .panel p, .panel li, .panel dl {
  color: #333333;
}
.panel h1, .panel h2, .panel h3, .panel h4, .panel h5, .panel h6 {
  line-height: 1;
  margin-bottom: 0.625rem;
}
.panel h1.subheader, .panel h2.subheader, .panel h3.subheader, .panel h4.subheader, .panel h5.subheader, .panel h6.subheader {
  line-height: 1.4;
}
.panel.callout {
  border-style: solid;
  border-width: 1px;
  border-color: #c6e4f2;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background: #f2f9fc;
  color: #333333;
}
.panel.callout > :first-child {
  margin-top: 0;
}
.panel.callout > :last-child {
  margin-bottom: 0;
}
.panel.callout h1, .panel.callout h2, .panel.callout h3, .panel.callout h4, .panel.callout h5, .panel.callout h6, .panel.callout p, .panel.callout li, .panel.callout dl {
  color: #333333;
}
.panel.callout h1, .panel.callout h2, .panel.callout h3, .panel.callout h4, .panel.callout h5, .panel.callout h6 {
  line-height: 1;
  margin-bottom: 0.625rem;
}
.panel.callout h1.subheader, .panel.callout h2.subheader, .panel.callout h3.subheader, .panel.callout h4.subheader, .panel.callout h5.subheader, .panel.callout h6.subheader {
  line-height: 1.4;
}
.panel.callout a:not(.button) {
  color: #2F9ACD;
}
.panel.callout a:not(.button):hover, .panel.callout a:not(.button):focus {
  color: #2884b0;
}
.panel.radius {
  border-radius: 3px;
}

/* Pricing Tables */
.pricing-table {
  border: solid 1px #DDDDDD;
  margin-left: 0;
  margin-bottom: 1.25rem;
}
.pricing-table * {
  list-style: none;
  line-height: 1;
}
.pricing-table .title {
  background-color: #333333;
  padding: 0.9375rem 1.25rem;
  text-align: center;
  color: #EEEEEE;
  font-weight: 400;
  font-size: 1rem;
  font-family: "AvenirNextLTPro", sans-serif;
}
.pricing-table .price {
  background-color: #F6F6F6;
  padding: 0.9375rem 1.25rem;
  text-align: center;
  color: #333333;
  font-weight: 400;
  font-size: 2rem;
  font-family: "AvenirNextLTPro", sans-serif;
}
.pricing-table .description {
  background-color: #FFFFFF;
  padding: 0.9375rem;
  text-align: center;
  color: #777777;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
  border-bottom: dotted 1px #DDDDDD;
}
.pricing-table .bullet-item {
  background-color: #FFFFFF;
  padding: 0.9375rem;
  text-align: center;
  color: #333333;
  font-size: 0.875rem;
  font-weight: 400;
  border-bottom: dotted 1px #DDDDDD;
}
.pricing-table .cta-button {
  background-color: #FFFFFF;
  text-align: center;
  padding: 1.25rem 1.25rem 0;
}

/* Progress Bar */
.progress {
  background-color: #F6F6F6;
  height: 1.5625rem;
  border: 1px solid white;
  padding: 0.125rem;
  margin-bottom: 0.625rem;
}
.progress .meter {
  background: #2F9ACD;
  height: 100%;
  display: block;
}
.progress.secondary .meter {
  background: #616161;
  height: 100%;
  display: block;
}
.progress.success .meter {
  background: #8BB741;
  height: 100%;
  display: block;
}
.progress.alert .meter {
  background: #DF5146;
  height: 100%;
  display: block;
}
.progress.radius {
  border-radius: 3px;
}
.progress.radius .meter {
  border-radius: 2px;
}
.progress.round {
  border-radius: 1000px;
}
.progress.round .meter {
  border-radius: 999px;
}

.range-slider {
  position: relative;
  border: 1px solid #DDDDDD;
  margin: 1.25rem 0;
  -ms-touch-action: none;
  touch-action: none;
  display: block;
  width: 100%;
  height: 1rem;
  background: #FAFAFA;
}
.range-slider.vertical-range {
  position: relative;
  border: 1px solid #DDDDDD;
  margin: 1.25rem 0;
  -ms-touch-action: none;
  touch-action: none;
  display: inline-block;
  width: 1rem;
  height: 12.5rem;
}
.range-slider.vertical-range .range-slider-handle {
  margin-top: 0;
  margin-left: -0.5rem;
  position: absolute;
  bottom: -10.5rem;
}
.range-slider.vertical-range .range-slider-active-segment {
  width: 0.875rem;
  height: auto;
  bottom: 0;
}
.range-slider.radius {
  background: #FAFAFA;
  border-radius: 3px;
}
.range-slider.radius .range-slider-handle {
  background: #2F9ACD;
  border-radius: 3px;
}
.range-slider.radius .range-slider-handle:hover {
  background: #2988b4;
}
.range-slider.round {
  background: #FAFAFA;
  border-radius: 1000px;
}
.range-slider.round .range-slider-handle {
  background: #2F9ACD;
  border-radius: 1000px;
}
.range-slider.round .range-slider-handle:hover {
  background: #2988b4;
}
.range-slider.disabled, .range-slider[disabled] {
  background: #FAFAFA;
  cursor: not-allowed;
  opacity: 0.7;
}
.range-slider.disabled .range-slider-handle, .range-slider[disabled] .range-slider-handle {
  background: #2F9ACD;
  cursor: default;
  opacity: 0.7;
}
.range-slider.disabled .range-slider-handle:hover, .range-slider[disabled] .range-slider-handle:hover {
  background: #2988b4;
}

.range-slider-active-segment {
  display: inline-block;
  position: absolute;
  height: 0.875rem;
  background: #606060;
}

.range-slider-handle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: -0.3125rem;
  width: 2rem;
  height: 1.375rem;
  border: 1px solid none;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #2F9ACD;
}
.range-slider-handle:hover {
  background: #2988b4;
}

.reveal-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1004;
  display: none;
  left: 0;
}

.reveal-modal {
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: 1005;
  width: 100%;
  top: 0;
  border-radius: 3px;
  left: 0;
  background-color: #FFFFFF;
  padding: 0;
  border: solid 1px #666666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 39.937em) {
  .reveal-modal {
    min-height: 100vh;
  }
}
.reveal-modal .column, .reveal-modal .columns {
  min-width: 0;
}
.reveal-modal > :first-child {
  margin-top: 0;
}
.reveal-modal > :last-child {
  margin-bottom: 0;
}
@media only screen and (min-width:40em) {
  .reveal-modal {
    width: 80%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width:40em) {
  .reveal-modal {
    top: 6.25rem;
  }
}
.reveal-modal.radius {
  border-radius: 3px;
}
.reveal-modal.round {
  border-radius: 1000px;
}
.reveal-modal.collapse {
  padding: 0;
}
@media only screen and (min-width:40em) {
  .reveal-modal.tiny {
    width: 30%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width:40em) {
  .reveal-modal.small {
    width: 40%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width:40em) {
  .reveal-modal.medium {
    width: 60%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width:40em) {
  .reveal-modal.large {
    width: 70%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width:40em) {
  .reveal-modal.xlarge {
    width: 95%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.reveal-modal.full {
  top: 0;
  left: 0;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  max-width: none !important;
  margin-left: 0 !important;
}
@media only screen and (min-width:40em) {
  .reveal-modal.full {
    width: 100%;
    max-width: 6.25rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.reveal-modal.toback {
  z-index: 1003;
}
.reveal-modal .close-reveal-modal {
  font-size: 2.5rem;
  line-height: 1;
  position: absolute;
  top: 0.625rem;
  right: 1.375rem;
  color: #AAAAAA;
  font-weight: 600;
  cursor: pointer;
}

.side-nav {
  display: block;
  margin: 0;
  padding: 0.875rem 0;
  list-style-type: none;
  list-style-position: outside;
  font-family: "AvenirNextLTPro", sans-serif;
}
.side-nav li {
  margin: 0 0 0.4375rem 0;
  font-size: 0.875rem;
  font-weight: 400;
}
.side-nav li a:not(.button) {
  display: block;
  color: #2F9ACD;
  margin: 0;
  padding: 0.4375rem 0.875rem;
}
.side-nav li a:not(.button):hover, .side-nav li a:not(.button):focus {
  background: hsla(0, 0%, 0%, 0.025);
  color: #6cb9dd;
}
.side-nav li.active > a:first-child:not(.button) {
  color: #6cb9dd;
  font-weight: 400;
  font-family: "AvenirNextLTPro", sans-serif;
}
.side-nav li.divider {
  border-top: 1px solid;
  height: 0;
  padding: 0;
  list-style: none;
  border-top-color: white;
}
.side-nav li.heading {
  color: #2F9ACD;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.split.button {
  position: relative;
  padding-right: 5.0625rem;
}
.split.button span {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: solid 1px;
}
.split.button span:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  border-style: inset;
  top: 50%;
  left: 50%;
}
.split.button span:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.split.button span {
  border-left-color: rgba(255, 255, 255, 0.5);
}
.split.button span {
  width: 3.09375rem;
}
.split.button span:after {
  border-top-style: solid;
  border-width: 0.375rem;
  top: 48%;
  margin-left: -0.375rem;
}
.split.button span:after {
  border-color: #FFFFFF transparent transparent transparent;
}
.split.button.secondary span {
  border-left-color: rgba(255, 255, 255, 0.5);
}
.split.button.secondary span:after {
  border-color: #FFFFFF transparent transparent transparent;
}
.split.button.alert span {
  border-left-color: rgba(255, 255, 255, 0.5);
}
.split.button.success span {
  border-left-color: rgba(255, 255, 255, 0.5);
}
.split.button.tiny {
  padding-right: 3.75rem;
}
.split.button.tiny span {
  width: 2.25rem;
}
.split.button.tiny span:after {
  border-top-style: solid;
  border-width: 0.375rem;
  top: 48%;
  margin-left: -0.375rem;
}
.split.button.small {
  padding-right: 4.375rem;
}
.split.button.small span {
  width: 2.625rem;
}
.split.button.small span:after {
  border-top-style: solid;
  border-width: 0.4375rem;
  top: 48%;
  margin-left: -0.375rem;
}
.split.button.large {
  padding-right: 5.5rem;
}
.split.button.large span {
  width: 3.4375rem;
}
.split.button.large span:after {
  border-top-style: solid;
  border-width: 0.3125rem;
  top: 48%;
  margin-left: -0.375rem;
}
.split.button.expand {
  padding-left: 2rem;
}
.split.button.secondary span:after {
  border-color: #333333 transparent transparent transparent;
}
.split.button.radius span {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.split.button.round span {
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}
.split.button.no-pip span:before {
  border-style: none;
}
.split.button.no-pip span:after {
  border-style: none;
}
.split.button.no-pip span > i {
  top: 50%;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -0.28889em;
  margin-top: -0.48889em;
}

.sub-nav {
  display: block;
  width: auto;
  overflow: hidden;
  margin-bottom: -0.25rem 0 1.125rem;
  padding-top: 0.25rem;
}
.sub-nav dt {
  text-transform: uppercase;
}
.sub-nav dt,
.sub-nav dd,
.sub-nav li {
  float: left;
  margin-left: 1rem;
  margin-bottom: 0;
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #999999;
}
.sub-nav dt a,
.sub-nav dd a,
.sub-nav li a {
  text-decoration: none;
  color: #999999;
  padding: 0.1875rem 1rem;
}
.sub-nav dt a:hover,
.sub-nav dd a:hover,
.sub-nav li a:hover {
  color: #737373;
}
.sub-nav dt.active a,
.sub-nav dd.active a,
.sub-nav li.active a {
  border-radius: 3px;
  font-weight: 400;
  background: #2F9ACD;
  padding: 0.1875rem 1rem;
  cursor: default;
  color: #FFFFFF;
}
.sub-nav dt.active a:hover,
.sub-nav dd.active a:hover,
.sub-nav li.active a:hover {
  background: #2884b0;
}

.switch {
  padding: 0;
  border: none;
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.switch label, .switch ticket-add-edit-modal dt, ticket-add-edit-modal .switch dt, .switch add-edit-ticket-modal dt, add-edit-ticket-modal .switch dt {
  display: block;
  margin-bottom: 1rem;
  position: relative;
  color: transparent;
  background: #DDDDDD;
  text-indent: 100%;
  width: 4rem;
  height: 2rem;
  cursor: pointer;
  transition: left 0.15s ease-out;
}
.switch input {
  opacity: 0;
  position: absolute;
  top: 9px;
  left: 10px;
  padding: 0;
}
.switch input + label, .switch ticket-add-edit-modal input + dt, ticket-add-edit-modal .switch input + dt, .switch add-edit-ticket-modal input + dt, add-edit-ticket-modal .switch input + dt {
  margin-left: 0;
  margin-right: 0;
}
.switch label:after, .switch ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch dt:after, .switch add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch dt:after {
  content: "";
  display: block;
  background: #FFFFFF;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: translate3d(0, 0, 0);
  transition: left 0.15s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.switch input:checked + label, .switch ticket-add-edit-modal input:checked + dt, ticket-add-edit-modal .switch input:checked + dt, .switch add-edit-ticket-modal input:checked + dt, add-edit-ticket-modal .switch input:checked + dt {
  background: #2F9ACD;
}
.switch input:checked + label:after, .switch ticket-add-edit-modal input:checked + dt:after, ticket-add-edit-modal .switch input:checked + dt:after, .switch add-edit-ticket-modal input:checked + dt:after, add-edit-ticket-modal .switch input:checked + dt:after {
  left: 2.25rem;
}
.switch label, .switch ticket-add-edit-modal dt, ticket-add-edit-modal .switch dt, .switch add-edit-ticket-modal dt, add-edit-ticket-modal .switch dt {
  width: 4rem;
  height: 2rem;
}
.switch label:after, .switch ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch dt:after, .switch add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch dt:after {
  width: 1.5rem;
  height: 1.5rem;
}
.switch input:checked + label:after, .switch ticket-add-edit-modal input:checked + dt:after, ticket-add-edit-modal .switch input:checked + dt:after, .switch add-edit-ticket-modal input:checked + dt:after, add-edit-ticket-modal .switch input:checked + dt:after {
  left: 2.25rem;
}
.switch label, .switch ticket-add-edit-modal dt, ticket-add-edit-modal .switch dt, .switch add-edit-ticket-modal dt, add-edit-ticket-modal .switch dt {
  color: transparent;
  background: #DDDDDD;
}
.switch label:after, .switch ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch dt:after, .switch add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch dt:after {
  background: #FFFFFF;
}
.switch input:checked + label, .switch ticket-add-edit-modal input:checked + dt, ticket-add-edit-modal .switch input:checked + dt, .switch add-edit-ticket-modal input:checked + dt, add-edit-ticket-modal .switch input:checked + dt {
  background: #2F9ACD;
}
.switch.large label, .switch.large ticket-add-edit-modal dt, ticket-add-edit-modal .switch.large dt, .switch.large add-edit-ticket-modal dt, add-edit-ticket-modal .switch.large dt {
  width: 5rem;
  height: 2.5rem;
}
.switch.large label:after, .switch.large ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch.large dt:after, .switch.large add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch.large dt:after {
  width: 2rem;
  height: 2rem;
}
.switch.large input:checked + label:after, .switch.large ticket-add-edit-modal input:checked + dt:after, ticket-add-edit-modal .switch.large input:checked + dt:after, .switch.large add-edit-ticket-modal input:checked + dt:after, add-edit-ticket-modal .switch.large input:checked + dt:after {
  left: 2.75rem;
}
.switch.small label, .switch.small ticket-add-edit-modal dt, ticket-add-edit-modal .switch.small dt, .switch.small add-edit-ticket-modal dt, add-edit-ticket-modal .switch.small dt {
  width: 3.5rem;
  height: 1.75rem;
}
.switch.small label:after, .switch.small ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch.small dt:after, .switch.small add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch.small dt:after {
  width: 1.25rem;
  height: 1.25rem;
}
.switch.small input:checked + label:after, .switch.small ticket-add-edit-modal input:checked + dt:after, ticket-add-edit-modal .switch.small input:checked + dt:after, .switch.small add-edit-ticket-modal input:checked + dt:after, add-edit-ticket-modal .switch.small input:checked + dt:after {
  left: 2rem;
}
.switch.tiny label, .switch.tiny ticket-add-edit-modal dt, ticket-add-edit-modal .switch.tiny dt, .switch.tiny add-edit-ticket-modal dt, add-edit-ticket-modal .switch.tiny dt {
  width: 3rem;
  height: 1.5rem;
}
.switch.tiny label:after, .switch.tiny ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch.tiny dt:after, .switch.tiny add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch.tiny dt:after {
  width: 1rem;
  height: 1rem;
}
.switch.tiny input:checked + label:after, .switch.tiny ticket-add-edit-modal input:checked + dt:after, ticket-add-edit-modal .switch.tiny input:checked + dt:after, .switch.tiny add-edit-ticket-modal input:checked + dt:after, add-edit-ticket-modal .switch.tiny input:checked + dt:after {
  left: 1.75rem;
}
.switch.radius label, .switch.radius ticket-add-edit-modal dt, ticket-add-edit-modal .switch.radius dt, .switch.radius add-edit-ticket-modal dt, add-edit-ticket-modal .switch.radius dt {
  border-radius: 4px;
}
.switch.radius label:after, .switch.radius ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch.radius dt:after, .switch.radius add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch.radius dt:after {
  border-radius: 3px;
}
.switch.round {
  border-radius: 1000px;
}
.switch.round label, .switch.round ticket-add-edit-modal dt, ticket-add-edit-modal .switch.round dt, .switch.round add-edit-ticket-modal dt, add-edit-ticket-modal .switch.round dt {
  border-radius: 2rem;
}
.switch.round label:after, .switch.round ticket-add-edit-modal dt:after, ticket-add-edit-modal .switch.round dt:after, .switch.round add-edit-ticket-modal dt:after, add-edit-ticket-modal .switch.round dt:after {
  border-radius: 2rem;
}

table {
  background: #FFFFFF;
  margin-bottom: 1.25rem;
  border: solid 1px #E0E0E0;
  table-layout: auto;
}
table caption {
  background: transparent;
  color: inherit;
  font-size: 1rem;
  font-weight: bold;
}
table thead {
  background: #E0E0E0;
}
table thead tr th,
table thead tr td {
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
table tfoot {
  background: #E0E0E0;
}
table tfoot tr th,
table tfoot tr td {
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
table tr th,
table tr td {
  padding: 0.5625rem 0.625rem;
  font-size: 0.875rem;
  color: inherit;
  text-align: left;
}
table tr.even, table tr.alt, table tr:nth-of-type(even) {
  background: #FFFFFF;
}
table thead tr th,
table tfoot tr th,
table tfoot tr td,
table tbody tr th,
table tbody tr td,
table tr td {
  display: table-cell;
  line-height: 1.125rem;
}

.tabs {
  margin-bottom: 0 !important;
  margin-left: 0;
}
.tabs:before, .tabs:after {
  content: " ";
  display: table;
}
.tabs:after {
  clear: both;
}
.tabs dd, .tabs .tab-title {
  position: relative;
  margin-bottom: 0 !important;
  list-style: none;
  float: left;
}
.tabs dd > a, .tabs .tab-title > a {
  display: block;
  background-color: transparent;
  color: #212121;
  padding: 0.25rem 0.5rem;
  font-family: "AvenirNextLTPro", sans-serif;
  font-size: 0.75rem;
}
.tabs dd > a:hover, .tabs .tab-title > a:hover {
  background-color: transparent;
}
.tabs dd > a:focus, .tabs .tab-title > a:focus {
  outline: none;
}
.tabs dd.active a, .tabs .tab-title.active a {
  background-color: transparent;
  color: #212121;
}
.tabs.radius dd:first-child a, .tabs.radius .tab:first-child a {
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.tabs.radius dd:last-child a, .tabs.radius .tab:last-child a {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.tabs.vertical dd, .tabs.vertical .tab-title {
  position: inherit;
  float: none;
  display: block;
  top: auto;
}

.tabs-content {
  margin-bottom: 1.5rem;
  width: 100%;
}
.tabs-content:before, .tabs-content:after {
  content: " ";
  display: table;
}
.tabs-content:after {
  clear: both;
}
.tabs-content > .content {
  display: none;
  float: left;
  padding: 0.5rem 0;
  width: 100%;
}
.tabs-content > .content.active {
  display: block;
  float: none;
}
.tabs-content > .content.contained {
  padding: 0.5rem;
}
.tabs-content.vertical {
  display: block;
}
.tabs-content.vertical > .content {
  padding: 0 0.5rem;
}

@media only screen and (min-width:40em) {
  .tabs.vertical {
    width: 20%;
    max-width: 20%;
    float: left;
    margin: 0 0 1.25rem;
  }
  .tabs-content.vertical {
    width: 80%;
    max-width: 80%;
    float: left;
    margin-left: -1px;
    padding-left: 1rem;
  }
}
.no-js .tabs-content > .content {
  display: block;
  float: none;
}

/* Image Thumbnails */
.th {
  line-height: 0;
  display: inline-block;
  border: solid 4px #FFFFFF;
  max-width: 100%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-out;
}
.th:hover, .th:focus {
  box-shadow: 0 0 6px 1px rgba(47, 154, 205, 0.5);
}
.th.radius {
  border-radius: 3px;
}

/* Tooltips */
.has-tip {
  border-bottom: dotted 1px #CCCCCC;
  cursor: help;
  font-weight: 600;
  color: #333333;
}
.has-tip:hover, .has-tip:focus {
  border-bottom: dotted 1px #15455c;
  color: #2F9ACD;
}
.has-tip.tip-left, .has-tip.tip-right {
  float: none !important;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 1006;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 0.75rem;
  max-width: 300px;
  left: 50%;
  width: 100%;
  color: #FFFFFF;
  background: #333333;
}
.tooltip > .nub {
  display: block;
  left: 5px;
  position: absolute;
  width: 0;
  height: 0;
  border: solid 5px;
  border-color: transparent transparent #333333 transparent;
  top: -10px;
  pointer-events: none;
}
.tooltip > .nub.rtl {
  left: auto;
  right: 5px;
}
.tooltip.radius {
  border-radius: 3px;
}
.tooltip.round {
  border-radius: 1000px;
}
.tooltip.round > .nub {
  left: 2rem;
}
.tooltip.opened {
  color: #2F9ACD !important;
  border-bottom: dotted 1px #15455c !important;
}

.tap-to-close {
  display: block;
  font-size: 0.625rem;
  color: #777777;
  font-weight: 400;
}

@media only screen and (min-width:40em) {
  .tooltip > .nub {
    border-color: transparent transparent #333333 transparent;
    top: -10px;
  }
  .tooltip.tip-top > .nub {
    border-color: #333333 transparent transparent transparent;
    top: auto;
    bottom: -10px;
  }
  .tooltip.tip-left, .tooltip.tip-right {
    float: none !important;
  }
  .tooltip.tip-left > .nub {
    border-color: transparent transparent transparent #333333;
    right: -10px;
    left: auto;
    top: 50%;
    margin-top: -5px;
  }
  .tooltip.tip-right > .nub {
    border-color: transparent #333333 transparent transparent;
    right: auto;
    left: -10px;
    top: 50%;
    margin-top: -5px;
  }
}
meta.foundation-mq-topbar {
  font-family: "/only screen and (min-width:40em)/";
  width: 40em;
}

/* Wrapped around .top-bar to contain to grid width */
.contain-to-grid {
  width: 100%;
  background: none;
}
.contain-to-grid .top-bar {
  margin-bottom: 0;
}

.fixed {
  width: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 99;
}
.fixed.expanded:not(.top-bar) {
  overflow-y: auto;
  height: auto;
  width: 100%;
  max-height: 100%;
}
.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.fixed.expanded:not(.top-bar) .top-bar-section {
  z-index: 98;
  margin-top: 2rem;
}

.top-bar {
  overflow: hidden;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  background: none;
  margin-bottom: 0;
}
.top-bar ul {
  margin-bottom: 0;
  list-style: none;
}
.top-bar .row {
  max-width: none;
}
.top-bar form,
.top-bar input {
  margin-bottom: 0;
}
.top-bar input {
  height: 1.75rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  font-size: 0.75rem;
}
.top-bar .button, .top-bar button {
  padding-top: 0.4125rem;
  padding-bottom: 0.4125rem;
  margin-bottom: 0;
  font-size: 0.75rem;
}
@media only screen and (max-width: 39.937em) {
  .top-bar .button, .top-bar button {
    position: relative;
    top: -1px;
  }
}
.top-bar .title-area {
  position: relative;
  margin: 0;
}
.top-bar .name {
  height: 2rem;
  margin: 0;
  font-size: 16px;
}
.top-bar .name h1, .top-bar .name h2, .top-bar .name h3, .top-bar .name h4, .top-bar .name p, .top-bar .name span {
  line-height: 2rem;
  font-size: 1.0625rem;
  margin: 0;
}
.top-bar .name h1 a, .top-bar .name h2 a, .top-bar .name h3 a, .top-bar .name h4 a, .top-bar .name p a, .top-bar .name span a {
  font-weight: 400;
  color: #212121;
  width: 75%;
  display: block;
  padding: 0 0.6666666667rem;
}
.top-bar .toggle-topbar {
  position: absolute;
  right: 0;
  top: 0;
}
.top-bar .toggle-topbar a {
  color: #212121;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  position: relative;
  display: block;
  padding: 0 0.6666666667rem;
  height: 2rem;
  line-height: 2rem;
}
.top-bar .toggle-topbar.menu-icon {
  top: 50%;
  margin-top: -16px;
}
.top-bar .toggle-topbar.menu-icon a {
  height: 34px;
  line-height: 33px;
  padding: 0 2.2291666667rem 0 0.6666666667rem;
  color: #FFFFFF;
  position: relative;
}
.top-bar .toggle-topbar.menu-icon a span::after {
  content: "";
  position: absolute;
  display: block;
  height: 0;
  top: 50%;
  margin-top: -8px;
  right: 0.6666666667rem;
  box-shadow: 0 0 0 1px #FFFFFF, 0 7px 0 1px #FFFFFF, 0 14px 0 1px #FFFFFF;
  width: 16px;
}
.top-bar .toggle-topbar.menu-icon a span:hover:after {
  box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px "";
}
.top-bar.expanded {
  height: auto;
  background: transparent;
}
.top-bar.expanded .title-area {
  background: none;
}
.top-bar.expanded .toggle-topbar a {
  color: #888888;
}
.top-bar.expanded .toggle-topbar a span::after {
  box-shadow: 0 0 0 1px #888888, 0 7px 0 1px #888888, 0 14px 0 1px #888888;
}

.top-bar-section {
  left: 0;
  position: relative;
  width: auto;
  transition: left 300ms ease-out;
}
.top-bar-section ul {
  padding: 0;
  width: 100%;
  height: auto;
  display: block;
  font-size: 16px;
  margin: 0;
}
.top-bar-section .divider,
.top-bar-section [role=separator] {
  border-top: 1px solid #E0E0E0;
  clear: both;
  height: 1px;
  width: 100%;
}
.top-bar-section ul li {
  background: #FFFFFF;
}
.top-bar-section ul li > a {
  display: block;
  width: 100%;
  color: #212121;
  padding: 12px 0 12px 0;
  padding-left: 0.6666666667rem;
  font-family: "AvenirNextLTPro", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  text-transform: none;
}
.top-bar-section ul li > a.button {
  font-size: 0.8125rem;
  padding-right: 0.6666666667rem;
  padding-left: 0.6666666667rem;
  background-color: #2F9ACD;
  border-color: #267ba4;
  color: #FFFFFF;
}
.top-bar-section ul li > a.button:hover, .top-bar-section ul li > a.button:focus {
  background-color: #267ba4;
}
.top-bar-section ul li > a.button:hover, .top-bar-section ul li > a.button:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.secondary {
  background-color: #616161;
  border-color: #4e4e4e;
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.secondary:hover, .top-bar-section ul li > a.button.secondary:focus {
  background-color: #4e4e4e;
}
.top-bar-section ul li > a.button.secondary:hover, .top-bar-section ul li > a.button.secondary:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.success {
  background-color: #8BB741;
  border-color: #6f9234;
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.success:hover, .top-bar-section ul li > a.button.success:focus {
  background-color: #6f9234;
}
.top-bar-section ul li > a.button.success:hover, .top-bar-section ul li > a.button.success:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.alert {
  background-color: #DF5146;
  border-color: #c82e23;
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.alert:hover, .top-bar-section ul li > a.button.alert:focus {
  background-color: #c82e23;
}
.top-bar-section ul li > a.button.alert:hover, .top-bar-section ul li > a.button.alert:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.warning {
  background-color: #FEA82F;
  border-color: #f08d01;
  color: #FFFFFF;
}
.top-bar-section ul li > a.button.warning:hover, .top-bar-section ul li > a.button.warning:focus {
  background-color: #f08d01;
}
.top-bar-section ul li > a.button.warning:hover, .top-bar-section ul li > a.button.warning:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > button {
  font-size: 0.8125rem;
  padding-right: 0.6666666667rem;
  padding-left: 0.6666666667rem;
  background-color: #2F9ACD;
  border-color: #267ba4;
  color: #FFFFFF;
}
.top-bar-section ul li > button:hover, .top-bar-section ul li > button:focus {
  background-color: #267ba4;
}
.top-bar-section ul li > button:hover, .top-bar-section ul li > button:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > button.secondary {
  background-color: #616161;
  border-color: #4e4e4e;
  color: #FFFFFF;
}
.top-bar-section ul li > button.secondary:hover, .top-bar-section ul li > button.secondary:focus {
  background-color: #4e4e4e;
}
.top-bar-section ul li > button.secondary:hover, .top-bar-section ul li > button.secondary:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > button.success {
  background-color: #8BB741;
  border-color: #6f9234;
  color: #FFFFFF;
}
.top-bar-section ul li > button.success:hover, .top-bar-section ul li > button.success:focus {
  background-color: #6f9234;
}
.top-bar-section ul li > button.success:hover, .top-bar-section ul li > button.success:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > button.alert {
  background-color: #DF5146;
  border-color: #c82e23;
  color: #FFFFFF;
}
.top-bar-section ul li > button.alert:hover, .top-bar-section ul li > button.alert:focus {
  background-color: #c82e23;
}
.top-bar-section ul li > button.alert:hover, .top-bar-section ul li > button.alert:focus {
  color: #FFFFFF;
}
.top-bar-section ul li > button.warning {
  background-color: #FEA82F;
  border-color: #f08d01;
  color: #FFFFFF;
}
.top-bar-section ul li > button.warning:hover, .top-bar-section ul li > button.warning:focus {
  background-color: #f08d01;
}
.top-bar-section ul li > button.warning:hover, .top-bar-section ul li > button.warning:focus {
  color: #FFFFFF;
}
.top-bar-section ul li:hover:not(.has-form) > a {
  background-color: #555555;
  background: none;
  color: #212121;
}
.top-bar-section ul li.active > a {
  background: "transparent";
  color: #212121;
}
.top-bar-section ul li.active > a:hover {
  background: none;
  color: #212121;
}
.top-bar-section .has-form {
  padding: 0.6666666667rem;
}
.top-bar-section .has-dropdown {
  position: relative;
}
.top-bar-section .has-dropdown > a:after {
  margin-right: 0.6666666667rem;
  margin-top: -4.5px;
  position: absolute;
  top: 50%;
  right: 0;
}
.top-bar-section .has-dropdown.moved {
  position: static;
}
.top-bar-section .has-dropdown.moved > .dropdown {
  display: block;
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
  position: absolute !important;
  width: 100%;
}
.top-bar-section .has-dropdown.moved > a:after {
  display: none;
}
.top-bar-section .dropdown {
  padding: 0;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 99;
  display: block;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.top-bar-section .dropdown li {
  width: 100%;
  height: auto;
}
.top-bar-section .dropdown li a {
  font-weight: 400;
  padding: 8px 0.6666666667rem;
}
.top-bar-section .dropdown li a.parent-link {
  font-weight: 400;
}
.top-bar-section .dropdown li.title h5, .top-bar-section .dropdown li.parent-link {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.125rem;
}
.top-bar-section .dropdown li.title h5 a, .top-bar-section .dropdown li.parent-link a {
  color: #212121;
  display: block;
}
.top-bar-section .dropdown li.title h5 a:hover, .top-bar-section .dropdown li.parent-link a:hover {
  background: none;
}
.top-bar-section .dropdown li.has-form {
  padding: 8px 0.6666666667rem;
}
.top-bar-section .dropdown li .button, .top-bar-section .dropdown li button {
  top: auto;
}
.top-bar-section .dropdown label, .top-bar-section .dropdown ticket-add-edit-modal dt, ticket-add-edit-modal .top-bar-section .dropdown dt, .top-bar-section .dropdown add-edit-ticket-modal dt, add-edit-ticket-modal .top-bar-section .dropdown dt {
  padding: 8px 0.6666666667rem 2px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #777777;
  font-weight: 600;
  font-size: 0.625rem;
}

.js-generated {
  display: block;
}

@media only screen and (min-width:40em) {
  .top-bar {
    background: none;
    overflow: visible;
  }
  .top-bar:before, .top-bar:after {
    content: " ";
    display: table;
  }
  .top-bar:after {
    clear: both;
  }
  .top-bar .toggle-topbar {
    display: none;
  }
  .top-bar .title-area {
    float: left;
  }
  .top-bar .name h1 a,
  .top-bar .name h2 a,
  .top-bar .name h3 a,
  .top-bar .name h4 a,
  .top-bar .name h5 a,
  .top-bar .name h6 a {
    width: auto;
  }
  .top-bar input,
  .top-bar .button,
  .top-bar button {
    font-size: 0.875rem;
    position: relative;
    height: 1.75rem;
    top: 0.125rem;
  }
  .top-bar.expanded {
    background: none;
  }
  .contain-to-grid .top-bar {
    max-width: 6.25rem;
    margin: 0 auto;
    margin-bottom: 0;
  }
  .top-bar-section {
    transition: none 0 0;
    left: 0 !important;
  }
  .top-bar-section ul {
    width: auto;
    height: auto !important;
    display: inline;
  }
  .top-bar-section ul li {
    float: left;
  }
  .top-bar-section ul li .js-generated {
    display: none;
  }
  .top-bar-section li.hover > a:not(.button) {
    background-color: #555555;
    background: none;
    color: #212121;
  }
  .top-bar-section li:not(.has-form) a:not(.button) {
    padding: 0 0.6666666667rem;
    line-height: 2rem;
    background: "transparent";
  }
  .top-bar-section li:not(.has-form) a:not(.button):hover {
    background-color: #555555;
    background: none;
  }
  .top-bar-section li.active:not(.has-form) a:not(.button) {
    padding: 0 0.6666666667rem;
    line-height: 2rem;
    color: #212121;
    background: "transparent";
  }
  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    background: none;
    color: #212121;
  }
  .top-bar-section .has-dropdown.moved {
    position: relative;
  }
  .top-bar-section .has-dropdown.moved > .dropdown {
    display: block;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .top-bar-section .has-dropdown.hover > .dropdown, .top-bar-section .has-dropdown.not-click:hover > .dropdown {
    display: block;
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    position: absolute !important;
  }
  .top-bar-section .has-dropdown > a:focus + .dropdown {
    display: block;
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    position: absolute !important;
  }
  .top-bar-section .has-dropdown .dropdown li.has-dropdown > a:after {
    border: none;
    content: "»";
    top: 1rem;
    margin-top: -1px;
    right: 5px;
    line-height: 1.2;
  }
  .top-bar-section .dropdown {
    left: 0;
    top: auto;
    background: transparent;
    min-width: 100%;
  }
  .top-bar-section .dropdown li a {
    color: #212121;
    line-height: 2rem;
    white-space: nowrap;
    padding: 12px 0.6666666667rem;
    background: #FFFFFF;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active) > a:not(.button) {
    color: #212121;
    background: #FFFFFF;
  }
  .top-bar-section .dropdown li:not(.has-form):not(.active):hover > a:not(.button) {
    color: #212121;
    background-color: #555555;
    background: #BDBDBD;
  }
  .top-bar-section .dropdown li label, .top-bar-section .dropdown li ticket-add-edit-modal dt, ticket-add-edit-modal .top-bar-section .dropdown li dt, .top-bar-section .dropdown li add-edit-ticket-modal dt, add-edit-ticket-modal .top-bar-section .dropdown li dt {
    white-space: nowrap;
    background: #333333;
  }
  .top-bar-section .dropdown li .dropdown {
    left: 100%;
    top: 0;
  }
  .top-bar-section > ul > .divider, .top-bar-section > ul > [role=separator] {
    border-bottom: none;
    border-top: none;
    border-right: none;
    clear: none;
    height: 2rem;
    width: 0;
  }
  .top-bar-section .has-form {
    background: "transparent";
    padding: 0 0.6666666667rem;
    height: 2rem;
  }
  .top-bar-section .right li .dropdown {
    left: auto;
    right: 0;
  }
  .top-bar-section .right li .dropdown li .dropdown {
    right: 100%;
  }
  .top-bar-section .left li .dropdown {
    right: auto;
    left: 0;
  }
  .top-bar-section .left li .dropdown li .dropdown {
    left: 100%;
  }
  .no-js .top-bar-section ul li:hover > a {
    background-color: #555555;
    background: none;
    color: #212121;
  }
  .no-js .top-bar-section ul li:active > a {
    background: "transparent";
    color: #212121;
  }
  .no-js .top-bar-section .has-dropdown:hover > .dropdown {
    display: block;
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    position: absolute !important;
  }
  .no-js .top-bar-section .has-dropdown > a:focus + .dropdown {
    display: block;
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    position: absolute !important;
  }
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 39.937em) {
  .small-only-text-left {
    text-align: left !important;
  }
  .small-only-text-right {
    text-align: right !important;
  }
  .small-only-text-center {
    text-align: center !important;
  }
  .small-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen {
  .small-text-left {
    text-align: left !important;
  }
  .small-text-right {
    text-align: right !important;
  }
  .small-text-center {
    text-align: center !important;
  }
  .small-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:40em) and (max-width:63.397em) {
  .medium-only-text-left {
    text-align: left !important;
  }
  .medium-only-text-right {
    text-align: right !important;
  }
  .medium-only-text-center {
    text-align: center !important;
  }
  .medium-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:40em) {
  .medium-text-left {
    text-align: left !important;
  }
  .medium-text-right {
    text-align: right !important;
  }
  .medium-text-center {
    text-align: center !important;
  }
  .medium-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:64em) and (max-width:90em) {
  .large-only-text-left {
    text-align: left !important;
  }
  .large-only-text-right {
    text-align: right !important;
  }
  .large-only-text-center {
    text-align: center !important;
  }
  .large-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:64em) {
  .large-text-left {
    text-align: left !important;
  }
  .large-text-right {
    text-align: right !important;
  }
  .large-text-center {
    text-align: center !important;
  }
  .large-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:90.063em) and (max-width:120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }
  .xlarge-only-text-right {
    text-align: right !important;
  }
  .xlarge-only-text-center {
    text-align: center !important;
  }
  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:90.063em) {
  .xlarge-text-left {
    text-align: left !important;
  }
  .xlarge-text-right {
    text-align: right !important;
  }
  .xlarge-text-center {
    text-align: center !important;
  }
  .xlarge-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:120.063em) and (max-width:99999999em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }
  .xxlarge-only-text-right {
    text-align: right !important;
  }
  .xxlarge-only-text-center {
    text-align: center !important;
  }
  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:120.063em) {
  .xxlarge-text-left {
    text-align: left !important;
  }
  .xxlarge-text-right {
    text-align: right !important;
  }
  .xxlarge-text-center {
    text-align: center !important;
  }
  .xxlarge-text-justify {
    text-align: justify !important;
  }
}
/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* Default Link Styles */
a {
  color: #2F9ACD;
  text-decoration: none;
  line-height: inherit;
}
a:hover, a:focus {
  color: #2884b0;
}
a img {
  border: none;
}

/* Default paragraph styles */
p {
  font-family: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}
p.lead {
  font-size: 1.21875rem;
  line-height: 1.6;
}
p aside {
  font-size: 0.875rem;
  line-height: 1.35;
  font-style: italic;
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #212121;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.4;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-size: 60%;
  color: #6f6f6f;
  line-height: 0;
}

h1 {
  font-size: 0.75rem;
}

h2 {
  font-size: 0.5rem;
}

h3 {
  font-size: 0.6875rem;
}

h4 {
  font-size: 0.5625rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: 400;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

hr {
  border: solid #E0E0E0;
  border-width: 1px 0 0;
  clear: both;
  margin: 1rem 0 0.9375rem;
  height: 0;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 600;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  font-family: "Cousine", monospace;
  font-weight: 400;
  color: #333333;
  background-color: #d0d0d0;
  border-width: 1px;
  border-style: solid;
  border-color: #bbbbbb;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

/* Lists */
ul,
ol,
dl {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

ul {
  margin-left: 1.1rem;
}
ul.no-bullet {
  margin-left: 0;
}
ul.no-bullet li ul,
ul.no-bullet li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}
ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit;
}
ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}
ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}
ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}
ul.no-bullet {
  list-style: none;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}
ol li ul,
ol li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: 600;
}
dl dd {
  margin-bottom: 0.75rem;
}

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #212121;
  cursor: help;
}

abbr {
  text-transform: none;
}
abbr[title] {
  border-bottom: 1px dotted #DDDDDD;
}

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #DDDDDD;
}
blockquote cite {
  display: block;
  font-size: 0.8125rem;
  color: #545454;
}
blockquote cite:before {
  content: "— ";
}
blockquote cite a,
blockquote cite a:visited {
  color: #545454;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f;
}

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #DDDDDD;
  padding: 0.625rem 0.75rem;
}
.vcard li {
  margin: 0;
  display: block;
}
.vcard .fn {
  font-weight: 600;
  font-size: 0.9375rem;
}

.vevent .summary {
  font-weight: 600;
}
.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: 600;
  border: none;
  padding: 0 0.0625rem;
}

@media only screen and (min-width:40em) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
  }
  h1 {
    font-size: 1.375rem;
  }
  h2 {
    font-size: 1.125rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.875rem;
  }
  h5 {
    font-size: 1.125rem;
  }
  h6 {
    font-size: 1rem;
  }
}
.off-canvas-wrap {
  -webkit-backface-visibility: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.off-canvas-wrap.move-right, .off-canvas-wrap.move-left {
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

.inner-wrap {
  position: relative;
  width: 100%;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.inner-wrap:before, .inner-wrap:after {
  content: " ";
  display: table;
}
.inner-wrap:after {
  clear: both;
}

.tab-bar {
  -webkit-backface-visibility: hidden;
  background: #333333;
  color: #FFFFFF;
  height: 2.8125rem;
  line-height: 2.8125rem;
  position: relative;
}
.tab-bar h1, .tab-bar h2, .tab-bar h3, .tab-bar h4, .tab-bar h5, .tab-bar h6 {
  color: #FFFFFF;
  font-weight: 600;
  line-height: 2.8125rem;
  margin: 0;
}
.tab-bar h1, .tab-bar h2, .tab-bar h3, .tab-bar h4 {
  font-size: 1.125rem;
}

.left-small {
  width: 2.8125rem;
  height: 2.8125rem;
  position: absolute;
  top: 0;
  border-right: solid 1px #1a1a1a;
  left: 0;
}

.right-small {
  width: 2.8125rem;
  height: 2.8125rem;
  position: absolute;
  top: 0;
  border-left: solid 1px #1a1a1a;
  right: 0;
}

.tab-bar-section {
  padding: 0 0.625rem;
  position: absolute;
  text-align: center;
  height: 2.8125rem;
  top: 0;
}
@media only screen and (min-width:40em) {
  .tab-bar-section.left {
    text-align: left;
  }
  .tab-bar-section.right {
    text-align: right;
  }
}
.tab-bar-section.left {
  left: 0;
  right: 2.8125rem;
}
.tab-bar-section.right {
  left: 2.8125rem;
  right: 0;
}
.tab-bar-section.middle {
  left: 2.8125rem;
  right: 2.8125rem;
}

.tab-bar .menu-icon {
  text-indent: 2.1875rem;
  width: 2.8125rem;
  height: 2.8125rem;
  display: block;
  padding: 0;
  color: #FFFFFF;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.tab-bar .menu-icon span::after {
  content: "";
  position: absolute;
  display: block;
  height: 0;
  top: 50%;
  margin-top: -0.5rem;
  left: 0.90625rem;
  box-shadow: 0 0 0 1px #FFFFFF, 0 7px 0 1px #FFFFFF, 0 14px 0 1px #FFFFFF;
  width: 1rem;
}
.tab-bar .menu-icon span:hover:after {
  box-shadow: 0 0 0 1px #b3b3b3, 0 7px 0 1px #b3b3b3, 0 14px 0 1px #b3b3b3;
}

.left-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
  z-index: 1001;
  box-sizing: content-box;
  transition: transform 500ms ease 0s;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  left: 0;
}
.left-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

.right-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
  z-index: 1001;
  box-sizing: content-box;
  transition: transform 500ms ease 0s;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  right: 0;
}
.right-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

ul.off-canvas-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
ul.off-canvas-list li label, ul.off-canvas-list li ticket-add-edit-modal dt, ticket-add-edit-modal ul.off-canvas-list li dt, ul.off-canvas-list li add-edit-ticket-modal dt, add-edit-ticket-modal ul.off-canvas-list li dt {
  display: block;
  padding: 0.3rem 0.9375rem;
  color: #999999;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  background: #444444;
  border-top: 1px solid #5e5e5e;
  border-bottom: none;
  margin: 0;
}
ul.off-canvas-list li a {
  display: block;
  padding: 0.6666666667rem;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid #262626;
  transition: background 300ms ease;
}
ul.off-canvas-list li a:hover {
  background: #242424;
}

.move-right > .inner-wrap {
  -ms-transform: translate(15.625rem, 0);
  -webkit-transform: translate3d(15.625rem, 0, 0);
  -moz-transform: translate3d(15.625rem, 0, 0);
  -ms-transform: translate3d(15.625rem, 0, 0);
  -o-transform: translate3d(15.625rem, 0, 0);
  transform: translate3d(15.625rem, 0, 0);
}
.move-right .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  transition: background 300ms ease;
  cursor: pointer;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width:40em) {
  .move-right .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.move-left > .inner-wrap {
  -ms-transform: translate(-15.625rem, 0);
  -webkit-transform: translate3d(-15.625rem, 0, 0);
  -moz-transform: translate3d(-15.625rem, 0, 0);
  -ms-transform: translate3d(-15.625rem, 0, 0);
  -o-transform: translate3d(-15.625rem, 0, 0);
  transform: translate3d(-15.625rem, 0, 0);
}
.move-left .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  transition: background 300ms ease;
  cursor: pointer;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width:40em) {
  .move-left .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap .left-off-canvas-menu, .offcanvas-overlap .right-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  transition: background 300ms ease;
  cursor: pointer;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width:40em) {
  .offcanvas-overlap .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-left .right-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-left .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  transition: background 300ms ease;
  cursor: pointer;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width:40em) {
  .offcanvas-overlap-left .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-right .left-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-right .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  transition: background 300ms ease;
  cursor: pointer;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width:40em) {
  .offcanvas-overlap-right .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.no-csstransforms .left-off-canvas-menu {
  left: -15.625rem;
}
.no-csstransforms .right-off-canvas-menu {
  right: -15.625rem;
}
.no-csstransforms .move-left > .inner-wrap {
  right: 15.625rem;
}
.no-csstransforms .move-right > .inner-wrap {
  left: 15.625rem;
}

.left-submenu {
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
  z-index: 1002;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  left: 0;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.left-submenu * {
  -webkit-backface-visibility: hidden;
}
.left-submenu .back > a {
  padding: 0.3rem 0.9375rem;
  color: #999999;
  text-transform: uppercase;
  font-weight: 600;
  background: #444;
  border-top: 1px solid #5e5e5e;
  border-bottom: none;
  margin: 0;
}
.left-submenu .back > a:hover {
  background: #303030;
  border-top: 1px solid #5e5e5e;
  border-bottom: none;
}
.left-submenu .back > a:before {
  content: "«";
  margin-right: 0.5rem;
  display: inline;
}
.left-submenu.move-right, .left-submenu.offcanvas-overlap-right, .left-submenu.offcanvas-overlap {
  -ms-transform: translate(0%, 0);
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.right-submenu {
  -webkit-backface-visibility: hidden;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  position: absolute;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
  z-index: 1002;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  right: 0;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.right-submenu * {
  -webkit-backface-visibility: hidden;
}
.right-submenu .back > a {
  padding: 0.3rem 0.9375rem;
  color: #999999;
  text-transform: uppercase;
  font-weight: 600;
  background: #444;
  border-top: 1px solid #5e5e5e;
  border-bottom: none;
  margin: 0;
}
.right-submenu .back > a:hover {
  background: #303030;
  border-top: 1px solid #5e5e5e;
  border-bottom: none;
}
.right-submenu .back > a:after {
  content: "»";
  margin-left: 0.5rem;
  display: inline;
}
.right-submenu.move-left, .right-submenu.offcanvas-overlap-left, .right-submenu.offcanvas-overlap {
  -ms-transform: translate(0%, 0);
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.left-off-canvas-menu ul.off-canvas-list li.has-submenu > a:after {
  content: "»";
  margin-left: 0.5rem;
  display: inline;
}

.right-off-canvas-menu ul.off-canvas-list li.has-submenu > a:before {
  content: "«";
  margin-right: 0.5rem;
  display: inline;
}

/* small displays */
@media only screen {
  .show-for-small-only, .show-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .hide-for-small-only, .hide-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .visible-for-small-only, .visible-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .hidden-for-small-only, .hidden-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  table.show-for-small-only, table.show-for-small-up, table.show-for-small, table.show-for-small-down, table.hide-for-medium-only, table.hide-for-medium-up, table.hide-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.show-for-small-only, thead.show-for-small-up, thead.show-for-small, thead.show-for-small-down, thead.hide-for-medium-only, thead.hide-for-medium-up, thead.hide-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.show-for-small-only, tbody.show-for-small-up, tbody.show-for-small, tbody.show-for-small-down, tbody.hide-for-medium-only, tbody.hide-for-medium-up, tbody.hide-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.show-for-small-only, tr.show-for-small-up, tr.show-for-small, tr.show-for-small-down, tr.hide-for-medium-only, tr.hide-for-medium-up, tr.hide-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.show-for-small-only, td.show-for-small-only, th.show-for-small-up, td.show-for-small-up, th.show-for-small, td.show-for-small, th.show-for-small-down, td.show-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.hide-for-medium-up, td.hide-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}
/* medium displays */
@media only screen and (min-width:40em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.show-for-medium-only, table.show-for-medium-up, table.show-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.show-for-medium-only, thead.show-for-medium-up, thead.show-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.show-for-medium-only, tbody.show-for-medium-up, tbody.show-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.show-for-medium-only, tr.show-for-medium-up, tr.show-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.show-for-medium-only, td.show-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.show-for-medium, td.show-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}
/* large displays */
@media only screen and (min-width:64em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.show-for-large-only, table.show-for-large-up, table.show-for-large, table.show-for-large-down, table.hide-for-xlarge-only, table.hide-for-xlarge-up, table.hide-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.show-for-large-only, thead.show-for-large-up, thead.show-for-large, thead.show-for-large-down, thead.hide-for-xlarge-only, thead.hide-for-xlarge-up, thead.hide-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.show-for-large-only, tbody.show-for-large-up, tbody.show-for-large, tbody.show-for-large-down, tbody.hide-for-xlarge-only, tbody.hide-for-xlarge-up, tbody.hide-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.show-for-large-only, tr.show-for-large-up, tr.show-for-large, tr.show-for-large-down, tr.hide-for-xlarge-only, tr.hide-for-xlarge-up, tr.hide-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.show-for-large-only, td.show-for-large-only, th.show-for-large-up, td.show-for-large-up, th.show-for-large, td.show-for-large, th.show-for-large-down, td.show-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.hide-for-xlarge-up, td.hide-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}
/* xlarge displays */
@media only screen and (min-width:90.063em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .show-for-xlarge-only, .show-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .hide-for-xlarge-only, .hide-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .visible-for-xlarge-only, .visible-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .hidden-for-xlarge-only, .hidden-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .hidden-for-xxlarge-down {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.show-for-xlarge-only, table.show-for-xlarge-up, table.show-for-xlarge, table.show-for-xlarge-down, table.hide-for-xxlarge-only, table.hide-for-xxlarge-up, table.hide-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.show-for-xlarge-only, thead.show-for-xlarge-up, thead.show-for-xlarge, thead.show-for-xlarge-down, thead.hide-for-xxlarge-only, thead.hide-for-xxlarge-up, thead.hide-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.show-for-xlarge-only, tbody.show-for-xlarge-up, tbody.show-for-xlarge, tbody.show-for-xlarge-down, tbody.hide-for-xxlarge-only, tbody.hide-for-xxlarge-up, tbody.hide-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.show-for-xlarge-only, tr.show-for-xlarge-up, tr.show-for-xlarge, tr.show-for-xlarge-down, tr.hide-for-xxlarge-only, tr.hide-for-xxlarge-up, tr.hide-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.show-for-xlarge-only, td.show-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.show-for-xlarge, td.show-for-xlarge, th.show-for-xlarge-down, td.show-for-xlarge-down, th.hide-for-xxlarge-only, td.hide-for-xxlarge-only, th.hide-for-xxlarge-up, td.hide-for-xxlarge-up, th.hide-for-xxlarge, td.hide-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}
/* xxlarge displays */
@media only screen and (min-width:120.063em) {
  .hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down, .hide-for-xlarge-only, .show-for-xlarge-up, .hide-for-xlarge, .hide-for-xlarge-down, .show-for-xxlarge-only, .show-for-xxlarge-up, .show-for-xxlarge, .show-for-xxlarge-down {
    display: inherit !important;
  }
  .show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down, .show-for-xlarge-only, .hide-for-xlarge-up, .show-for-xlarge, .show-for-xlarge-down, .hide-for-xxlarge-only, .hide-for-xxlarge-up, .hide-for-xxlarge, .hide-for-xxlarge-down {
    display: none !important;
  }
  .hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down, .hidden-for-xlarge-only, .visible-for-xlarge-up, .hidden-for-xlarge, .hidden-for-xlarge-down, .visible-for-xxlarge-only, .visible-for-xxlarge-up, .visible-for-xxlarge, .visible-for-xxlarge-down {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  .visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down, .visible-for-xlarge-only, .hidden-for-xlarge-up, .visible-for-xlarge, .visible-for-xlarge-down, .hidden-for-xxlarge-only, .hidden-for-xxlarge-up, .hidden-for-xxlarge, .hidden-for-xxlarge-down {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down, table.hide-for-xlarge-only, table.show-for-xlarge-up, table.hide-for-xlarge, table.hide-for-xlarge-down, table.show-for-xxlarge-only, table.show-for-xxlarge-up, table.show-for-xxlarge, table.show-for-xxlarge-down {
    display: table !important;
  }
  thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down, thead.hide-for-xlarge-only, thead.show-for-xlarge-up, thead.hide-for-xlarge, thead.hide-for-xlarge-down, thead.show-for-xxlarge-only, thead.show-for-xxlarge-up, thead.show-for-xxlarge, thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }
  tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down, tbody.hide-for-xlarge-only, tbody.show-for-xlarge-up, tbody.hide-for-xlarge, tbody.hide-for-xlarge-down, tbody.show-for-xxlarge-only, tbody.show-for-xxlarge-up, tbody.show-for-xxlarge, tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }
  tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down, tr.hide-for-xlarge-only, tr.show-for-xlarge-up, tr.hide-for-xlarge, tr.hide-for-xlarge-down, tr.show-for-xxlarge-only, tr.show-for-xxlarge-up, tr.show-for-xxlarge, tr.show-for-xxlarge-down {
    display: table-row;
  }
  th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down, th.hide-for-xlarge-only, td.hide-for-xlarge-only, th.show-for-xlarge-up, td.show-for-xlarge-up, th.hide-for-xlarge, td.hide-for-xlarge, th.hide-for-xlarge-down, td.hide-for-xlarge-down, th.show-for-xxlarge-only, td.show-for-xxlarge-only, th.show-for-xxlarge-up, td.show-for-xxlarge-up, th.show-for-xxlarge, td.show-for-xxlarge, th.show-for-xxlarge-down, td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}
/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
  display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-landscape, table.show-for-portrait {
  display: table !important;
}

thead.hide-for-landscape, thead.show-for-portrait {
  display: table-header-group !important;
}

tbody.hide-for-landscape, tbody.show-for-portrait {
  display: table-row-group !important;
}

tr.hide-for-landscape, tr.show-for-portrait {
  display: table-row !important;
}

td.hide-for-landscape, td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important;
}

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important;
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
  /* Specific visibility for tables */
  table.show-for-landscape, table.hide-for-portrait {
    display: table !important;
  }
  thead.show-for-landscape, thead.hide-for-portrait {
    display: table-header-group !important;
  }
  tbody.show-for-landscape, tbody.hide-for-portrait {
    display: table-row-group !important;
  }
  tr.show-for-landscape, tr.hide-for-portrait {
    display: table-row !important;
  }
  td.show-for-landscape, td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important;
  }
}
@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important;
  }
  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }
  /* Specific visibility for tables */
  table.show-for-portrait, table.hide-for-landscape {
    display: table !important;
  }
  thead.show-for-portrait, thead.hide-for-landscape {
    display: table-header-group !important;
  }
  tbody.show-for-portrait, tbody.hide-for-landscape {
    display: table-row-group !important;
  }
  tr.show-for-portrait, tr.hide-for-landscape {
    display: table-row !important;
  }
  td.show-for-portrait, td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important;
  }
}
/* Touch-enabled device targeting */
.show-for-touch {
  display: none !important;
}

.hide-for-touch {
  display: inherit !important;
}

.touch .show-for-touch {
  display: inherit !important;
}

.touch .hide-for-touch {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-touch {
  display: table !important;
}

.touch table.show-for-touch {
  display: table !important;
}

thead.hide-for-touch {
  display: table-header-group !important;
}

.touch thead.show-for-touch {
  display: table-header-group !important;
}

tbody.hide-for-touch {
  display: table-row-group !important;
}

.touch tbody.show-for-touch {
  display: table-row-group !important;
}

tr.hide-for-touch {
  display: table-row !important;
}

.touch tr.show-for-touch {
  display: table-row !important;
}

td.hide-for-touch {
  display: table-cell !important;
}

.touch td.show-for-touch {
  display: table-cell !important;
}

th.hide-for-touch {
  display: table-cell !important;
}

.touch th.show-for-touch {
  display: table-cell !important;
}

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    color: #000000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block;
  }
  .hide-for-print {
    display: none;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .hide-on-print {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  .show-for-print {
    display: inherit !important;
  }
}
/* Print visibility */
@media print {
  .show-for-print {
    display: block;
  }
  .hide-for-print {
    display: none;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
}
html, body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
@media print {
  html, body {
    overflow: visible;
    position: relative;
    width: 1200px;
  }
}
@media print {
  html nav, html .no-print, body nav, body .no-print {
    display: none;
  }
}

main {
  position: absolute;
  top: calc(3.5rem + var(--nav-top-bar-height, 5.75rem));
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 1rem 0.5rem;
  overflow-y: auto;
  font-size: 0.875rem;
}
main.info-banner-visible {
  top: calc(3.5rem + var(--nav-top-bar-height, 5.75rem) + 5.3125rem);
}
@media print {
  main {
    position: relative;
    overflow-y: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  }
}
main.offset {
  left: calc(var(--nav-left-bar-width, 15.625rem) + 1.5rem - 1rem);
}
@media print {
  main.offset {
    left: 0;
  }
}
main.new-tab-offset {
  top: -16px;
  padding: 0;
}

.main-container {
  padding: 0;
  margin: 0 8px;
}

.sheet {
  background-color: #FFFFFF;
}
.sheet.overflow-auto {
  overflow: auto;
}

.padded {
  padding: 0.5rem;
}

.double-padded {
  padding: 1rem;
}

.margin-bottom {
  margin-bottom: 1.5625rem;
}

.margin-right {
  margin-right: 1.5625rem;
}

.no-margin {
  margin: 0 !important;
}

.spaced {
  margin: 0.5rem;
}

.full-width {
  width: 100%;
}

.position-relative {
  position: relative;
}

.full-sized, alert-story-mode-viewer .aggregate-selector-container {
  width: 100%;
  height: 100%;
}

.auto-min-inline-size {
  min-inline-size: auto;
}

.action-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  padding: 0 8px;
}
.action-header.action-group {
  justify-content: space-between;
}
.action-header h1.sub-header {
  display: inline-block;
  line-height: 2.5rem;
  font-weight: 500;
  font-size: 0.825rem;
  text-transform: none;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  color: #212121;
  border-bottom: none;
}

.content-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem 0 0.5rem;
  border-bottom: 1px solid #E0E0E0;
}
.content-header pr-dropdown {
  margin: 0 0.3125rem 0 0.3125rem;
}
.content-header ds-dropdown .ds-dropdown-trigger {
  margin-top: 0;
}
.content-header ds-dropdown .ds-dropdown-trigger:hover {
  color: #2F9ACD;
}
.content-header ds-dropdown .trigger-container {
  margin-top: 4px;
  margin-bottom: 0;
  border-bottom: 0;
}
.content-header h2 {
  width: 100%;
}
.content-header .search-filter {
  margin-left: auto;
  height: 34px;
}
.content-header .search-filter input[type=text] {
  display: inline-block;
  width: 280px;
}
.content-header .search-filter input[type=text]:focus {
  background-color: transparent;
}
.content-header .search-filter input[type=text]::-webkit-input-placeholder {
  color: #757575;
}
.content-header .search-filter input[type=text]:-moz-placeholder {
  color: #757575;
}
.content-header .search-filter input[type=text]::-moz-placeholder {
  color: #757575;
}
.content-header .search-filter input[type=text]:-ms-input-placeholder {
  color: #757575;
}
.content-header .search-filter button {
  padding: 0;
  margin-left: -16px;
  background-color: transparent;
  color: #2F9ACD;
}
.content-header .search-filter button i {
  vertical-align: middle;
}
.content-header .filter-label {
  max-width: 30em;
  display: inline-block;
  vertical-align: middle;
}

ds-dropdown #ds-dropdown-trigger {
  flex-grow: 1;
  font-weight: 500;
}
ds-dropdown .dropdown-label {
  padding-right: 6px;
}
ds-dropdown .dropdown-value {
  padding-right: 3px;
}

ul.ds-dropdown-body {
  margin-left: 0;
  margin-bottom: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  border-top: 4px solid #2F9ACD;
  box-shadow: 0 4px 8px 0 rgba(33, 33, 33, 0.5);
  background-color: #FFFFFF;
  color: #212121;
  font-size: 14px;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
  /*div.active .trigger-container {
    border-bottom: 1px solid $main-accent-color;
  }*/
}
ul.ds-dropdown-body .search-filter {
  margin-left: auto;
  height: 34px;
}
ul.ds-dropdown-body .search-filter input[type=text] {
  display: inline-block;
  width: 280px;
}
ul.ds-dropdown-body .search-filter input[type=text]:focus {
  background-color: transparent;
}
ul.ds-dropdown-body .search-filter input[type=text]::-webkit-input-placeholder {
  color: #757575;
}
ul.ds-dropdown-body .search-filter input[type=text]:-moz-placeholder {
  color: #757575;
}
ul.ds-dropdown-body .search-filter input[type=text]::-moz-placeholder {
  color: #757575;
}
ul.ds-dropdown-body .search-filter input[type=text]:-ms-input-placeholder {
  color: #757575;
}
ul.ds-dropdown-body .search-filter button {
  padding: 0;
  margin-left: -16px;
  background-color: transparent;
  color: #2F9ACD;
}
ul.ds-dropdown-body .search-filter button i {
  vertical-align: middle;
}
ul.ds-dropdown-body .search-filter input[type=text] {
  width: 100%;
}
ul.ds-dropdown-body #ds-dropdown-footer-row {
  font-weight: normal;
  flex-grow: 1;
}
ul.ds-dropdown-body li {
  padding: 0 16px;
}
ul.ds-dropdown-body li:hover {
  background-color: #DFF0F8;
}

tag-search-multiselect-dropdown ds-dropdown pr-dropdown .options-container {
  padding-bottom: 12px;
}
tag-search-multiselect-dropdown ds-dropdown pr-dropdown *[pr-dropdown-body] {
  z-index: 920;
}

global-tag-search-bar .search-input {
  width: 100%;
}
global-tag-search-bar .search-input .underlined-text-box:focus {
  background: #FFFFFF;
}
global-tag-search-bar .search-input input[type=text] {
  overflow: hidden;
  height: 40px;
  border: none;
  padding: 0px 16px 0px 8px;
}
global-tag-search-bar .search-input input[type=text]:hover:not(:disabled) {
  background: none;
}

.hide-header-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50em;
  white-space: nowrap;
}

.center {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.right {
  float: right;
}

.no-text-transform {
  text-transform: none !important;
}

.flex-right {
  justify-content: flex-end;
}

.flex-container {
  display: flex;
}

.flex-child-same-width {
  flex: 1 1 0px;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.flex-container-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0 0.5rem;
}

.fixed-page-container {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0 1rem;
  overflow-y: hidden;
}
.fixed-page-container .fixed-right-content {
  position: absolute;
  right: 0.625rem;
  width: 19.375rem;
}
.fixed-page-container .fixed-scrolling-content {
  position: absolute;
  right: 20.75rem;
  left: 0.3125rem;
  height: 100%;
  overflow-y: scroll;
}
.fixed-page-container .fixed-scrolling-content .form-footer-space {
  margin-bottom: 6.25rem;
}
.fixed-page-container .fixed-scrolling-content .fixed-footer-notification-space {
  margin-bottom: 11.25rem;
}
.fixed-page-container .fixed-save-footer {
  position: absolute;
  height: 4.6875rem;
  right: 20.75rem;
  left: 0.3125rem;
  bottom: 0;
  padding: 0 0.9375rem;
  background-color: #FFFFFF;
}
.fixed-page-container .fixed-full-width {
  right: 1rem;
}
.fixed-page-container.below-top-nav-and-header {
  top: 3.75rem;
}

.fixed-container-top-buffer {
  top: 3.75rem;
}

.fixed-container-top-buffer-small {
  top: 0.9375rem;
}

.section-container {
  padding: 0 1.5625rem;
}

trigger-description-display .section-container:nth-of-type(n+2) .row {
  border-top: 1px solid #BDBDBD;
  margin-top: 0.3125rem;
  padding-top: 0.3125rem;
}

.interval-element {
  padding: 0 0.625rem;
}

:focus {
  outline: none;
}

*::selection {
  color: #FFFFFF;
  background-color: #616161;
}

.hidden {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade_in {
  animation: fadeIn 1s linear;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade_out {
  animation: fadeOut 1s linear;
}

[ng-click], [ui-sref], .click-target {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

dl dd {
  font-size: smaller;
}

.nowrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.row {
  max-width: none !important;
}

h1 {
  display: inline-block;
  padding: 0;
  line-height: 40px;
  font-weight: 600;
}

h2 {
  border-bottom: 1px solid #E0E0E0;
  padding: 0 0.5rem;
  font-weight: 600;
  line-height: 2.5rem;
}
h2 small {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0 0.5rem;
}
h2 a.pr-button, h2 a.color-pr-button, h2 a.range-pr-button {
  margin-top: 0.5rem;
}
h2 .settings button {
  color: #212121;
}
h2 ul.button-group {
  margin-left: 1.375rem;
}
h2 ul.button-group li:first-child button {
  border-left: none;
}
h2 ul.button-group button, h2 ul.button-group .button {
  margin-left: 0.3125rem;
}
h2 ul.button-group button:focus, h2 ul.button-group .button:focus {
  outline: none;
}

.sub-header {
  margin: 16px 14px;
  border-bottom: 1px solid #E0E0E0;
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #616161;
  text-transform: uppercase;
}

.term-label {
  font-weight: 500;
  color: #616161;
}

.de-emphasize {
  color: #616161;
  font-weight: 400;
  font-size: 90%;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.standard-font-weight {
  font-weight: 400;
}

table:not(.mat-calendar-table) {
  border-collapse: collapse;
  margin: 0;
}
table:not(.mat-calendar-table) thead tr th, table:not(.mat-calendar-table) tbody tr td, table:not(.mat-calendar-table) tfoot tr td {
  text-align: left;
  white-space: normal;
}
table:not(.mat-calendar-table) thead tr th, table:not(.mat-calendar-table) thead tr td, table:not(.mat-calendar-table) tfoot tr th, table:not(.mat-calendar-table) tfoot tr td {
  padding: 0.25rem 0.625rem;
  background-color: #E0E0E0;
  color: #424242;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}
table:not(.mat-calendar-table) tbody tr {
  background-color: #FFFFFF;
  color: #212121;
}

table[dsTable] th[dsResizableColumn] {
  position: relative;
}
table[dsTable] th[dsResizableColumn] div.resize-column-marker {
  position: absolute;
  cursor: ew-resize;
  height: 100%;
  width: 10px;
  right: 0;
  top: 0;
  z-index: 100;
  background: linear-gradient(#BDBDBD, #BDBDBD) no-repeat center/2px 100%;
}

.no-left-padding {
  padding-left: 0;
}

.no-right-padding {
  padding-right: 0;
}

.fraud-inbox-trigger-view-bell-column {
  width: 1rem;
}

.hover-table, .grouped-table {
  border: 0;
}
.hover-table thead th[ng-click]:hover:not(.default-cursor), .grouped-table thead th[ng-click]:hover:not(.default-cursor), .hover-table thead th.click-target:hover:not(.default-cursor), .grouped-table thead th.click-target:hover:not(.default-cursor) {
  color: #2F9ACD;
}
.hover-table tbody td, .grouped-table tbody td {
  border-bottom: 1px solid #E0E0E0;
  border-top: 1px solid #E0E0E0;
  vertical-align: middle;
}
.hover-table tbody td.button-cell, .grouped-table tbody td.button-cell {
  padding: 0;
}
.hover-table tbody td.link-cell:hover, .grouped-table tbody td.link-cell:hover, .hover-table tbody td.link-cell a:hover, .grouped-table tbody td.link-cell a:hover {
  color: #2F9ACD;
}
.hover-table tbody td.link-cell, .grouped-table tbody td.link-cell {
  font-weight: 500;
  padding: 0;
}
.hover-table tbody td.link-cell > :first-of-type, .grouped-table tbody td.link-cell > :first-of-type {
  padding: 0.5625rem 0.625rem;
  display: block;
}
.hover-table tbody td.link-cell > :first-of-type a, .grouped-table tbody td.link-cell > :first-of-type a {
  display: block;
}
.hover-table .hover-tab, .grouped-table .hover-tab {
  padding: 0;
  width: 4px;
  min-width: 4px;
}
.hover-table .hover-tab .hover-tab-hack, .grouped-table .hover-tab .hover-tab-hack {
  width: 0.375rem;
}
.hover-table .no-data-text, .grouped-table .no-data-text {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.hover-table .no-data-text:hover, .grouped-table .no-data-text:hover {
  background-color: #FFFFFF;
}
.hover-table .small-cell, .grouped-table .small-cell {
  width: 10em;
}
.hover-table.full-width, .full-width.grouped-table {
  width: 100%;
}
.hover-table tr:hover, .grouped-table tr:hover, .hover-table .selected-row, .grouped-table .selected-row {
  background-color: #FAFAFA;
}
.hover-table tr:hover > td.hover-tab, .grouped-table tr:hover > td.hover-tab, .hover-table .selected-row > td.hover-tab, .grouped-table .selected-row > td.hover-tab {
  background-color: #2F9ACD;
}
.hover-table tr:hover > td.hover-tab, .grouped-table tr:hover > td.hover-tab, .hover-table .selected-row > td.hover-tab, .grouped-table .selected-row > td.hover-tab {
  position: relative;
}
.hover-table tr:hover > td.hover-tab:before, .grouped-table tr:hover > td.hover-tab:before, .hover-table .selected-row > td.hover-tab:before, .grouped-table .selected-row > td.hover-tab:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 0.375rem;
  top: -1px;
}
.hover-table tr:hover > td.hover-tab:after, .grouped-table tr:hover > td.hover-tab:after, .hover-table .selected-row > td.hover-tab:after, .grouped-table .selected-row > td.hover-tab:after {
  content: "";
  position: absolute;
  left: 0px;
  width: 0.375rem;
  bottom: -1px;
}
.hover-table tr.no-hover:hover, .hover-table #cat-select-line-items-table tr:hover, #cat-select-line-items-table .hover-table tr:hover, .grouped-table tr.no-hover:hover, .grouped-table #cat-select-line-items-table tr:hover, #cat-select-line-items-table .grouped-table tr:hover {
  background-color: #FFFFFF;
}
.hover-table .timestamp-cell, .grouped-table .timestamp-cell {
  width: 8.75rem;
}
.hover-table .timestamp-cell .interval-container, .grouped-table .timestamp-cell .interval-container {
  display: inline-block;
}
.hover-table .icon-cell, .grouped-table .icon-cell {
  width: 2.5rem;
}
.hover-table .icon-cell.expander, .grouped-table .icon-cell.expander {
  font-size: 1.25rem;
}
.hover-table .right-dropdown, .grouped-table .right-dropdown {
  width: 1rem;
}
.hover-table tbody tr.no-bottom-border td, .grouped-table tbody tr.no-bottom-border td {
  border-bottom: 1px solid #FFFFFF;
}
.hover-table tbody tr.no-top-border td, .grouped-table tbody tr.no-top-border td {
  border-top: 1px solid #FFFFFF;
}

.fixed-size-table {
  border-spacing: 0;
  width: 47.875rem;
}
.fixed-size-table tbody, .fixed-size-table tbody tr, .fixed-size-table thead {
  display: block;
}
.fixed-size-table tbody {
  max-height: 10.9375rem;
  overflow-y: auto;
}
.fixed-size-table tbody tr td {
  white-space: normal;
}

.long-cell {
  width: 25rem;
}

.medium-cell {
  width: 15.625rem;
}

.short-cell {
  width: 6.875rem;
}

.return-to-top-footer {
  background-color: #E0E0E0;
}
.return-to-top-footer td {
  text-align: center;
  padding: 0;
  display: block;
}
.return-to-top-footer td:hover {
  background-color: #E0E0E0;
}
.return-to-top-footer .footer-text {
  padding: 0.1875rem;
  font-size: 0.75rem;
  font-weight: 500;
}
.return-to-top-footer .footer-text:hover {
  color: #2F9ACD;
}

.tabs dd > a, .tabs .tab-title > a {
  padding: 0.25rem 1rem;
}
.tabs .active {
  border-bottom: 1px solid #E0E0E0;
  border-bottom-width: 3px;
  cursor: default;
}

dl.tabs {
  border-bottom: 1px solid #E0E0E0;
}
dl.tabs dd {
  padding: 1rem 1rem 0;
  cursor: pointer;
}
dl.tabs dd:first-child {
  margin-left: 1rem;
}

.tabs-content {
  padding-top: 1rem;
}

fieldset {
  padding: 0.7em;
  margin: 0.7em 0;
}
fieldset label, fieldset ticket-add-edit-modal dt, ticket-add-edit-modal fieldset dt, fieldset add-edit-ticket-modal dt, add-edit-ticket-modal fieldset dt {
  display: inline;
}

input[type=text], input[type=email], input[type=number], input[type=password] {
  margin: 0;
  padding: 0;
  height: 28px;
  border: none;
  border-bottom: 1px solid #616161;
  color: #212121;
  box-shadow: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

input[type=text]::-webkit-input-placeholder, input[type=email]::-webkit-input-placeholder, input[type=number]::-webkit-input-placeholder, input[type=password]::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #757575;
  font-weight: 400;
}
input[type=text]:-moz-placeholder, input[type=email]:-moz-placeholder, input[type=number]:-moz-placeholder, input[type=password]:-moz-placeholder, textarea:-moz-placeholder {
  color: #757575;
  font-weight: 400;
}
input[type=text]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=number]::-moz-placeholder, input[type=password]::-moz-placeholder, textarea::-moz-placeholder {
  color: #757575;
  font-weight: 400;
}
input[type=text]:-ms-input-placeholder, input[type=email]:-ms-input-placeholder, input[type=number]:-ms-input-placeholder, input[type=password]:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #757575;
  font-weight: 400;
}
input[type=text]:hover:not(:disabled), input[type=email]:hover:not(:disabled), input[type=number]:hover:not(:disabled), input[type=password]:hover:not(:disabled), textarea:hover:not(:disabled) {
  background: #F7FBFD;
}
input[type=text]:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, textarea:focus {
  background: #F7FBFD;
  border-color: #2F9ACD;
}
input[type=text][disabled], input[type=text][readonly], input[type=email][disabled], input[type=email][readonly], input[type=number][disabled], input[type=number][readonly], input[type=password][disabled], input[type=password][readonly], textarea[disabled], textarea[readonly] {
  background: #F5F5F5;
}

fieldset[disabled] input[type=text], fieldset[disabled] input[type=email], fieldset[disabled] input[type=number], fieldset[disabled] input[type=password], fieldset[disabled] textarea {
  background: #F5F5F5;
}

input[type=checkbox],
input[type=file],
input[type=radio] {
  margin: 8px;
  display: inline;
}

textarea {
  margin: 4px 0 0 0;
  font-weight: 500;
  box-shadow: none;
  border-radius: 2px;
}

.toastui-editor-mode-switch {
  display: none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}

.ng-invalid.ng-touched:not(:focus):not(form):not(.dont-add-error-border) {
  border-bottom: 1px solid #DF5146;
}
.ng-invalid.ng-touched:not(:focus):not(form):not(.dont-add-error-border):hover {
  background: #FEF8F8;
}

.form-error-text {
  color: #DF5146;
  text-align: end;
}

.form-error-text-start-align {
  color: #DF5146;
  text-align: start;
}

.disabled-text {
  color: #757575;
}

.nice-form label:not(.for-checkbox):not(.date-range-error), .nice-form ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error), ticket-add-edit-modal .nice-form dt:not(.for-checkbox):not(.date-range-error), .nice-form add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error), add-edit-ticket-modal .nice-form dt:not(.for-checkbox):not(.date-range-error) {
  margin: 1.25rem 0 0 0;
  padding: 0;
  border: none;
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  color: #616161;
}
.nice-form label:not(.for-checkbox):not(.date-range-error) div .title-header, .nice-form ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error) div .title-header, ticket-add-edit-modal .nice-form dt:not(.for-checkbox):not(.date-range-error) div .title-header, .nice-form add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error) div .title-header, add-edit-ticket-modal .nice-form dt:not(.for-checkbox):not(.date-range-error) div .title-header {
  margin: 0;
}
.nice-form label:not(.for-checkbox):not(.date-range-error) .input-caption, .nice-form ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error) .input-caption, ticket-add-edit-modal .nice-form dt:not(.for-checkbox):not(.date-range-error) .input-caption, .nice-form add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error) .input-caption, add-edit-ticket-modal .nice-form dt:not(.for-checkbox):not(.date-range-error) .input-caption {
  margin-top: 4px;
  line-height: 16px;
  font-size: 12px;
}
.nice-form select:not(.inline-block) {
  display: block;
}
.nice-form .error-message {
  color: #DF5146;
  font-size: 0.75rem;
}
.nice-form .top-padded {
  padding-top: 1.25rem;
}
.nice-form .no-margin-top {
  margin-top: 0;
}
.nice-form .half-width {
  width: 18.75rem;
}
.nice-form .removable-item-list {
  margin: 0;
}
.nice-form .removable-item-list .removable-item {
  padding: 0.3125rem;
  border: 0.125rem solid #FFFFFF;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nice-form .removable-item-list .removable-item:hover {
  background-color: #FAFAFA;
}
.nice-form .removable-item-list .removable-item .delete-button {
  padding: 0.125rem 0.25rem 0 0.25rem;
}
.nice-form .removable-item-list .removable-item .removable-item-large {
  font-size: larger;
}
.nice-form input[type=checkbox] {
  margin: 0 0.0625rem 0 0;
}
.nice-form select {
  width: 18.75rem;
  height: 1.75rem;
  border: none;
  border-bottom: 1px solid #616161;
  margin: 0;
  padding: 0.125rem 1rem 0 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: #212121;
  background-color: #FFFFFF;
  background-position: right -0.3125rem center;
}
.nice-form select.value-inactive {
  font-weight: 400;
  color: #757575;
}
.nice-form ds-select {
  width: 18.75rem;
}
.nice-form ds-select.inline-block {
  display: inline-block;
}
.nice-form ds-select.inline-block ds-single-select, .nice-form ds-select.inline-block ds-multi-select {
  width: 100%;
}
.nice-form select:disabled {
  border: 1px solid #E0E0E0;
  background-color: #BDBDBD;
  cursor: default;
}
.nice-form policy-selector select:nth-of-type(1) {
  margin-bottom: 0.5rem;
}
.nice-form .tiny-input {
  width: 2rem;
  display: inline-block;
}
.nice-form .short-input, .nice-form interval-selector input[type=number] {
  width: 96px;
  display: inline-block;
}
.nice-form .medium-input {
  width: 10rem;
  display: inline-block;
}
.nice-form interval-selector ds-select .ds-dropdown-trigger {
  margin-top: 0;
}
.nice-form.panel input[type=text], .nice-form.panel input[type=email], .nice-form.panel input[type=number] {
  padding-left: 0.5rem;
  border: 1px solid #616161;
}
.nice-form .nice-form-display-text {
  font-size: 0.875rem;
  font-weight: 500;
}
.nice-form .roles-bottom-padded {
  padding-bottom: 5rem;
}
.nice-form .no-margin-radio-button input[type=radio] {
  margin: 0;
}

a {
  color: #212121;
}

.link-text, alert-inbox .scrolling-panel-text, ng1-a-link.link-text a, alert-inbox ng1-a-link.scrolling-panel-text a {
  color: #2F9ACD;
  font-weight: 500;
}
.link-text:hover:not(.disabled-link), alert-inbox .scrolling-panel-text:hover:not(.disabled-link), ng1-a-link.link-text a:hover:not(.disabled-link), alert-inbox ng1-a-link.scrolling-panel-text a:hover:not(.disabled-link) {
  color: #24769E;
  text-decoration: none;
}
.link-text.disabled-link, alert-inbox .disabled-link.scrolling-panel-text, ng1-a-link.link-text a.disabled-link, alert-inbox ng1-a-link.scrolling-panel-text a.disabled-link {
  color: #757575;
  pointer-events: none;
}

.link-on-hover-text {
  font-weight: 500;
}
.link-on-hover-text:hover {
  color: #2F9ACD;
}

i {
  transition: color 300ms ease-out 0s;
}
i.embiggen {
  font-size: 1.2rem;
}
i.primary, i.dynamic-thresholds {
  color: #2F9ACD;
}
i.primary:hover, i.dynamic-thresholds:hover {
  color: #257ba4;
}
i.secondary {
  color: #616161;
}
i.secondary:hover {
  color: #484848;
}
i.success {
  color: #8BB741;
}
i.success:hover {
  color: #6e9134;
}
i.upper-threshold {
  color: #8BB741;
}
i.warning {
  color: #FEA82F;
}
i.warning:hover {
  color: #f99201;
}
i.lower-threshold {
  color: #FEA82F;
}
i.every-event {
  color: #2F9ACD;
}
i.sequence-trigger {
  color: #2F9ACD;
}
i.uba-trigger {
  color: #2F9ACD;
}
i.info {
  color: #757575;
}
i.info:hover {
  color: #5c5c5c;
}
i.alert {
  color: #DF5146;
}
i.alert:hover {
  color: #ce3024;
}
i.inactive {
  color: #757575;
}
i.large {
  font-size: 2.8125rem;
}

.filedrop {
  width: 100%;
  height: 4.0625rem;
  border: dashed 0.0625rem #616161;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #616161;
}

input[type=file].hidden-file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 4.0625rem;
  left: 0;
  margin: 0;
}
input[type=file].hidden-file-input:hover + .filedrop {
  background-color: #FAFAFA;
}

.alert-box {
  background-color: #FFFFFF;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.04), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 3px 6px -4px rgba(0, 0, 0, 0.08);
  color: #212121;
  border: none;
  border-left: 6px solid #2F9ACD;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 9px;
}
.alert-box .main-icon {
  margin-right: 5px;
}
.alert-box.success {
  background: #FFFFFF;
  color: #212121;
}
.alert-box.alert {
  background: #FFFFFF;
  color: #212121;
}
.alert-box.warning {
  background: #FFFFFF;
  color: #212121;
}
.alert-box.info {
  background: #FFFFFF;
  color: #212121;
}
.alert-box.small {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
}
.alert-box.small .close {
  font-size: 1.5rem;
}
.alert-box.large {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 2rem;
}
.alert-box.large .close {
  font-size: 3rem;
}
.alert-box .close {
  font-size: 2rem;
  line-height: 1.25rem;
}
.alert-box .flex-contents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.alert-box > .flex-grow {
  flex-grow: 1;
}
.alert-box.fixed {
  position: fixed;
  top: 6.25rem;
  left: 25%;
  width: 50%;
}
.alert-box .indent {
  margin-left: 0.9375rem;
}

button, .button {
  padding-bottom: 0.4rem;
  font-weight: 500;
}

button.inactive, .button.inactive {
  background: #757575;
}

button.icon-only, .button.icon-only {
  width: 2.75rem;
  height: 2.25rem;
  font-size: 1.75rem;
  line-height: 100%;
  text-align: center;
  padding: 0;
  padding-top: 0.3rem;
  vertical-align: top;
}

button.icon-and-text, .button.icon-and-text {
  height: 2.25rem;
  line-height: 100%;
  vertical-align: top;
}

button.clone, .button.clone {
  float: left;
  margin-left: 1rem;
}

button.btn-light, a.btn-light {
  color: #2F9ACD;
  background: #FFFFFF;
  border: 1px solid #2F9ACD;
}
button.btn-light.disabled, a.btn-light.disabled {
  border: 1px solid #E0E0E0;
  color: #2F9ACD;
  cursor: default;
  background: #FFFFFF;
}
button.btn-light:hover, a.btn-light:hover {
  color: #2F9ACD;
  background: #FFFFFF;
}
button.btn-light:not(.disabled):hover, a.btn-light:not(.disabled):hover {
  background: #eaf5fa;
}

button.link, .button.link {
  background-color: transparent !important;
  color: #2F9ACD;
  font-weight: 500;
}
button.link.secondary, .button.link.secondary {
  color: #616161;
}
button.link.success, .button.link.success {
  color: #8BB741;
}
button.link.warning, .button.link.warning {
  color: #FEA82F;
}
button.link.info, .button.link.info {
  color: #757575;
}
button.link.alert, .button.link.alert {
  color: #DF5146;
}
button.link:hover, .button.link:hover {
  background-color: transparent;
  text-decoration: underline;
}
button.link.borderless:hover, .button.link.borderless:hover {
  text-decoration: none;
}

.action.buttons {
  text-align: right;
}
.action.buttons button.alert, .action.buttons .button.alert {
  float: left;
}

.pr-button-group {
  display: inline;
  margin: 0 0 0 0.0625rem;
  list-style-type: none;
  font-size: 0;
  white-space: nowrap;
}
.pr-button-group li {
  margin-left: -0.0625rem;
}

.pr-box, .pr-button, .color-pr-button, .range-pr-button {
  display: inline-block;
  z-index: 1020;
  padding: 0.375rem 0.3125rem 0.25rem 0.3125rem;
  border: 1px solid #616161;
  line-height: 100%;
  font-weight: 500;
  font-size: 0.8125rem;
  background-color: #FFFFFF;
}

.pr-button:not(.disabled):hover, .color-pr-button:not(.disabled):hover, .range-pr-button:not(.disabled):hover, .pr-button:not(.disabled).active, .color-pr-button:not(.disabled).active, .range-pr-button:not(.disabled).active {
  background-color: #616161;
  color: #FFFFFF;
  cursor: pointer;
}
.pr-button.disabled, .disabled.color-pr-button, .disabled.range-pr-button {
  border: 1px solid #E0E0E0;
  color: #BDBDBD;
  cursor: default;
}
.pr-button.warning, .warning.color-pr-button, .warning.range-pr-button {
  background-color: #FEA82F;
  color: #FFFFFF;
}
.pr-button i, .color-pr-button i, .range-pr-button i {
  line-height: 0;
}

.range-pr-button {
  font-size: 0.75rem;
  border: 1px solid #BDBDBD;
  padding: 0.375rem 0.75rem 0.25rem 0.75rem;
  margin-left: -0.075rem;
  height: 1.5625rem;
}
.range-pr-button:not(.disabled):hover, .range-pr-button:not(.disabled).active {
  background-color: #2F9ACD;
}

.color-pr-button:not(.disabled):hover, .color-pr-button:not(.disabled).active {
  background-color: #2F9ACD;
}

pagination-detail {
  line-height: 100%;
  text-align: center;
}
pagination-detail ul.pagination {
  display: inline-block;
  margin: 0.5rem 0 0.25rem 0;
}

.pr-dropdown-button, pr-dropdown *[pr-dropdown-trigger] {
  display: flex;
  align-items: center;
  padding-top: 6px;
  border-bottom: 4px solid transparent;
  color: #212121;
}
.pr-dropdown-button:hover:not(.button-dropdown, .link-dropdown), pr-dropdown [pr-dropdown-trigger]:hover:not(.button-dropdown, .link-dropdown), .pr-dropdown-button.active:not(.button-dropdown, .link-dropdown), pr-dropdown .active[pr-dropdown-trigger]:not(.button-dropdown, .link-dropdown), .pr-dropdown-button:focus:not(.button-dropdown, .link-dropdown), pr-dropdown [pr-dropdown-trigger]:focus:not(.button-dropdown, .link-dropdown) {
  border-bottom: 4px solid #2F9ACD;
}
.pr-dropdown-button.link-dropdown i.dropdown, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger] i.dropdown, savable-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger] i.dropdown, pr-dropdown .link-dropdown[pr-dropdown-trigger] i.dropdown, pr-dropdown tag-search-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger] i.dropdown, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger] i.dropdown, pr-dropdown savable-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger] i.dropdown, savable-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger] i.dropdown {
  font-size: 0.5rem;
}
.pr-dropdown-button.link-dropdown:hover, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger]:hover, savable-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger]:hover, pr-dropdown .link-dropdown[pr-dropdown-trigger]:hover, pr-dropdown tag-search-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger]:hover, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger]:hover, pr-dropdown savable-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger]:hover, savable-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger]:hover {
  color: #2F9ACD;
}
.pr-dropdown-button.link-dropdown.active, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button.active[pr-dropdown-trigger], savable-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button.active[pr-dropdown-trigger], pr-dropdown .link-dropdown.active[pr-dropdown-trigger], pr-dropdown tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .active[pr-dropdown-trigger], tag-search-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown .active[pr-dropdown-trigger], pr-dropdown savable-multiselect-dropdown.dropdown-menu ds-dropdown .active[pr-dropdown-trigger], savable-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown .active[pr-dropdown-trigger], .pr-dropdown-button.link-dropdown:focus, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger]:focus, savable-multiselect-dropdown.dropdown-menu ds-dropdown .pr-dropdown-button[pr-dropdown-trigger]:focus, pr-dropdown .link-dropdown[pr-dropdown-trigger]:focus, pr-dropdown tag-search-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger]:focus, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger]:focus, pr-dropdown savable-multiselect-dropdown.dropdown-menu ds-dropdown [pr-dropdown-trigger]:focus, savable-multiselect-dropdown.dropdown-menu ds-dropdown pr-dropdown [pr-dropdown-trigger]:focus {
  color: #19536E;
}

pr-dropdown {
  display: inline-block;
  font-size: 14px;
  position: relative;
}
pr-dropdown .pr-dropdown-flex-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
pr-dropdown .pr-dropdown-flex-row .fill-available-space {
  flex-grow: 1;
}
pr-dropdown .dropdown-label {
  padding-right: 2px;
  color: #616161;
}
pr-dropdown.with-button {
  margin-left: 0;
}
pr-dropdown.with-button *[pr-dropdown-trigger] {
  padding-left: 0;
  border-bottom: 4px solid transparent;
}
pr-dropdown *[pr-dropdown-trigger] {
  cursor: pointer;
}
pr-dropdown *[pr-dropdown-trigger].inline {
  display: inline;
  padding: 0;
}
pr-dropdown *[pr-dropdown-trigger].disabled-trigger {
  pointer-events: none;
}
pr-dropdown *[pr-dropdown-trigger].disabled-trigger .trigger-container {
  color: #757575;
  background-color: #FAFAFA;
  border-bottom-color: #757575;
}
pr-dropdown *[pr-dropdown-trigger] .dropdown-value {
  font-weight: 500;
}
pr-dropdown *[pr-dropdown-body] {
  z-index: 1020;
  position: absolute;
}
pr-dropdown ul[pr-dropdown-body] {
  text-align: left;
  margin-top: -6px;
  margin-left: 0;
  border: none;
  border-top: 4px solid #2F9ACD;
  box-shadow: 0 4px 8px 0 rgba(33, 33, 33, 0.5);
  background-color: #FFFFFF;
  color: #212121;
  font-size: 14px;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) {
  padding: 0 16px;
  line-height: 1.75rem;
  white-space: nowrap;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles).threatmatch-li {
  padding: 0;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles):hover, pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles):focus {
  outline: none;
  background-color: #DFF0F8;
  cursor: pointer;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles).active {
  background-color: #E0E0E0;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles).active i {
  color: #2F9ACD;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles).active i.fa-house {
  color: #616161;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles).no-hover {
  background-color: inherit;
  cursor: default;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) a {
  color: inherit;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) a:hover {
  text-decoration: none;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) i {
  font-size: 14px;
  color: transparent;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) i:not(.fa-check) {
  padding-right: 4px;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) .fa {
  padding-top: 4px;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) .visible-icon {
  color: inherit !important;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) .visible-alert {
  color: #DF5146 !important;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) .visible-edit {
  color: #2F9ACD !important;
}
pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) .search-button {
  color: #2F9ACD;
  background-color: transparent;
  position: absolute;
  right: 7px;
  top: 4px;
}

predicate-dropdown-menu pr-dropdown li, search-events-per-page-dropdown pr-dropdown li {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.radio-list {
  font-size: 0.875rem;
  list-style: none;
  margin: 0;
}

#date-range-controls a#clear-dates.pr-button, #date-range-controls a#clear-dates.range-pr-button, #date-range-controls a#clear-dates.color-pr-button {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
}

person-picker ds-dropdown .options-container {
  max-height: 290px !important;
  overflow: revert;
}

.saved-query-name {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #616161;
  line-height: 16px;
}

.saved-query-aggregation {
  font-size: 12px;
  font-weight: 400;
  color: #616161;
  line-height: 16px;
  margin-top: 2px;
}
.saved-query-aggregation .aggregation-field {
  font-weight: 600;
}

.saved-query-string {
  display: block;
  font-size: 14px;
  color: #212121;
  font-weight: 500;
  line-height: 16px;
}

.saved-query-actions {
  display: block;
  margin-top: 8px;
}

search-link a[href]:hover, *[search-link] a[href]:hover {
  text-decoration: none;
}
search-link.saved-query-string a:hover, search-link.saved-query-string span:hover, *[search-link].saved-query-string a:hover, *[search-link].saved-query-string span:hover {
  color: #24769E;
}
search-link a:not([href]), *[search-link] a:not([href]) {
  cursor: default;
}

.f-dropdown {
  max-width: 300px;
  width: auto;
}
.f-dropdown li a {
  overflow: hidden;
  text-overflow: ellipsis;
}

a.hover-link:hover {
  text-decoration: underline;
}

.boxed-badge, .risk-state-badge, ticket-list-page-grouped-list tr .subtasks-badge,
ticket-list-page-default-list tr .subtasks-badge,
ticket-list-page-due-date-list tr .subtasks-badge,
task-schedule-list-page-grouped-list tr .subtasks-badge,
task-schedule-list-page-default-list tr .subtasks-badge,
library-list-page-grouped-list tr .subtasks-badge,
parent-and-subtasks tr .subtasks-badge,
parent-and-subschedules tr .subtasks-badge, ticket-state-badge .ticket-state-badge, due-date-badge .due-date-badge, .beta-badge, .alpha-badge, .notified-regulator-badge, .rated-threat, .unrated-threat, .threat-badge, .severity-badge {
  display: inline-block;
  padding: 0.25rem 0.25rem 0.125rem;
  font-size: 0.75rem;
  line-height: 1;
  margin: 0 0.25rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #212121;
}

.rated-threat.rating-CRITICAL, .unrated-threat.rating-CRITICAL, .threat-badge.rating-CRITICAL, .severity-badge.rating-CRITICAL {
  border-color: #C02D21;
  background-color: #C02D21;
  color: #FFFFFF;
}
.rated-threat.rating-HIGH, .unrated-threat.rating-HIGH, .threat-badge.rating-HIGH, .severity-badge.rating-HIGH {
  border-color: #DF5146;
  background-color: #DF5146;
  color: #FFFFFF;
}
.rated-threat.rating-MEDIUM, .unrated-threat.rating-MEDIUM, .threat-badge.rating-MEDIUM, .severity-badge.rating-MEDIUM {
  border-color: #FEA82F;
  background-color: #FEA82F;
  color: #FFFFFF;
}
.rated-threat.rating-LOW, .unrated-threat.rating-LOW, .threat-badge.rating-LOW, .severity-badge.rating-LOW {
  border-color: #8BB741;
  background-color: #8BB741;
  color: #FFFFFF;
}

.notified-regulator-badge {
  margin: 0;
  border-color: #757575;
  background-color: #FFFFFF;
}

.alpha-badge {
  color: #DF5146;
  border-color: #871F17;
  background-color: white;
}

.beta-badge {
  color: #8BB741;
  border-color: #8BB741;
  background-color: white;
  font-size: x-small;
}

threat-rating-dropdown .rated-threat, threat-rating-dropdown .unrated-threat, threat-rating-dropdown .threat-badge {
  cursor: pointer;
}
threat-rating-dropdown li.indent {
  padding-left: 1rem;
}

.trigger-graph-container {
  margin: 0.9375rem 0.9375rem 0 0.9375rem;
  text-align: center;
}
.trigger-graph-container #legend {
  display: inline-block;
  margin-top: 0.9375rem;
  width: 100%;
  font-size: 0.875rem;
}
.trigger-graph-container #legend #trendline {
  color: #7B1E7A;
}
.trigger-graph-container .normal-alert {
  fill: #24769E;
  stroke: #24769E;
  color: #24769E;
}
.trigger-graph-container .upper-alert {
  fill: #8BB741;
  stroke: #8BB741;
  color: #8BB741;
}
.trigger-graph-container .lower-alert {
  fill: #FEA82F;
  stroke: #FEA82F;
  color: #FEA82F;
}
.trigger-graph-container .selected-alert {
  fill-opacity: 0.2;
}

.sequence-alert-container {
  margin: 0.9375rem 0.9375rem 0 0.9375rem;
  text-align: center;
}

.datepicker.datepicker-inline {
  position: relative;
}
.datepicker table {
  table-layout: auto;
  width: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.datepicker table td, .datepicker table th {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.75);
}
.datepicker.datepicker-dropdown.dropdown-menu {
  padding: 2px;
  background-color: #616161;
}
.datepicker .month.disabled, .datepicker .day.disabled {
  color: #BDBDBD;
}

.query-string {
  color: rgba(33, 33, 33, 0.6);
  font-family: "Cousine", monospace;
  font-size: 1.1em;
}

severity-icon .severity-critical {
  color: #C02D21;
}
severity-icon .severity-high {
  color: #DF5146;
}
severity-icon .severity-medium {
  color: #FEA82F;
}
severity-icon .severity-low {
  color: #8BB741;
}

floating-tooltip {
  background: #757575;
  color: #E0E0E0;
  font-weight: 100;
  z-index: 1060;
  position: fixed;
  display: block;
  padding: 7px 12px 5px 12px;
  height: auto;
  width: auto;
  max-width: 31.25rem;
  box-shadow: 2px 1px 3px 0px #212121;
}

floating-tooltip div {
  font-size: 0.75rem;
  line-height: 100%;
}

floating-tooltip div span {
  font-weight: 500;
}

floating-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
}

floating-tooltip.top-left-arrow:after {
  bottom: -10px;
  right: 4px;
  border-width: 10px 10px 0;
  border-color: #757575 transparent transparent transparent;
}

floating-tooltip.top-right-arrow:after {
  bottom: -10px;
  left: 3px;
  border-width: 10px 10px 0;
  border-color: #757575 transparent transparent transparent;
}

floating-tooltip.bottom-left-arrow:after {
  top: -10px;
  right: 4px;
  border-width: 0px 10px 10px;
  border-color: transparent transparent #757575 transparent;
}

floating-tooltip.bottom-right-arrow:after {
  top: -10px;
  left: 3px;
  border-width: 0px 10px 10px;
  border-color: transparent transparent #757575 transparent;
}

floating-tooltip.mid-left-arrow:after {
  right: -20px;
  top: 0px;
  border-width: 10px 10px 10px;
  border-color: transparent transparent transparent #757575;
}

floating-tooltip.mid-right-arrow:after {
  left: -20px;
  top: 0px;
  border-width: 10px 10px 10px;
  border-color: transparent #757575 transparent transparent;
}

.applied-label {
  background-color: #BDBDBD;
  color: #212121;
  border-radius: 0.25rem;
  padding: 0.25rem 0.3125rem 0.125rem 0.3125rem;
  margin-right: 0.125rem;
  font-weight: 500;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

formatted-html, div.formatted-html {
  word-wrap: break-word;
}
formatted-html > span *, div.formatted-html > span * {
  font-size: 14px;
}
formatted-html > span > *, formatted-html > span > ol li, div.formatted-html > span > *, div.formatted-html > span > ol li {
  margin-bottom: 14px;
}
formatted-html > span > *:last-child, div.formatted-html > span > *:last-child {
  margin-bottom: 0;
}
formatted-html h1, formatted-html h2, formatted-html h3, formatted-html h4, formatted-html h5, formatted-html h6, div.formatted-html h1, div.formatted-html h2, div.formatted-html h3, div.formatted-html h4, div.formatted-html h5, div.formatted-html h6 {
  border: none;
  margin-bottom: 0;
}
formatted-html h1, div.formatted-html h1 {
  font-size: 1.125rem;
}
formatted-html h2, div.formatted-html h2 {
  font-size: 1rem;
}
formatted-html h3, div.formatted-html h3 {
  font-size: 0.875rem;
}
formatted-html h4, div.formatted-html h4 {
  font-size: 1.125rem;
}
formatted-html h5, div.formatted-html h5 {
  font-size: 1rem;
}
formatted-html pre, div.formatted-html pre {
  margin-left: 1rem;
  white-space: pre-wrap;
}
formatted-html pre ip-dropdown ul, div.formatted-html pre ip-dropdown ul {
  color: #212121;
  font-family: "AvenirNextLTPro", sans-serif;
}
formatted-html pre .f-dropdown li a, div.formatted-html pre .f-dropdown li a {
  display: inline;
}
formatted-html ul, formatted-html ol, div.formatted-html ul, div.formatted-html ol {
  font-size: 100%;
  margin-bottom: 0;
}
formatted-html p, div.formatted-html p {
  font-weight: inherit;
  font-size: inherit;
}

.reveal-modal {
  padding: 1.25rem;
  max-width: none;
  max-height: 80%;
  overflow: auto;
}
.reveal-modal.small-modal {
  width: 25%;
}
.reveal-modal.medium-modal {
  width: 50%;
}
.reveal-modal.large-modal {
  width: 95%;
}

.tt-menu {
  background-color: #FAFAFA;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.04), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 3px 6px -4px rgba(0, 0, 0, 0.08);
  padding: 0.5rem;
}

.tt-suggestion.tt-cursor {
  background-color: white;
  color: #212121;
}

.loading-spinner {
  opacity: 0.6;
  margin: 7rem 0;
}

.loading-container {
  text-align: center;
}

*[transparent-loadable] {
  position: relative;
}
*[transparent-loadable] .transparent-loading-container {
  position: absolute;
  z-index: 1010;
  background-color: #FAFAFA;
  text-align: center;
  opacity: 0.6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.scrolling-panel {
  overflow-y: scroll;
}
@media print {
  .scrolling-panel {
    overflow-y: inherit;
  }
}

@media print {
  .page-break-after {
    page-break-after: always;
  }
}

.modal h3 {
  font-weight: 600;
}
.modal .center-text {
  margin: 0;
  padding: 2rem;
  text-align: center;
}

trigger-graph {
  height: 175px;
}
trigger-graph svg {
  fill: none;
}
trigger-graph svg .label {
  fill: #212121;
  text-anchor: middle;
  font-size: 0.8125rem;
}
trigger-graph svg .axis {
  stroke: #FAFAFA;
  stroke-width: 1;
  stroke-opacity: 0.5;
}
trigger-graph svg .lines path.range-line {
  stroke-width: 0.125rem;
  stroke: #24769E;
}
trigger-graph svg .anomaly-circle {
  stroke: #DF5146;
  stroke-dasharray: 3, 2;
}
trigger-graph svg .trend-line {
  fill: none;
  stroke: #7B1E7A;
  opacity: 0.9;
  stroke-width: 0.125rem;
  stroke-dasharray: 2, 1;
}
trigger-graph svg .horizontal-line-upper, trigger-graph svg .horizontal-line-lower {
  fill: none;
  opacity: 0.9;
  stroke-width: 0.125rem;
  stroke: #8BB741;
}
trigger-graph svg .horizontal-line-lower {
  stroke: #FEA82F;
}

.declutter .show-on-hover {
  opacity: 0;
}
.declutter .show-on-hover:focus {
  opacity: inherit;
}
.declutter:hover .show-on-hover {
  opacity: inherit;
}

.no-list-styles {
  display: block;
  margin: 0;
  list-style: none outside;
}

.margin-top {
  margin-top: 0.3125rem;
}

.sheet-side-menu-section {
  position: absolute;
  width: 128px;
  padding-right: 0;
  padding-left: 8px;
}
.sheet-side-menu-section h2 {
  padding: 0;
}
.sheet-side-menu-section .sheet-side-menu {
  padding-top: 4px;
}
.sheet-side-menu-section .sheet-side-menu .sheet-side-menu-item {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 10px 0 10px 12px;
  width: 100%;
}
.sheet-side-menu-section .sheet-side-menu .sheet-side-menu-item.active, .sheet-side-menu-section .sheet-side-menu .sheet-side-menu-item:hover {
  background-color: #E0E0E0;
}
.sheet-side-menu-section .sheet-side-menu .sheet-side-menu-item .sheet-side-menu-item-text:hover {
  color: inherit;
  background-color: inherit;
}
.sheet-side-menu-section .sheet-side-menu .sheet-side-menu-item.alert-inbox-filter {
  padding-left: 8px;
  font-size: 0.75rem;
}

.banded-block, .cat-banded-block {
  color: #616161;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-left: 3px solid #FAFAFA;
  margin: 0.5rem 0;
}

.banded-block-title, .cat-banded-block-title, .ticket-view .ticket-log dt {
  border-bottom: 1px solid #FAFAFA;
  font-weight: 600;
  font-size: 0.75rem;
  margin-left: 0.625rem;
}

.banded-block-content, .cat-banded-block-content, .ticket-view ul.history-log > li dd {
  padding-left: 1rem;
}

.left-border.success {
  position: absolute;
  height: 100%;
  left: 0px;
  width: 3px;
  background-color: #8BB741;
}

.gray-notification, .error-notification {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error-notification {
  color: #DF5146;
}

.gray-notification {
  color: #757575;
}

.drag-over-border {
  border: 0.125rem dashed #2F9ACD !important;
}

.drag-opaque {
  opacity: 0.4;
}

.drag-cursor {
  cursor: move;
}

@media (max-height: 2700px) {
  .media-chart-heights {
    height: 800px;
  }
}
@media (max-height: 1600px) {
  .media-chart-heights {
    height: 500px;
  }
}
@media (max-height: 1250px) {
  .media-chart-heights {
    height: 400px;
  }
}
@media (max-height: 900px) {
  .media-chart-heights {
    height: 300px;
  }
}
@media (max-height: 750px) {
  .media-chart-heights {
    height: 200px;
  }
}
@media print {
  .media-chart-heights {
    height: 300px;
  }
}

.underline {
  border-bottom: 1px solid #E0E0E0;
}

.uppercase {
  text-transform: uppercase;
}

.demibold {
  font-weight: 500;
}

.small-font {
  font-size: 0.75rem;
}

.short-width {
  width: 20%;
}

.long-width {
  width: 80%;
}

.extra-long-width {
  width: 90%;
}

.space-between {
  justify-content: space-between;
}

.notification-message {
  display: flex;
  font-size: 0.8125rem;
  align-items: center;
}
.notification-message notification-icon i {
  margin-right: 16px;
}
.notification-message button.notification-message-action {
  font-size: 0.8125rem;
}

.indicator {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  padding-left: 2px;
}

pr-dropdown:not([ng-version]) .dropdown-label {
  padding-right: 6px;
}
pr-dropdown:not([ng-version]) .dropdown-value {
  padding-right: 3px;
}
pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li.threatmatch-li {
  padding: 0;
}
pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li.title-option {
  font-size: 0.75rem;
  color: #757575;
  text-transform: uppercase;
}
pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li.separator-above {
  border-top: 1px solid #BDBDBD;
  padding-top: 0.1875rem;
}
pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li .wide {
  display: block;
  padding: 0.0625rem 1.875rem 0 0.625rem;
}

.sidebar .row-flex {
  display: flex;
  flex-direction: row;
}
.sidebar .row-flex dt, .sidebar .row-flex dd {
  padding: 0.25rem 0;
  line-height: 1.5rem;
  font-size: 0.875rem;
}
.sidebar .row-flex dt {
  flex: 35%;
  font-weight: 500;
  color: #616161;
}
.sidebar .row-flex dd {
  flex: 65%;
  padding-right: 1rem;
  font-weight: 400;
  color: #212121;
}

escalate-dropdown .border-top {
  border-top: 1px solid #E0E0E0;
  padding-top: 0.1875rem;
}

search-results-table.columns {
  padding: 0;
}

ticket-alerts-table search-results-table {
  overflow-x: unset;
}

saved-query-summary .action-icon .mat-icon {
  fill-rule: evenodd;
  fill: #FEA82F;
}

info-banner .banner-container {
  height: 85px;
  border-top: 4px solid;
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 14px;
}
info-banner .banner-container.banner-type-success {
  border-top-color: #8BB741;
  background-color: #F6FFED;
}
info-banner .banner-container.banner-type-info {
  border-top-color: #2F9ACD;
  background-color: #F7FBFD;
}
info-banner .banner-container.banner-type-warning {
  border-top-color: #FEA82F;
  background-color: #FFFCF7;
}
info-banner .banner-container.banner-type-alert {
  border-top-color: #DF5146;
  background-color: #FEF8F8;
}
info-banner .banner-inner-container {
  display: flex;
  flex-grow: 1;
  padding: 24px;
}
info-banner .banner-main-icon {
  padding: 0 10px 0 10px;
  font-size: 24px;
  font-weight: bold;
}
info-banner .banner-main-icon.banner-type-success {
  color: #8BB741;
}
info-banner .banner-main-icon.banner-type-info {
  color: #2F9ACD;
}
info-banner .banner-main-icon.banner-type-warning {
  color: #FEA82F;
}
info-banner .banner-main-icon.banner-type-alert {
  color: #DF5146;
}
info-banner .link-text.banner-type-success, info-banner alert-inbox .banner-type-success.scrolling-panel-text, alert-inbox info-banner .banner-type-success.scrolling-panel-text {
  color: #8BB741;
}
info-banner .link-text.banner-type-info, info-banner alert-inbox .banner-type-info.scrolling-panel-text, alert-inbox info-banner .banner-type-info.scrolling-panel-text {
  color: #2F9ACD;
}
info-banner .link-text.banner-type-warning, info-banner alert-inbox .banner-type-warning.scrolling-panel-text, alert-inbox info-banner .banner-type-warning.scrolling-panel-text {
  color: #FEA82F;
}
info-banner .link-text.banner-type-alert, info-banner alert-inbox .banner-type-alert.scrolling-panel-text, alert-inbox info-banner .banner-type-alert.scrolling-panel-text {
  color: #E67971;
}
info-banner .banner-close-icon {
  color: #757575;
}
info-banner .banner-message-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
info-banner .banner-title {
  font-weight: 600;
  font-size: 16px;
}
info-banner .banner-message {
  font-weight: 500;
  font-size: 14px;
  white-space: pre-line;
}

.edit-page header.action-header.action-group:not(.remove-old-styles), .view-page header.action-header.action-group:not(.remove-old-styles) {
  justify-content: left;
  align-items: center;
}
.edit-page header.action-header.action-group:not(.remove-old-styles).action-column, .view-page header.action-header.action-group:not(.remove-old-styles).action-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.edit-page header .header-action-buttons, .view-page header .header-action-buttons {
  margin-left: auto;
}
.edit-page .section, .view-page .section {
  background: white;
  margin: 12px 0;
  padding-bottom: 24px;
}
.edit-page .section:not(.pr-dropdown-overflow-visible), .view-page .section:not(.pr-dropdown-overflow-visible) {
  overflow: hidden;
}
.edit-page .section.overflow-visible, .view-page .section.overflow-visible {
  overflow: visible;
}
.edit-page .section.overflow-visible .sub-header, .view-page .section.overflow-visible .sub-header {
  padding-top: 16px;
}
.edit-page .section:first-of-type, .view-page .section:first-of-type {
  margin-top: 0;
}
.edit-page .fixed-right-content, .view-page .fixed-right-content {
  position: static;
  float: right;
  width: 25%;
  min-width: 23.5rem;
  overflow-y: auto;
  height: 100%;
  padding-left: 1rem;
}
.edit-page .fixed-left-content, .view-page .fixed-left-content {
  position: static;
  float: right;
  width: 75%;
  max-width: calc(100% - 23.5rem);
  overflow-y: auto;
  height: 100%;
}

.view-page .sidebar .row-flex dt {
  font-weight: normal;
}
.view-page .boxed-badge, .view-page .risk-state-badge, .view-page ticket-list-page-grouped-list tr .subtasks-badge, ticket-list-page-grouped-list tr .view-page .subtasks-badge,
.view-page ticket-list-page-default-list tr .subtasks-badge,
ticket-list-page-default-list tr .view-page .subtasks-badge,
.view-page ticket-list-page-due-date-list tr .subtasks-badge,
ticket-list-page-due-date-list tr .view-page .subtasks-badge,
.view-page task-schedule-list-page-grouped-list tr .subtasks-badge,
task-schedule-list-page-grouped-list tr .view-page .subtasks-badge,
.view-page task-schedule-list-page-default-list tr .subtasks-badge,
task-schedule-list-page-default-list tr .view-page .subtasks-badge,
.view-page library-list-page-grouped-list tr .subtasks-badge,
library-list-page-grouped-list tr .view-page .subtasks-badge,
.view-page parent-and-subtasks tr .subtasks-badge,
parent-and-subtasks tr .view-page .subtasks-badge,
.view-page parent-and-subschedules tr .subtasks-badge,
parent-and-subschedules tr .view-page .subtasks-badge, .view-page ticket-state-badge .ticket-state-badge, ticket-state-badge .view-page .ticket-state-badge, .view-page due-date-badge .due-date-badge, due-date-badge .view-page .due-date-badge, .view-page .rated-threat, .view-page .unrated-threat, .view-page .threat-badge, .view-page .severity-badge, .view-page .notified-regulator-badge, .view-page .alpha-badge, .view-page .beta-badge {
  border: 1px solid #E0E0E0;
}
.view-page .section-title {
  font-size: 0.75rem;
  color: #616161;
}

trigger-action-display #watchers-list li.nice-form-display-text {
  font-size: 0.875rem;
}

trigger-edit-page .ticket-watchers-title {
  font-size: 0.75rem;
  color: #616161;
  margin-top: 1rem;
}

.required-asterisk {
  color: #DF5146;
}

tag-search-multiselect-dropdown, savable-multiselect-dropdown {
  margin-left: 4px;
  margin-right: 4px;
}
tag-search-multiselect-dropdown .dropdown-label, savable-multiselect-dropdown .dropdown-label {
  color: #757575;
  font-weight: 400;
}
tag-search-multiselect-dropdown ds-dropdown, savable-multiselect-dropdown ds-dropdown {
  height: 34px;
  display: inline-block;
  border-bottom: 4px solid transparent;
}
tag-search-multiselect-dropdown ds-dropdown .fa-chevron-up, savable-multiselect-dropdown ds-dropdown .fa-chevron-up {
  font-size: 0.5rem;
}
tag-search-multiselect-dropdown ds-dropdown .fa-chevron-down, savable-multiselect-dropdown ds-dropdown .fa-chevron-down {
  font-size: 0.5rem;
}
tag-search-multiselect-dropdown ds-dropdown .trigger-container, savable-multiselect-dropdown ds-dropdown .trigger-container {
  display: flex;
  height: 24px;
  border-bottom: 1px solid transparent;
}
tag-search-multiselect-dropdown ds-dropdown .trigger-container:active, tag-search-multiselect-dropdown ds-dropdown .trigger-container:focus, savable-multiselect-dropdown ds-dropdown .trigger-container:active, savable-multiselect-dropdown ds-dropdown .trigger-container:focus {
  border-bottom: 1px solid #2F9ACD;
}
tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .trigger-container:active, tag-search-multiselect-dropdown.dropdown-menu ds-dropdown .trigger-container:focus, savable-multiselect-dropdown.dropdown-menu ds-dropdown .trigger-container:active, savable-multiselect-dropdown.dropdown-menu ds-dropdown .trigger-container:focus {
  border-bottom: 1px solid transparent;
}
tag-search-multiselect-dropdown action-buttons div, savable-multiselect-dropdown action-buttons div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

view-data-restores-modal #status-selector .ds-dropdown-trigger {
  border-bottom: none;
  margin: 0;
}
view-data-restores-modal #status-selector ds-dropdown .trigger-container {
  border: none;
}

activity-log-section #visible-activity-logs .trigger-container {
  border-bottom: none;
}

search-nested-fields .ds-dropdown-trigger {
  margin: 0;
}

ds-page-header ds-actions {
  display: flex;
  gap: 8px;
}

ticket-view-sidebar ds-page-section .page-section, task-schedule-edit-page task-schedule-sidebar ds-page-section .page-section {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
ticket-view-sidebar ds-page-section .page-section h3, task-schedule-edit-page task-schedule-sidebar ds-page-section .page-section h3 {
  margin-left: -10px;
  margin-top: 0 !important;
}

escalation-steps-table .schedule-actions .ds-dropdown-trigger {
  margin: 0;
}
escalation-steps-table ds-label .high {
  background-color: #E67971 !important;
  border-color: #DF5146 !important;
}

schedule-interval-row .time-container .larger-width ds-select {
  width: 160px;
}

ds-rich-text-editor {
  width: 100%;
}
ds-rich-text-editor .toastui-editor-toolbar-icons:disabled {
  background-color: unset;
  border: none;
}

cyber-risk-readiness-details-card .crr-card-disabled .cyber-risk-readiness-bar.disabled {
  background-color: #E0E0E0;
}

pr-dropdown.recent-items-dropdown *[pr-dropdown-trigger] {
  padding-top: 0;
  border-bottom: revert;
}
pr-dropdown.recent-items-dropdown ul[pr-dropdown-body] {
  margin: 0 4px 0 0;
}
pr-dropdown.recent-items-dropdown li {
  font-size: 0.8125rem;
  padding-left: 20px;
  line-height: 32px;
}
pr-dropdown.recent-items-dropdown .recent-items-dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(33, 33, 33, 0.5);
  display: block;
}
pr-dropdown.recent-items-dropdown .recent-items-dropdown-list .dropdown-fixed-text {
  font-style: italic;
}
pr-dropdown.recent-items-dropdown .recent-items-dropdown-list .divider {
  border-top: 1px solid #E0E0E0;
}

dashboard-custom-editor-zen-mode .content-header .search-filter input[type=text] {
  width: 100%;
}

mapping-framework-line-item-modal .content-header .search-filter input[type=text] {
  width: 100%;
}

dashboard-workspace-dropdown pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) i:not(.fa-check) {
  padding-right: unset;
  color: unset;
}

dashboard-list-header dashboard-workspace-dropdown .dropdown-label {
  display: none;
}

.info-banner-visible navigation-header-bar {
  top: 5.3125rem;
}

navigation-header-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
  padding: 0 24px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
}

navbar-user-menu {
  padding-right: 0.375rem;
}
navbar-user-menu .current-user-name {
  font-size: 0.8125rem;
  margin-left: 0.1875rem;
  vertical-align: text-top;
}
navbar-user-menu ul[pr-dropdown-body] li {
  line-height: 2.5rem;
}
navbar-user-menu svg {
  vertical-align: top;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  border: 0.0625rem solid #757575;
  border-radius: 50%;
  fill: #616161;
  background-color: #FFFFFF;
}
navbar-user-menu .mat-icon {
  vertical-align: top;
}
navbar-user-menu .toggleable-menu-item {
  display: flex;
  justify-content: space-between;
}
navbar-user-menu .toggleable-menu-item .toggle-on, navbar-user-menu .toggleable-menu-item .toggle-off {
  padding-left: 1rem;
  font-size: 0.6875rem;
  font-weight: 500;
  text-transform: uppercase;
}
navbar-user-menu .toggleable-menu-item .toggle-on {
  color: #8BB741;
}
navbar-user-menu .toggleable-menu-item .toggle-off {
  color: #FEA82F;
}

.info-banner-visible navigation-left-bar {
  top: 8.8125rem;
}

navigation-left-bar {
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
  overflow-x: visible;
}

.info-banner-visible .navigation-top-bar {
  top: 8.8125rem;
}

.navigation-top-bar {
  position: absolute;
  display: block;
  top: 3.5rem;
  left: calc(var(--nav-left-bar-width, 15.625rem) + 1.5rem);
  right: 0;
  height: var(--nav-top-bar-height, 5.75rem);
}
.navigation-top-bar > nav.new-tab-nav {
  height: var(--nav-top-bar-height, 5.75rem) !important;
  border-bottom: 1px solid #E0E0E0;
}
.navigation-top-bar > nav.new-tab-nav.no-bottom-border {
  border-bottom: none;
}
.navigation-top-bar .new-tab-nav .top-bar-section {
  display: flex;
  flex-direction: column;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul {
  display: flex;
  align-items: flex-end;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li {
  background: none;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li:not(:last-child) {
  margin-right: 56px;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li:hover > a {
  background: none !important;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li > a {
  margin-top: 0;
  white-space: nowrap;
  padding: 0 !important;
  font-size: 0.9375rem;
  line-height: 2.1875rem !important;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li.active > a, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li.not-click > a, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li > a:hover {
  position: relative;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li.active > a:before, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li.not-click > a:before, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li > a:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li.active:not(.nav-top-item-main) > a {
  font-weight: 500;
}
.navigation-top-bar .new-tab-nav .top-bar-section > ul > li.nav-top-item-main.active > a:before, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li.nav-top-item-main.not-click > a:before, .navigation-top-bar .new-tab-nav .top-bar-section > ul > li.nav-top-item-main > a:hover:before {
  right: auto;
  width: 32px;
}
.navigation-top-bar .new-tab-nav .top-bar-section h1.nav-top-item-main {
  font-size: 24px;
  font-weight: 500;
  float: left;
  margin-top: 16px;
}
.navigation-top-bar .new-tab-nav .top-bar-section.nav-section-main > ul > li.active > a:before, .navigation-top-bar .new-tab-nav .top-bar-section.nav-section-main > ul > li.not-click > a:before, .navigation-top-bar .new-tab-nav .top-bar-section.nav-section-main > ul > li > a:hover:before {
  background-color: #2F9ACD;
}

.top-bar-section ul.no-display-inline {
  display: block;
}

.dropdown-item-full-width {
  width: 100%;
}

login-page .row .columns, forgot-password-page .row .columns, reset-password-page .row .columns, totp-authentication-page .row .columns {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
login-page .alert-box, forgot-password-page .alert-box, reset-password-page .alert-box, totp-authentication-page .alert-box {
  margin: 0;
}
login-page form[name=forgotPassword] button, login-page form[name=login] button, login-page form[name=totpForm] button, forgot-password-page form[name=forgotPassword] button, forgot-password-page form[name=login] button, forgot-password-page form[name=totpForm] button, reset-password-page form[name=forgotPassword] button, reset-password-page form[name=login] button, reset-password-page form[name=totpForm] button, totp-authentication-page form[name=forgotPassword] button, totp-authentication-page form[name=login] button, totp-authentication-page form[name=totpForm] button {
  text-align: center;
  width: 100%;
}
login-page form a, forgot-password-page form a, reset-password-page form a, totp-authentication-page form a {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 1rem;
}

u2f-authentication-page .info-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
u2f-authentication-page .info-box .info-box-text {
  padding: 0.625rem;
  font-size: 16px;
}

.reset-password fieldset {
  background-color: #FFFFFF;
}
.reset-password fieldset legend {
  background-color: transparent;
}

setup-totp-page .setup-2fa-offset {
  padding-left: calc(var(--nav-left-bar-width, 15.625rem) + 1.5rem - 1rem);
}
setup-totp-page h3 {
  font-weight: 600;
  margin-bottom: 16px;
}
setup-totp-page .page-header {
  padding-left: 1rem;
}
setup-totp-page .page-header h1 {
  padding-left: 0;
}
setup-totp-page .main-page-body {
  display: flex;
  border-spacing: 1rem;
}
setup-totp-page .main-page-body .installation-instructions {
  margin: 1rem 0.5rem 1rem 1rem;
  vertical-align: top;
  border-spacing: 0;
}
setup-totp-page .main-page-body .installation-instructions h3 {
  color: #616161;
  text-transform: uppercase;
  border-bottom: 1px solid #E0E0E0;
}
setup-totp-page .main-page-body .installation-instructions .instructions-text {
  padding: 1rem 0.5rem;
}
setup-totp-page .main-page-body .installation-instructions .instructions-text p, setup-totp-page .main-page-body .installation-instructions .instructions-text li, setup-totp-page .main-page-body .installation-instructions .instructions-text h3 {
  font-size: 0.875rem;
}
setup-totp-page setup-2fa-sidebar {
  display: flex;
}
setup-totp-page setup-2fa-sidebar .code-and-secret {
  margin: 1rem 1rem 1rem 0.5rem;
  padding: 1rem;
  width: 18.75rem;
}
setup-totp-page setup-2fa-sidebar .code-and-secret .sidebar-title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #616161;
  text-transform: uppercase;
  border-bottom: 1px solid #E0E0E0;
}
setup-totp-page setup-2fa-sidebar .code-and-secret canvas {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
setup-totp-page setup-2fa-sidebar .code-and-secret #read-only-code {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-family: monospace;
  letter-spacing: 0.0625rem;
}
setup-totp-page .setup-complete-button {
  margin-right: 1rem;
}

.hover-table .chart-name-cell, .grouped-table .chart-name-cell {
  max-width: 12.5rem;
  word-break: break-word;
  overflow: auto;
}
.hover-table .chart-type-cell, .grouped-table .chart-type-cell {
  max-width: 1.25rem;
  overflow-wrap: normal;
  overflow: auto;
}

dashboard-list-page .action-header {
  height: 40px;
}

old-dashboard-edit-page .flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
old-dashboard-edit-page .chart-row-max-1 {
  width: 13.75rem;
}
old-dashboard-edit-page .chart-row-max-2 {
  width: 27.5rem;
}
old-dashboard-edit-page .chart-row-max-3 {
  width: 41.25rem;
}
old-dashboard-edit-page .chart-row-max-4 {
  width: 55rem;
}
old-dashboard-edit-page .chart-row-max-5 {
  width: 68.75rem;
}
old-dashboard-edit-page .flex-space-between-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
old-dashboard-edit-page .flex-center-content {
  display: flex;
  justify-content: center;
}
old-dashboard-edit-page .chart-tile {
  width: 12.5rem;
  border: 0.0625rem solid #757575;
  margin: 0.625rem;
}
old-dashboard-edit-page .chart-tile .chart-header {
  padding: 0.3125rem;
  border-bottom: 0.0625rem solid #757575;
}
old-dashboard-edit-page .chart-tile .chart-tile-preview-icon {
  background-color: #FAFAFA;
  height: 7.5rem;
}
old-dashboard-edit-page .chart-tile .chart-tile-preview-icon i {
  font-size: 5.625rem;
  align-self: center;
}
old-dashboard-edit-page .add-chart-dashboard {
  padding: 0;
  margin-left: 0.625rem;
}
old-dashboard-edit-page .add-chart-dashboard:hover {
  color: #2F9ACD;
}

navigation-top-bar-dashboard #angle-down-space {
  margin-right: 5.0625rem;
}

old-dashboard-main-page .chart-row {
  display: flex;
  flex-direction: row;
}
old-dashboard-main-page .dashboard-chart {
  width: 100%;
  min-width: 24.0625rem;
  padding: 0.3125rem;
  margin: 0.3125rem;
}

.color-0 rect {
  fill: #2F9ACD;
}
.color-0 .fa, .color-0 .fas {
  color: #2F9ACD;
}
.color-0.line path {
  fill: none;
  stroke: #2F9ACD;
}
.color-0.arc path {
  fill: #2F9ACD;
  stroke: #2F9ACD;
}

.color-1 rect {
  fill: #24769E;
}
.color-1 .fa, .color-1 .fas {
  color: #24769E;
}
.color-1.line path {
  fill: none;
  stroke: #24769E;
}
.color-1.arc path {
  fill: #24769E;
  stroke: #24769E;
}

.color-2 rect {
  fill: #8BB741;
}
.color-2 .fa, .color-2 .fas {
  color: #8BB741;
}
.color-2.line path {
  fill: none;
  stroke: #8BB741;
}
.color-2.arc path {
  fill: #8BB741;
  stroke: #8BB741;
}

.color-3 rect {
  fill: #6B8D32;
}
.color-3 .fa, .color-3 .fas {
  color: #6B8D32;
}
.color-3.line path {
  fill: none;
  stroke: #6B8D32;
}
.color-3.arc path {
  fill: #6B8D32;
  stroke: #6B8D32;
}

.color-4 rect {
  fill: #E67971;
}
.color-4 .fa, .color-4 .fas {
  color: #E67971;
}
.color-4.line path {
  fill: none;
  stroke: #E67971;
}
.color-4.arc path {
  fill: #E67971;
  stroke: #E67971;
}

.color-5 rect {
  fill: #DF5146;
}
.color-5 .fa, .color-5 .fas {
  color: #DF5146;
}
.color-5.line path {
  fill: none;
  stroke: #DF5146;
}
.color-5.arc path {
  fill: #DF5146;
  stroke: #DF5146;
}

.color-6 rect {
  fill: #FEA82F;
}
.color-6 .fa, .color-6 .fas {
  color: #FEA82F;
}
.color-6.line path {
  fill: none;
  stroke: #FEA82F;
}
.color-6.arc path {
  fill: #FEA82F;
  stroke: #FEA82F;
}

.color-7 rect {
  fill: #CF7A01;
}
.color-7 .fa, .color-7 .fas {
  color: #CF7A01;
}
.color-7.line path {
  fill: none;
  stroke: #CF7A01;
}
.color-7.arc path {
  fill: #CF7A01;
  stroke: #CF7A01;
}

.color-8 rect {
  fill: #A769A6;
}
.color-8 .fa, .color-8 .fas {
  color: #A769A6;
}
.color-8.line path {
  fill: none;
  stroke: #A769A6;
}
.color-8.arc path {
  fill: #A769A6;
  stroke: #A769A6;
}

.color-9 rect {
  fill: #7B1E7A;
}
.color-9 .fa, .color-9 .fas {
  color: #7B1E7A;
}
.color-9.line path {
  fill: none;
  stroke: #7B1E7A;
}
.color-9.arc path {
  fill: #7B1E7A;
  stroke: #7B1E7A;
}

.color-none rect {
  fill: #757575;
}
.color-none .fa, .color-none .fas {
  color: #757575;
}
.color-none.line path {
  fill: none;
  stroke: #757575;
}
.color-none.arc path {
  fill: #757575;
  stroke: #757575;
}

.color-low rect {
  fill: #8BB741;
}
.color-low .fa, .color-low .fas {
  color: #8BB741;
}
.color-low.line path {
  fill: none;
  stroke: #8BB741;
}
.color-low.arc path {
  fill: #8BB741;
  stroke: #8BB741;
}

.color-medium rect {
  fill: #FEA82F;
}
.color-medium .fa, .color-medium .fas {
  color: #FEA82F;
}
.color-medium.line path {
  fill: none;
  stroke: #FEA82F;
}
.color-medium.arc path {
  fill: #FEA82F;
  stroke: #FEA82F;
}

.color-high rect {
  fill: #DF5146;
}
.color-high .fa, .color-high .fas {
  color: #DF5146;
}
.color-high.line path {
  fill: none;
  stroke: #DF5146;
}
.color-high.arc path {
  fill: #DF5146;
  stroke: #DF5146;
}

.loading-svg.unloaded {
  visibility: hidden;
}

.loading-graph {
  position: relative;
}
.loading-graph .graph-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FAFAFA;
}
.loading-graph .graph-overlay table, .loading-graph .graph-overlay td {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  text-align: center !important;
  vertical-align: middle !important;
}
.loading-graph .spinner {
  opacity: 0.6;
}

.tooltip-graph {
  position: relative;
}
.tooltip-graph .tooltip {
  background: rgba(33, 33, 33, 0.85);
  color: #FAFAFA;
  border: 1px solid #FAFAFA;
  z-index: 1060;
  position: fixed;
  display: block;
  padding: 8px;
  height: auto;
  width: auto;
}

.dashboard-graph {
  display: block;
}
.dashboard-graph svg {
  width: 100%;
  height: 100%;
}
.dashboard-graph svg text {
  font-size: 0.75rem;
}
.dashboard-graph svg .axis path,
.dashboard-graph svg .axis line {
  fill: none;
  stroke: #212121;
  shape-rendering: crispEdges;
}
.dashboard-graph svg .axis .tick {
  fill: #212121;
}

.top-legend-graph .legend {
  text-align: right;
}
.top-legend-graph .legend-elem {
  display: inline-block;
  margin: 0 0.5rem;
  max-width: calc(20% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  padding-right: 0.5rem;
  cursor: pointer;
}
.top-legend-graph .legend-elem i {
  margin-right: 0.45rem;
}
.top-legend-graph .legend-elem:last-child {
  margin-right: 2rem;
}

.right-legend-graph svg, .right-legend-graph .legend {
  display: inline-block;
  vertical-align: top;
}
.right-legend-graph .legend {
  padding-left: 1rem;
}
.right-legend-graph .legend .legend-elem {
  cursor: pointer;
}
.right-legend-graph .legend .legend-elem i, .right-legend-graph .legend .legend-elem span {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.25rem;
}
.right-legend-graph .legend .legend-elem i {
  width: 0.875rem;
}
.right-legend-graph .legend .legend-elem span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
}

ip-map {
  display: block;
}
ip-map .ip-map {
  width: 100%;
  background: #FAFAFA;
}
ip-map .ip-map .leaflet-overlay-pane svg path {
  stroke: #333333;
  fill: #DF5146;
  fill-opacity: 0.4;
}
ip-map .ip-map .leaflet-control-zoom {
  opacity: 0.9;
}
ip-map .ip-map .leaflet-popup {
  color: #212121;
}
ip-map .ip-map .leaflet-popup-close-button a {
  color: #212121;
}

ticket-pipeline-graph .pipeline-stage .count-label-small, ticket-pipeline-graph .hover-stage .count-label-small {
  font-size: 0.75rem;
}
ticket-pipeline-graph .pipeline-stage .count-label, ticket-pipeline-graph .hover-stage .count-label {
  font-size: 1rem;
}
ticket-pipeline-graph .pipeline-stage .count-label-large, ticket-pipeline-graph .hover-stage .count-label-large {
  font-size: 1.5rem;
}
ticket-pipeline-graph .pipeline-stage .count-small, ticket-pipeline-graph .hover-stage .count-small {
  font-size: 1.25rem;
}
ticket-pipeline-graph .pipeline-stage .count, ticket-pipeline-graph .hover-stage .count {
  font-size: 2rem;
}
ticket-pipeline-graph .pipeline-stage .count-large, ticket-pipeline-graph .hover-stage .count-large {
  font-size: 2.5rem;
}
ticket-pipeline-graph .hover-stage polygon {
  stroke-width: 8px;
}

.chart-title {
  font-size: 16px;
  font-weight: 500;
}

.chart-subtitle {
  font-size: 0.8125rem;
  font-weight: 400;
}

.transparent {
  opacity: 0.15;
}

.no-data-message {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 50%;
  margin: 0 -25%;
  text-align: center;
}

.badge .badge-number {
  padding: 10px 30px;
}
@media (max-height: 2700px) {
  .badge .badge-number {
    font-size: 7.5rem;
    border-width: 0.9375rem;
  }
}
@media (max-height: 1600px) {
  .badge .badge-number {
    font-size: 5rem;
    border-width: 0.625rem;
  }
}
@media (max-height: 1250px) {
  .badge .badge-number {
    font-size: 4.375rem;
    border-width: 0.5625rem;
  }
}
@media (max-height: 900px) {
  .badge .badge-number {
    font-size: 3.75rem;
  }
}
@media (max-height: 750px) {
  .badge .badge-number {
    font-size: 3.125rem;
  }
}

search-page .container-sheet {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
}
search-page search-export > a[disabled] {
  opacity: 0.4;
}
search-page .search-results-header {
  display: table;
  width: 100%;
}
search-page .search-results-header search-count-label, search-page .search-results-header search-export {
  display: table-cell;
}
search-page .search-results-header h2 {
  display: inline;
  border: none;
}
search-page .search-results-header h4 {
  display: inline;
  font-weight: 500;
}
search-page .search-results-header search-export {
  vertical-align: middle;
}
search-page .search-results-header search-export #ticket-export {
  text-align: left;
}
search-page .search-results-header search-export #ticket-export li {
  padding: 0.5rem;
}
search-page .search-results-header search-export #ticket-export li.recent-title {
  cursor: default;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 0.5rem;
}
search-page .search-results-header search-export #ticket-export li.recent-title:hover {
  background-color: #FFFFFF;
}
search-page .search-bar {
  flex-grow: 1;
  display: flex;
  background-color: #E0E0E0;
  padding: 0.625rem 1rem;
}
search-page .search-bar form {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
search-page .search-bar .new-query-bar {
  max-width: 1276px;
  margin: auto;
}
search-page .search-bar .new-query-bar #search-box {
  flex-grow: 2;
}
search-page .search-bar .new-query-bar #search-box input {
  font-size: 0.875rem;
  padding-left: 1rem;
  margin: 0;
}
search-page .search-bar .new-query-bar #search-help button.help > i {
  vertical-align: middle;
}
search-page .search-bar .new-query-bar #search-submit {
  flex: none;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0px;
}
search-page .search-bar .new-query-bar #search-save-query {
  margin-left: 10px;
}
search-page .search-bar .new-query-bar #search-save-query pr-dropdown .button {
  padding: 0.5rem 1rem 0.4rem;
}
search-page .search-bar .new-query-bar #search-save-query pr-dropdown *[pr-dropdown-trigger] {
  padding: 0;
  border-bottom: 0;
}
search-page .search-bar #search-help {
  flex: none;
  width: 1.875rem;
  margin-right: 15px;
}
search-page .search-bar #search-help button.help {
  color: #616161;
}
search-page .search-bar #search-help button.help > i {
  vertical-align: bottom;
}
search-page .search-bar #search-help div.help {
  position: absolute;
  margin-top: -0.25rem;
  z-index: 1060;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(33, 33, 33, 0.5);
}
search-page .search-bar #search-help div.help h6 {
  background-color: #BDBDBD;
  color: #424242;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.5rem 1rem 0.25rem 1rem;
  text-transform: uppercase;
}
search-page .search-bar #search-help div.help table {
  background-color: #FAFAFA;
  border-collapse: initial;
  color: inherit;
  padding: 0.5rem 1rem;
}
search-page .search-bar #search-help div.help table tr {
  background-color: transparent;
  color: inherit;
  padding: 0 0 0.5rem 0;
}
search-page .search-bar #search-help div.help table tr:last-child {
  padding-bottom: 0;
}
search-page .search-bar #search-help div.help table tr th, search-page .search-bar #search-help div.help table tr td {
  padding: 0;
}
search-page .search-bar #search-help div.help table th {
  background-color: transparent;
  font-weight: 400;
  line-height: 1.25rem;
  vertical-align: top;
  width: 5.5rem;
}
search-page .search-bar #search-help div.help table td kbd {
  border: none;
  background: transparent;
  color: inherit;
  display: block;
  font-size: smaller;
}
search-page #search-results-container {
  position: relative;
}
search-page #search-results-container .loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FAFAFA;
  opacity: 0.6;
  text-align: center;
  padding: 4rem;
}
search-page dl.accordion div.expando-button {
  height: 100%;
  width: 100%;
}
search-page .search-graph {
  position: relative;
}
search-page .search-graph > a {
  position: absolute;
  top: 0;
  right: 0;
}
search-page .search-graph date-range-selector .range-selector-container {
  margin-bottom: 4px;
}
search-page .search-graph .graph-top-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 12px;
  padding-top: 8px;
}
search-page .search-graph .search-graph-section {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 0;
  background-color: white;
}
search-page .search-graph search-graph {
  display: block;
  position: relative;
  padding: 0 8px;
}
search-page .search-graph search-graph svg {
  width: 100%;
  height: 100%;
}
search-page .search-graph search-graph text {
  fill: #212121;
  font-size: 60%;
}
search-page .search-graph search-graph .axis path,
search-page .search-graph search-graph .axis line {
  fill: none;
  stroke: #212121;
  shape-rendering: crispEdges;
}
search-page .search-graph search-graph .axis .tick {
  fill: #212121;
  font-size: 0.875rem;
}
search-page .search-graph search-graph .range-line {
  fill: none;
  stroke: rgba(33, 33, 33, 0.35);
  shape-rendering: crispEdges;
}
search-page .search-graph search-graph .selected-line {
  fill: none;
  stroke: #24769E;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}
search-page .search-graph search-graph .date-marker {
  position: relative;
  cursor: ew-resize;
}
search-page .search-graph search-graph .date-marker polygon {
  fill: rgba(33, 33, 33, 0.7);
  stroke: none;
}
search-page .search-graph search-graph .date-marker path.inner-edge {
  stroke: #212121;
}
search-page .search-graph search-graph .date-marker .gripper {
  stroke: #212121;
  fill: none;
  stroke-width: 1px;
}
search-page .search-graph text.marker-text {
  font-size: 0.75rem;
}
search-page .search-graph g.lines {
  position: relative;
}
search-page search-mode-switcher h5 {
  color: #616161;
}
search-page search-aggregation-table #group-by-results, search-page search-aggregation2d-table #group-by-results, search-page search-aggregation2d-compound-table #group-by-results {
  width: 100%;
}
search-page search-aggregation-table .no-data-text, search-page search-aggregation2d-table .no-data-text, search-page search-aggregation2d-compound-table .no-data-text {
  width: 100%;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}
search-page search-aggregation-table .count-table-heading, search-page search-aggregation2d-table .count-table-heading, search-page search-aggregation2d-compound-table .count-table-heading {
  width: 80%;
}
search-page search-aggregation-table td, search-page search-aggregation2d-table td, search-page search-aggregation2d-compound-table td {
  padding: 0;
  border: 1px solid #E0E0E0;
  overflow-wrap: break-word;
  text-align: center;
  line-height: 1rem;
}
search-page .lowercase-header-table th {
  text-transform: lowercase;
}
search-page search-aggregation-table td:nth-of-type(1), search-page search-aggregation2d-table td:nth-of-type(1) {
  text-align: right;
  background-color: #E0E0E0;
  border: 0px;
  border-top: 1px solid #757575;
  font-size: 0.75rem;
  font-weight: 600;
  color: #424242;
}
search-page th.row-header {
  padding-top: 0;
  padding-bottom: 0;
  text-align: right;
  background-color: #E0E0E0;
  border: 0px;
  border-top: 1px solid #757575;
  font-size: 0.75rem;
  font-weight: 600;
  color: #424242;
}
search-page search-aggregation-table td:nth-of-type(1) {
  padding: 0.125rem;
}
search-page search-aggregation-table th {
  border-bottom: 1px solid #757575;
  text-transform: none;
}
search-page search-aggregation-table th:nth-of-type(n+2) {
  border-left: 1px solid #757575;
  text-align: center;
}
search-page search-aggregation2d-view .no-results {
  padding: 0.5rem;
}
search-page search-aggregation2d-table table {
  width: 100%;
}
search-page search-aggregation2d-table tbody {
  overflow: auto;
  max-height: 31.25rem;
  table-layout: fixed;
}
search-page search-aggregation2d-table thead {
  table-layout: fixed;
}
search-page search-aggregation2d-table .table-cell {
  padding: 0.125rem;
}
search-page search-aggregation2d-table .table-cell.first-col {
  width: 9rem;
}
search-page search-aggregation2d-table .greyed-out {
  color: #E0E0E0;
}
search-page search-aggregation2d-table th {
  word-break: break-all;
  padding: 0;
  border-left: 1px solid #757575;
}
search-page search-aggregation2d-table th:nth-of-type(1) {
  border-left: none;
}
search-page search-aggregation2d-table th.term-cell {
  text-align: center;
  text-transform: none;
}
search-page search-aggregation2d-table thead:nth-of-type(2) th {
  border-top: 1px solid #757575;
}
search-page search-aggregation2d-table .paging-column {
  width: 1.25rem;
}
search-page search-aggregation2d-table .paging-column .disabled {
  color: #757575;
}
search-page search-aggregation2d-table tr:not(:first-of-type) td.no-horiz-border {
  border-top: none;
}
search-page search-aggregation2d-table tr:not(:last-of-type) td.no-horiz-border {
  border-bottom: none;
}
search-page search-aggregation2d-table tr:last-of-type td {
  border-bottom: none;
}
search-page search-aggregation2d-table td:last-of-type {
  border-right: none;
}
search-page search-aggregation2d-table ul.pagination.primary-field {
  min-height: 0.75rem;
  padding-top: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
  display: block;
}
search-page search-aggregation2d-table ul.pagination li {
  margin-left: 0px;
}
search-page .search-error-notification {
  background: #FFFFFF;
  color: #616161;
  text-align: center;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

search-results .search-standalone-button {
  margin: 0 0.3125rem 0 0.3125rem;
}

search-results-table {
  display: block;
  overflow-x: auto;
}
search-results-table pr-dropdown ul[pr-dropdown-body] {
  margin-top: 0px;
}
search-results-table .timestamp {
  width: 200px;
  text-align: center;
}
search-results-table .chevron {
  width: 38px;
  padding: 0 16px;
}
search-results-table .selector {
  width: 36px;
}
search-results-table .custom-column {
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
search-results-table .sim-diff-separator-column {
  width: 0.5rem;
  text-align: center;
  padding-left: 0.0625rem;
  padding-right: 0.0625rem;
}
search-results-table .sim-diff-column {
  width: 2.25rem;
  overflow: hidden;
  text-align: center;
  padding-left: 0.0625rem;
  padding-right: 0.0625rem;
  padding-top: 0px;
  padding-bottom: 0px;
}
search-results-table .message-full-width {
  width: 100%;
}
search-results-table .message-fixed-width {
  width: 30rem;
}
search-results-table #search-results {
  table-layout: fixed;
}
search-results-table #search-results .similar-field-value-row {
  background: #BDBDBD;
}
search-results-table #search-results .different-field-value-row {
  background: #2F9ACD;
}
search-results-table #search-results .similar-fields-count-button {
  background: #BDBDBD;
  min-width: 1.45rem;
  text-align: center;
}
search-results-table #search-results .similar-fields-count-button:hover {
  background: #616161;
  color: #FFFFFF;
}
search-results-table #search-results .different-fields-count-button {
  background: #2F9ACD;
  min-width: 1.45rem;
  text-align: center;
}
search-results-table #search-results .different-fields-count-button:hover {
  background: #24769E;
  color: #FFFFFF;
}
search-results-table #search-results i {
  cursor: pointer;
}
search-results-table #search-results td.empty-data {
  width: 0;
}
search-results-table #search-results .search-result-details {
  padding: 0;
}
search-results-table #search-results .search-result-details table.results {
  border: revert;
  width: 100%;
}
search-results-table #search-results .search-result-details table.results .hover-tab {
  width: 4px;
  min-width: 4px;
}
search-results-table #search-results .search-result-details table.results .hover-tab:after, search-results-table #search-results .search-result-details table.results .hover-tab:before {
  border: revert;
}
search-results-table #search-results .search-result-details table.results tr td {
  border-top: revert;
}
search-results-table #search-results .search-result-details table.results tr td.data {
  width: 75%;
  word-break: break-all;
  word-break: break-word;
}
search-results-table #search-results .search-result-details table.results tr:last-child td {
  border-bottom: revert;
}
search-results-table #search-results tr.closed td.message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
search-results-table #search-results .expandable-field-column {
  vertical-align: top;
}
search-results-table #search-results .expandable-value-item i.hover-icon {
  color: transparent;
}
search-results-table #search-results .expandable-value-item:hover i.hover-icon {
  color: #2F9ACD;
}
search-results-table #search-results .expandable-value-item:not(:last-child) {
  margin-bottom: 8px;
}

.search-hint {
  padding: 0.5rem 0 0 1rem;
}

.saved-search-edit {
  position: relative;
}
.saved-search-edit .search-query-original-string {
  border: 1px solid #616161;
  padding: 8px;
  color: #616161;
  cursor: default;
  font-size: 0.875rem;
}
.saved-search-edit .legacy-saved-query-icon {
  position: absolute;
  top: 24px;
  left: 3px;
  font-size: 1rem;
}
.saved-search-edit .saved-search-name {
  text-indent: 1.5rem;
}

.query-selector-autocomplete-panel {
  max-height: 700px !important;
}
.query-selector-autocomplete-panel.mat-mdc-autocomplete-panel {
  background: #FAFAFA;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup {
  --mat-optgroup-label-text-size: 0.75rem;
  --mat-optgroup-label-text-color: #616161;
  --mat-optgroup-label-text-weight: 600;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid #BDBDBD;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup .mat-mdc-optgroup-label {
  padding: 0;
  min-height: unset;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup .mat-mdc-option {
  --mat-option-hover-state-layer-color: #F5F5F5;
  --mat-option-label-text-color: #212121;
  --mat-option-label-text-size: 0.85rem;
  --mat-option-label-text-line-height: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: 0;
}
.query-selector-autocomplete-panel .mat-mdc-optgroup .mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

saved-queries-list table > tbody > tr > td {
  max-width: 600px;
}

.saved-query-modal-overlay-panel .mdc-dialog__surface {
  overflow: visible;
}
.saved-query-modal-overlay-panel pr-dropdown {
  font-weight: 400;
}

add-data-restores-modal .date-range-picker-container {
  position: relative;
  box-shadow: unset;
  z-index: unset;
  transform: unset;
  margin: 0;
}
add-data-restores-modal .date-range-picker-container .calendar-header {
  margin: 0 20px;
}
add-data-restores-modal .date-range-picker-container .next-button {
  margin-right: 8px;
}

search-page .applied-filters {
  border: 1px solid #E0E0E0;
  margin: 0.5rem 0 0.125rem 0;
  padding: 0 0.1875rem 0.1875rem 0.1875rem;
}
search-page .filters {
  padding: 0;
}
search-page .filters h4 {
  padding: 0.25rem 0.5rem;
  background-color: #E0E0E0;
  color: #424242;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;
  margin-bottom: 0.125rem;
}
search-page .filters h4 span {
  font-weight: 400;
  text-transform: none;
}
search-page search-filter-selector .filter-term:hover {
  background-color: #BDBDBD;
}

.ticket-view {
  font-size: 0.875rem;
}
@media print {
  .ticket-view button, .ticket-view input, .ticket-view select, .ticket-view pr-dropdown, .ticket-view edit-mode, .ticket-view .filedrop, .ticket-view .return-to-top-footer {
    display: none;
  }
  .ticket-view a {
    text-decoration: none;
  }
  .ticket-view a[href]:after {
    content: none !important;
  }
  .ticket-view .fixed-page-container, .ticket-view .fixed-scrolling-content {
    overflow-y: visible;
  }
  .ticket-view .long-cell {
    width: 20rem;
  }
  .ticket-view .medium-cell {
    width: 12rem;
  }
  .ticket-view .timestamp > timestamp-dropdown > pr-dropdown {
    display: block;
  }
  .ticket-view .timestamp > timestamp-dropdown > pr-dropdown i {
    display: none;
  }
}
.ticket-view .fixed-page-container {
  position: relative;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0.1875rem 0.5rem;
  overflow: visible;
}
.ticket-view .fixed-page-container .fixed-right-content {
  position: relative;
  right: -1.375rem;
  top: 0.625rem;
  min-width: 20.3125rem;
}
.ticket-view .fixed-page-container .fixed-scrolling-content {
  flex-grow: 1;
  position: relative;
  right: 20.75rem;
  left: 0.3125rem;
  top: 0.625rem;
  height: 79vh;
  overflow-y: scroll;
}
@media print {
  .ticket-view .fixed-page-container .fixed-scrolling-content {
    height: unset;
  }
}
.ticket-view .fixed-page-container .fixed-scrolling-content .form-footer-space {
  margin-bottom: 6.25rem;
}
.ticket-view .fixed-page-container .fixed-scrolling-content .fixed-footer-notification-space {
  margin-bottom: 11.25rem;
}
.ticket-view #trigger-content-container {
  width: 98%;
  display: flex;
  align-items: flex-start;
  right: 0;
  left: 2.75rem;
  overflow-y: visible;
}
@media print {
  .ticket-view #trigger-content-container {
    display: unset;
    width: unset;
    position: relative;
    left: 0;
    overflow-y: initial;
  }
}
.ticket-view button.inline-add {
  color: #2F9ACD;
  font-size: 0.875rem;
  padding: 0;
}
.ticket-view button.inline-add.with-padding {
  padding-top: 1rem;
}
.ticket-view button.inline-add i {
  font-size: 0.875rem;
  padding-right: 0.1875rem;
}
.ticket-view .f-dropdown li {
  color: #212121;
}
.ticket-view #center-panel {
  padding: 0;
}
@media print {
  .ticket-view #center-panel {
    position: relative;
  }
  .ticket-view #center-panel .fixed-size-table tbody {
    max-height: unset;
  }
}
.ticket-view #center-panel article {
  margin: 0 16px;
  padding-bottom: 16px;
}
.ticket-view #center-panel article.horizontally-scrollable {
  overflow-x: auto;
}
.ticket-view #center-panel a.object-link {
  font-weight: 500;
  color: #212121;
}
.ticket-view #center-panel a.object-link:hover {
  color: #2F9ACD;
}
.ticket-view #center-panel button.inline-delete {
  padding: 0 0.25rem;
  color: #DF5146;
}
.ticket-view #center-panel button.inline-delete i {
  vertical-align: middle;
}
.ticket-view #center-panel table#ticket-files, .ticket-view #center-panel table#ticket-links, .ticket-view #center-panel table#ticket-policies {
  border: none;
  width: 100%;
}
.ticket-view #center-panel table#ticket-files:hover button.inline-delete i, .ticket-view #center-panel table#ticket-links:hover button.inline-delete i, .ticket-view #center-panel table#ticket-policies:hover button.inline-delete i {
  visibility: visible;
}
.ticket-view #center-panel table#ticket-files th, .ticket-view #center-panel table#ticket-files td, .ticket-view #center-panel table#ticket-links th, .ticket-view #center-panel table#ticket-links td, .ticket-view #center-panel table#ticket-policies th, .ticket-view #center-panel table#ticket-policies td {
  padding: 0 0 1rem 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
  vertical-align: top;
}
.ticket-view #center-panel table#ticket-files th, .ticket-view #center-panel table#ticket-links th, .ticket-view #center-panel table#ticket-policies th {
  padding-right: 1rem;
  white-space: nowrap;
  font-weight: 500;
  color: #616161;
}
.ticket-view #center-panel table#ticket-files td, .ticket-view #center-panel table#ticket-links td, .ticket-view #center-panel table#ticket-policies td {
  white-space: normal;
}
.ticket-view #center-panel table#ticket-files button.inline-delete, .ticket-view #center-panel table#ticket-links button.inline-delete, .ticket-view #center-panel table#ticket-policies button.inline-delete {
  margin-left: 0.75rem;
  line-height: 1.125rem;
}
.ticket-view #center-panel table#ticket-files tr:last-of-type > * {
  padding-bottom: 0;
}
.ticket-view #center-panel table#ticket-files {
  margin-top: 1.25rem;
}
.ticket-view #center-panel .center-row-flex {
  display: flex;
  flex-direction: row;
}
.ticket-view #center-panel .center-row-flex dt {
  float: left;
  padding-right: 10px;
  flex: 15%;
}
.ticket-view #center-panel .center-row-flex dd {
  flex: 85%;
  font-size: 0.75rem;
  font-weight: 500;
  padding-top: 0.6rem;
}
.ticket-view #center-panel .center-row-flex dd .sub-item {
  font-size: 0.75rem;
}
.ticket-view #center-panel .center-row-flex dd button {
  position: absolute;
  padding: 0 0 0 0.3125rem;
}
.ticket-view #center-panel .center-row-flex dd button i {
  opacity: 0;
}
.ticket-view #center-panel .center-row-flex dd:hover button i {
  opacity: 1;
}
.ticket-view #center-panel .section-margins {
  margin: 0 16px 32px 16px;
}
.ticket-view #right-panel {
  padding: 1.5rem 0 1rem 0;
}
@media print {
  .ticket-view #right-panel {
    position: relative;
    margin-left: 0.7rem;
  }
}
.ticket-view #right-panel h3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.ticket-view #right-panel .row-flex {
  display: flex;
  flex-direction: row;
}
.ticket-view #right-panel .row-flex dt, .ticket-view #right-panel .row-flex dd {
  padding: 0.25rem 0;
  line-height: 1.5rem;
  font-size: 0.875rem;
}
.ticket-view #right-panel .row-flex dt {
  flex: 25%;
  margin: 0 1rem 0 1.875rem;
  font-weight: 500;
  color: #616161;
}
.ticket-view #right-panel .row-flex dd {
  flex: 75%;
  max-width: 75%;
  padding-right: 16px;
  font-weight: 400;
  color: #212121;
}
.ticket-view #right-panel .row-flex label, .ticket-view #right-panel .row-flex ticket-add-edit-modal dt, ticket-add-edit-modal .ticket-view #right-panel .row-flex dt, .ticket-view #right-panel .row-flex add-edit-ticket-modal dt, add-edit-ticket-modal .ticket-view #right-panel .row-flex dt {
  margin: 1.25rem 0 0 0;
  padding: 0;
  border: none;
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  color: #757575;
}
.ticket-view #right-panel .row-flex span.normal-text {
  font-weight: 400;
  color: #212121;
}
.ticket-view #right-panel .row-flex .threat-badge, .ticket-view #right-panel .row-flex .due-date-badge, .ticket-view #right-panel .row-flex .ticket-state-badge {
  margin: 0;
}
.ticket-view #right-panel button.inline-edit, .ticket-view #right-panel button.inline-delete {
  padding: 0 0.25rem;
  line-height: 1.5rem;
}
.ticket-view #right-panel button.inline-edit {
  color: #2F9ACD;
}
.ticket-view #right-panel button.inline-delete {
  color: #DF5146;
}
.ticket-view #right-panel .row-flex:hover button.inline-edit i {
  visibility: visible;
}
.ticket-view #right-panel .row-flex:hover button.inline-delete i {
  visibility: visible;
}
.ticket-view #right-panel .severity-badge {
  margin: 0;
}
.ticket-view #right-panel .severity-badge:not(.rating-HIGH):not(.rating-MEDIUM) {
  border-color: #757575;
}
.ticket-view #right-panel trigger-name {
  color: #2F9ACD;
}
.ticket-view #right-panel textarea {
  resize: vertical;
}
.ticket-view #right-panel .watcher-display {
  font-size: 0.875rem;
}
.ticket-view #right-panel .watcher-title {
  font-weight: 500;
}
.ticket-view dt {
  padding-left: 0.625rem;
  padding-top: 0.625rem;
}
.ticket-view .ticket-log dt {
  padding-top: 0;
  padding-left: 0;
}
.ticket-view ul.history-log > li {
  position: relative;
  border-radius: 0.125rem;
}
.ticket-view ul.history-log > li .name-date-label {
  font-size: smaller;
  font-weight: 500;
}
.ticket-view ul.history-log > li blockquote {
  margin-bottom: 0;
  border-left: 0px;
}
@media print {
  .ticket-view ul.history-log > li blockquote {
    border: 0px;
  }
}
.ticket-view ul.history-log > li dt :not(:first-of-type) {
  margin-top: 1rem;
}
.ticket-view ul.history-log > li dd {
  margin: 0;
}
.ticket-view ul.history-log > li dd i {
  display: none;
}
.ticket-view ul.history-log > li dd i.edit-note {
  display: inline-block;
}
.ticket-view ul.history-log > li dd.ticket-link a {
  padding-left: 1rem;
}
.ticket-view ul.history-log > li #ticket-policies {
  background-color: inherit;
}
.ticket-view ul.history-log > li .sub-note {
  border-left: 2px solid #FAFAFA;
  padding: 0.25rem;
  padding-right: 0;
  margin-bottom: 0.5rem;
  margin-left: 0.5625rem;
  min-height: 1.1875rem;
}
.ticket-view .more-button {
  margin-bottom: 0.5rem;
}
@media print {
  .ticket-view div > form[name=ticketFileForm] {
    display: none;
  }
}

ticket-searches .limit-results-height {
  max-height: 12.5rem;
}
ticket-searches .query-row {
  display: flex;
  margin-bottom: 1rem;
}

generate-ticket-report-modal .parameter-label {
  margin: 0;
}
generate-ticket-report-modal .sub-header {
  margin-left: 0;
  margin-top: 0;
}
generate-ticket-report-modal p {
  margin: 0.625rem 0;
  font-size: 0.875rem;
}

.grouped-table {
  background: transparent;
}
.grouped-table tbody td {
  border-top: unset;
}
.grouped-table tbody.group-spacer tr {
  background-color: transparent;
}
.grouped-table tbody.group-spacer td {
  padding: 5px;
}
.grouped-table tbody.body-group {
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.25);
}

task-schedule .task-schedule-interval, task-schedule a {
  color: #2F9ACD;
}

due-date-badge .due-date-badge {
  border-color: #757575;
  background-color: #FFFFFF;
}
due-date-badge .due-date-badge.is-closed {
  background-color: #BDBDBD;
}
due-date-badge .due-date-badge.is-overdue {
  border-color: #DF5146;
  background-color: #DF5146;
  color: #FFFFFF;
}
due-date-badge .due-date-badge.was-overdue {
  border-color: #F5C9C6;
  background-color: #EEA19B;
  color: #FFFFFF;
}

ticket-state-badge .ticket-state-badge {
  border-color: #BDBDBD;
  background-color: #FAFAFA;
}
ticket-state-badge .ticket-state-badge.is-closed {
  background-color: #E0E0E0;
}

ticket-list-page-grouped-list tr .subtasks-badge,
ticket-list-page-default-list tr .subtasks-badge,
ticket-list-page-due-date-list tr .subtasks-badge,
task-schedule-list-page-grouped-list tr .subtasks-badge,
task-schedule-list-page-default-list tr .subtasks-badge,
library-list-page-grouped-list tr .subtasks-badge,
parent-and-subtasks tr .subtasks-badge,
parent-and-subschedules tr .subtasks-badge {
  border-color: #BDBDBD;
  background-color: #FAFAFA;
}
ticket-list-page-grouped-list tr .subtasks-badge i,
ticket-list-page-default-list tr .subtasks-badge i,
ticket-list-page-due-date-list tr .subtasks-badge i,
task-schedule-list-page-grouped-list tr .subtasks-badge i,
task-schedule-list-page-default-list tr .subtasks-badge i,
library-list-page-grouped-list tr .subtasks-badge i,
parent-and-subtasks tr .subtasks-badge i,
parent-and-subschedules tr .subtasks-badge i {
  color: #2F9ACD;
}
ticket-list-page-grouped-list tr th.summary-column,
ticket-list-page-default-list tr th.summary-column,
ticket-list-page-due-date-list tr th.summary-column,
task-schedule-list-page-grouped-list tr th.summary-column,
task-schedule-list-page-default-list tr th.summary-column,
library-list-page-grouped-list tr th.summary-column,
parent-and-subtasks tr th.summary-column,
parent-and-subschedules tr th.summary-column {
  min-width: 300px;
}
ticket-list-page-grouped-list tr td.summary-column .children-indicator, ticket-list-page-grouped-list tr td.summary-column .no-children-indicator,
ticket-list-page-default-list tr td.summary-column .children-indicator,
ticket-list-page-default-list tr td.summary-column .no-children-indicator,
ticket-list-page-due-date-list tr td.summary-column .children-indicator,
ticket-list-page-due-date-list tr td.summary-column .no-children-indicator,
task-schedule-list-page-grouped-list tr td.summary-column .children-indicator,
task-schedule-list-page-grouped-list tr td.summary-column .no-children-indicator,
task-schedule-list-page-default-list tr td.summary-column .children-indicator,
task-schedule-list-page-default-list tr td.summary-column .no-children-indicator,
library-list-page-grouped-list tr td.summary-column .children-indicator,
library-list-page-grouped-list tr td.summary-column .no-children-indicator,
parent-and-subtasks tr td.summary-column .children-indicator,
parent-and-subtasks .hover-table tr td.summary-column .spacer-children-indicator,
parent-and-subtasks .grouped-table tr td.summary-column .spacer-children-indicator,
parent-and-subtasks tr td.summary-column .no-children-indicator,
parent-and-subschedules tr td.summary-column .children-indicator,
parent-and-subschedules .hover-table tr td.summary-column .spacer-children-indicator,
parent-and-subschedules .grouped-table tr td.summary-column .spacer-children-indicator,
parent-and-subschedules tr td.summary-column .no-children-indicator {
  display: inline-block;
  align-self: center;
  width: 5px;
  height: 27px;
  min-width: 5px;
  margin: 0 6px;
  background-color: #2F9ACD;
}
ticket-list-page-grouped-list tr td.summary-column .no-children-indicator,
ticket-list-page-default-list tr td.summary-column .no-children-indicator,
ticket-list-page-due-date-list tr td.summary-column .no-children-indicator,
task-schedule-list-page-grouped-list tr td.summary-column .no-children-indicator,
task-schedule-list-page-default-list tr td.summary-column .no-children-indicator,
library-list-page-grouped-list tr td.summary-column .no-children-indicator,
parent-and-subtasks tr td.summary-column .no-children-indicator,
parent-and-subschedules tr td.summary-column .no-children-indicator {
  background-color: #BDBDBD;
}
ticket-list-page-grouped-list tr td.summary-column .summary-container,
ticket-list-page-default-list tr td.summary-column .summary-container,
ticket-list-page-due-date-list tr td.summary-column .summary-container,
task-schedule-list-page-grouped-list tr td.summary-column .summary-container,
task-schedule-list-page-default-list tr td.summary-column .summary-container,
library-list-page-grouped-list tr td.summary-column .summary-container,
parent-and-subtasks tr td.summary-column .summary-container,
parent-and-subschedules tr td.summary-column .summary-container {
  display: flex;
}
ticket-list-page-grouped-list tr td.summary-column .summary-container .fa-fw,
ticket-list-page-default-list tr td.summary-column .summary-container .fa-fw,
ticket-list-page-due-date-list tr td.summary-column .summary-container .fa-fw,
task-schedule-list-page-grouped-list tr td.summary-column .summary-container .fa-fw,
task-schedule-list-page-default-list tr td.summary-column .summary-container .fa-fw,
library-list-page-grouped-list tr td.summary-column .summary-container .fa-fw,
parent-and-subtasks tr td.summary-column .summary-container .fa-fw,
parent-and-subschedules tr td.summary-column .summary-container .fa-fw {
  flex-shrink: 0;
}
ticket-list-page-grouped-list tr td.summary-column .children-expander,
ticket-list-page-default-list tr td.summary-column .children-expander,
ticket-list-page-due-date-list tr td.summary-column .children-expander,
task-schedule-list-page-grouped-list tr td.summary-column .children-expander,
task-schedule-list-page-default-list tr td.summary-column .children-expander,
library-list-page-grouped-list tr td.summary-column .children-expander,
parent-and-subtasks tr td.summary-column .children-expander,
parent-and-subschedules tr td.summary-column .children-expander {
  align-self: center;
  color: #424242;
}
ticket-list-page-grouped-list tr td.summary-column .children-indentation,
ticket-list-page-default-list tr td.summary-column .children-indentation,
ticket-list-page-due-date-list tr td.summary-column .children-indentation,
task-schedule-list-page-grouped-list tr td.summary-column .children-indentation,
task-schedule-list-page-default-list tr td.summary-column .children-indentation,
library-list-page-grouped-list tr td.summary-column .children-indentation,
parent-and-subtasks tr td.summary-column .children-indentation,
parent-and-subschedules tr td.summary-column .children-indentation {
  width: 20px;
  height: 20px;
  min-width: 20px;
}
ticket-list-page-grouped-list tr.grayed-out,
ticket-list-page-default-list tr.grayed-out,
ticket-list-page-due-date-list tr.grayed-out,
task-schedule-list-page-grouped-list tr.grayed-out,
task-schedule-list-page-default-list tr.grayed-out,
library-list-page-grouped-list tr.grayed-out,
parent-and-subtasks tr.grayed-out,
parent-and-subschedules tr.grayed-out {
  color: #757575;
  background-color: #FAFAFA;
}
ticket-list-page-grouped-list tr.grayed-out .task-title, ticket-list-page-grouped-list tr.grayed-out task-schedule a,
ticket-list-page-default-list tr.grayed-out .task-title,
ticket-list-page-default-list tr.grayed-out task-schedule a,
ticket-list-page-due-date-list tr.grayed-out .task-title,
ticket-list-page-due-date-list tr.grayed-out task-schedule a,
task-schedule-list-page-grouped-list tr.grayed-out .task-title,
task-schedule-list-page-grouped-list tr.grayed-out task-schedule a,
task-schedule-list-page-default-list tr.grayed-out .task-title,
task-schedule-list-page-default-list tr.grayed-out task-schedule a,
library-list-page-grouped-list tr.grayed-out .task-title,
library-list-page-grouped-list tr.grayed-out task-schedule a,
parent-and-subtasks tr.grayed-out .task-title,
parent-and-subtasks tr.grayed-out task-schedule a,
parent-and-subschedules tr.grayed-out .task-title,
parent-and-subschedules tr.grayed-out task-schedule a {
  color: #757575;
}
ticket-list-page-grouped-list tr.grayed-out .subtasks-badge i,
ticket-list-page-default-list tr.grayed-out .subtasks-badge i,
ticket-list-page-due-date-list tr.grayed-out .subtasks-badge i,
task-schedule-list-page-grouped-list tr.grayed-out .subtasks-badge i,
task-schedule-list-page-default-list tr.grayed-out .subtasks-badge i,
library-list-page-grouped-list tr.grayed-out .subtasks-badge i,
parent-and-subtasks tr.grayed-out .subtasks-badge i,
parent-and-subschedules tr.grayed-out .subtasks-badge i {
  color: unset;
}
ticket-list-page-grouped-list tr.grayed-out .summary-column .children-expander,
ticket-list-page-default-list tr.grayed-out .summary-column .children-expander,
ticket-list-page-due-date-list tr.grayed-out .summary-column .children-expander,
task-schedule-list-page-grouped-list tr.grayed-out .summary-column .children-expander,
task-schedule-list-page-default-list tr.grayed-out .summary-column .children-expander,
library-list-page-grouped-list tr.grayed-out .summary-column .children-expander,
parent-and-subtasks tr.grayed-out .summary-column .children-expander,
parent-and-subschedules tr.grayed-out .summary-column .children-expander {
  color: #757575;
}
ticket-list-page-grouped-list tr.grayed-out .summary-column .children-indicator, ticket-list-page-grouped-list tr.grayed-out td.summary-column .no-children-indicator,
ticket-list-page-default-list tr.grayed-out .summary-column .children-indicator,
ticket-list-page-default-list tr.grayed-out td.summary-column .no-children-indicator,
ticket-list-page-due-date-list tr.grayed-out .summary-column .children-indicator,
ticket-list-page-due-date-list tr.grayed-out td.summary-column .no-children-indicator,
task-schedule-list-page-grouped-list tr.grayed-out .summary-column .children-indicator,
task-schedule-list-page-grouped-list tr.grayed-out td.summary-column .no-children-indicator,
task-schedule-list-page-default-list tr.grayed-out .summary-column .children-indicator,
task-schedule-list-page-default-list tr.grayed-out td.summary-column .no-children-indicator,
library-list-page-grouped-list tr.grayed-out .summary-column .children-indicator,
library-list-page-grouped-list tr.grayed-out td.summary-column .no-children-indicator,
parent-and-subtasks tr.grayed-out .summary-column .children-indicator,
parent-and-subtasks .hover-table tr.grayed-out td.summary-column .spacer-children-indicator,
parent-and-subtasks .grouped-table tr.grayed-out td.summary-column .spacer-children-indicator,
parent-and-subtasks tr.grayed-out td.summary-column .no-children-indicator,
parent-and-subschedules tr.grayed-out .summary-column .children-indicator,
parent-and-subschedules .hover-table tr.grayed-out td.summary-column .spacer-children-indicator,
parent-and-subschedules .grouped-table tr.grayed-out td.summary-column .spacer-children-indicator,
parent-and-subschedules tr.grayed-out td.summary-column .no-children-indicator {
  background-color: #BDBDBD;
}

ticket-list-page-grouped-list tr .summary-column {
  padding-left: 0;
}
ticket-list-page-grouped-list tr .task-title {
  vertical-align: top;
  display: inline-block;
}
ticket-list-page-grouped-list tr .ticket-select-icon {
  color: #757575;
}
ticket-list-page-grouped-list tr .ticket-select-icon .fa-square-check {
  color: #212121;
}
ticket-list-page-grouped-list tr.grayed-out .ticket-state-badge:not(.is-closed) {
  border-color: #BDBDBD;
  background-color: #FAFAFA;
}
ticket-list-page-grouped-list tr.grayed-out .due-date-badge:not(.is-overdue) {
  border-color: #BDBDBD;
  background-color: #FAFAFA;
}
ticket-list-page-grouped-list tr.grayed-out .due-date-badge.is-overdue,
ticket-list-page-grouped-list tr.grayed-out .due-date-badge.was-overdue {
  border-color: #F5C9C6;
  background-color: #EEA19B;
}

ticket-list-page-due-date-list .due-date-group-label {
  color: #000000;
  font-weight: 500;
  padding-top: 20px;
  font-size: large;
}
ticket-list-page-due-date-list .due-date-group-color {
  background-color: #FAFAFA;
}
ticket-list-page-due-date-list .due-date-title {
  display: inline-block;
}
ticket-list-page-due-date-list .due-date-view-all {
  padding-right: 15px;
}

parent-and-subtasks .task-title,
parent-and-subschedules .task-title {
  vertical-align: top;
  display: inline-block;
}
parent-and-subtasks .self-indicator,
parent-and-subschedules .self-indicator {
  color: #2F9ACD;
  align-self: center;
}
parent-and-subtasks .double-children-indentation,
parent-and-subschedules .double-children-indentation {
  width: 40px;
  height: 20px;
  min-width: 40px;
}
parent-and-subtasks .hover-table tr td.summary-column .spacer-children-indicator, parent-and-subtasks .grouped-table tr td.summary-column .spacer-children-indicator,
parent-and-subschedules .hover-table tr td.summary-column .spacer-children-indicator,
parent-and-subschedules .grouped-table tr td.summary-column .spacer-children-indicator {
  background-color: transparent;
}
parent-and-subtasks .hover-table tr td.schedule-cell, parent-and-subtasks .grouped-table tr td.schedule-cell,
parent-and-subschedules .hover-table tr td.schedule-cell,
parent-and-subschedules .grouped-table tr td.schedule-cell {
  width: 9.375rem;
}
parent-and-subtasks .hover-table tr td .de-emphasize p, parent-and-subtasks .grouped-table tr td .de-emphasize p,
parent-and-subschedules .hover-table tr td .de-emphasize p,
parent-and-subschedules .grouped-table tr td .de-emphasize p {
  font-size: unset;
}
parent-and-subtasks .hover-table tr.no-bottom-border td, parent-and-subtasks .grouped-table tr.no-bottom-border td,
parent-and-subschedules .hover-table tr.no-bottom-border td,
parent-and-subschedules .grouped-table tr.no-bottom-border td {
  border-bottom: unset;
}
parent-and-subtasks .hover-table tr.summary-note-row td, parent-and-subtasks .grouped-table tr.summary-note-row td,
parent-and-subschedules .hover-table tr.summary-note-row td,
parent-and-subschedules .grouped-table tr.summary-note-row td {
  border-top: unset;
  padding-top: 0;
  padding-bottom: 0;
}
parent-and-subtasks pr-dropdown a[pr-dropdown-trigger],
parent-and-subschedules pr-dropdown a[pr-dropdown-trigger] {
  color: #757575;
}
parent-and-subtasks pr-dropdown a[pr-dropdown-trigger]:hover, parent-and-subtasks pr-dropdown a[pr-dropdown-trigger].active,
parent-and-subschedules pr-dropdown a[pr-dropdown-trigger]:hover,
parent-and-subschedules pr-dropdown a[pr-dropdown-trigger].active {
  color: #2F9ACD;
}
parent-and-subtasks tr.dimmed-row,
parent-and-subschedules tr.dimmed-row {
  background-color: #FAFAFA;
}

ticket-add-edit-modal .due-date-badge, add-edit-ticket-modal .due-date-badge {
  margin: 0;
}

library-list-page-grouped-list tr .summary-column {
  padding-left: 0;
}
library-list-page-grouped-list .copied-to-org {
  color: #8BB741;
  font-size: inherit;
}

.filter-label-container {
  display: flex;
  align-items: center;
}
.filter-label-container .filter-label {
  color: white;
  background-color: #616161;
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 600;
}

ticket-watcher-select .watcher-display {
  font-weight: 400;
  color: #212121;
}

ticket-history .action-buttons {
  margin-top: 0.25rem;
}

ticket-log .note-container {
  display: flex;
  flex-direction: column;
}
ticket-log .add-reply-button {
  text-align: right;
}
ticket-log .action-buttons {
  margin-top: 0.25rem;
}

similar-incident svg-icon.bar-icon .mat-icon, incident-similarity-modal svg-icon.bar-icon .mat-icon {
  height: 8px;
  line-height: 0.25rem;
  width: 20px;
  margin-right: 4px;
  cursor: pointer;
}
similar-incident .incident-text, incident-similarity-modal .incident-text {
  margin-top: 4px;
  font-weight: 500;
  line-height: 1rem;
  color: #424242;
}
similar-incident .incident-text:hover, incident-similarity-modal .incident-text:hover {
  color: #2F9ACD;
}

incident-preview similar-incident-icons svg-icon.bar-icon .mat-icon {
  height: 12px;
  width: 20px;
  line-height: 0.5rem;
  cursor: pointer;
  fill: #757575;
}

ticket-due-date ds-button button.blue-link {
  padding-top: 0;
}

.action-header.action-group {
  justify-content: space-between;
}
.action-header.action-group > nav {
  height: var(--nav-top-bar-height, 5.75rem);
  margin: 0.5rem 2.5rem 0.25rem 0.25rem;
}
.action-header.action-group > nav .top-bar-section > ul > li {
  background: none;
}
.action-header.action-group > nav .top-bar-section > ul > li:not(:last-child) {
  margin-right: 1rem;
}
.action-header.action-group > nav .top-bar-section > ul > li:hover > a {
  background: none;
}
.action-header.action-group > nav .top-bar-section > ul > li > a {
  padding: 0 !important;
  font-size: 0.9375rem;
  line-height: 2.1875rem !important;
  margin-top: var(--nav-top-bar-height, 5.75rem)-2.1875rem;
}
.action-header.action-group > nav .top-bar-section > ul > li.nav-top-item-main > a, .action-header.action-group > nav .top-bar-section > ul > li .nav-top-item-main-text, .action-header.action-group > nav .top-bar-section > ul > li .main-item-no-link {
  font-size: 1.75rem;
  line-height: 3rem;
  margin-top: var(--nav-top-bar-height, 5.75rem)-3rem;
  font-weight: 600;
}
.action-header.action-group > nav .top-bar-section > ul > li.active > a, .action-header.action-group > nav .top-bar-section > ul > li.not-click > a, .action-header.action-group > nav .top-bar-section > ul > li > a:hover {
  position: relative;
}
.action-header.action-group > nav .top-bar-section > ul > li.active > a:before, .action-header.action-group > nav .top-bar-section > ul > li.not-click > a:before, .action-header.action-group > nav .top-bar-section > ul > li > a:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.25rem;
}
.action-header.action-group > nav .top-bar-section > ul > li.active:not(.nav-top-item-main) > a {
  font-weight: 500;
}
.action-header.action-group > nav .top-bar-section.nav-section-main > ul > li.active > a:before, .action-header.action-group > nav .top-bar-section.nav-section-main > ul > li.not-click > a:before, .action-header.action-group > nav .top-bar-section.nav-section-main > ul > li > a:hover:before {
  background-color: #2F9ACD;
}

query-finder .search-query-results {
  max-height: 380px;
}

saved-query-modal .pr-dropdown-button:hover:not(.button-dropdown, .link-dropdown), saved-query-modal pr-dropdown [pr-dropdown-trigger]:hover:not(.button-dropdown, .link-dropdown), pr-dropdown saved-query-modal [pr-dropdown-trigger]:hover:not(.button-dropdown, .link-dropdown), saved-query-modal .pr-dropdown-button.active:not(.button-dropdown, .link-dropdown), saved-query-modal pr-dropdown .active[pr-dropdown-trigger]:not(.button-dropdown, .link-dropdown), pr-dropdown saved-query-modal .active[pr-dropdown-trigger]:not(.button-dropdown, .link-dropdown), saved-query-modal .pr-dropdown-button:focus:not(.button-dropdown, .link-dropdown), saved-query-modal pr-dropdown [pr-dropdown-trigger]:focus:not(.button-dropdown, .link-dropdown), pr-dropdown saved-query-modal [pr-dropdown-trigger]:focus:not(.button-dropdown, .link-dropdown) {
  border-bottom: none;
}
saved-query-modal modal-buttons #cancel-button button.cancel {
  color: #2F9ACD;
}
saved-query-modal modal-buttons #close-button button.cancel {
  color: #2F9ACD;
}
saved-query-modal query-finder .search-query-results {
  max-height: 410px;
}

ticket-escalation-section ds-button.manage-escalation-settings-button > button {
  padding: 0;
  justify-content: flex-end;
}
ticket-escalation-section ds-button.manage-escalation-settings-button > button i {
  font-size: 16px;
}
ticket-escalation-section ds-button.escalate-ticket-button > button {
  font-size: 14px;
  height: 26px;
}

modified-query-form query-finder .search-query-results {
  max-height: 320px;
}

saved-query-selector referenced-objects related-objects-list table:not(.mat-calendar-table) thead tr th, saved-query-selector referenced-objects related-objects-list table:not(.mat-calendar-table) thead tr td, saved-query-selector referenced-objects related-objects-list table:not(.mat-calendar-table) tfoot tr th, saved-query-selector referenced-objects related-objects-list table:not(.mat-calendar-table) tfoot tr td {
  color: #616161;
}
saved-query-selector referenced-objects related-objects-list table:not(.mat-calendar-table) tbody td.link-cell > :first-of-type {
  margin-left: 6px;
}

collapsible-trigger-description .formatted-trigger-desc > span * {
  line-height: 16px;
  font-size: 14px;
}
collapsible-trigger-description .formatted-trigger-desc > span > *, collapsible-trigger-description .formatted-trigger-desc > span > ol li {
  margin-bottom: 16px;
}

.alerts td.absolute-container {
  text-align: center;
  position: relative;
}
.alerts td.absolute-container .absolute-centered {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}

.classifiers input[type=checkbox].pr-dropdown-body {
  pointer-events: none;
}
.classifiers input[type=text], .classifiers textarea, .classifiers small {
  width: calc(100% - 2rem);
}
.classifiers .status input[type=checkbox] {
  display: none;
}
.classifiers input:not(.classifier-search-input), .classifiers select, .classifiers textarea, .classifiers small {
  margin: 0.5rem;
  display: inline-block;
}
.classifiers fieldset {
  padding: 0.5rem;
  margin: 0.5rem 0;
}
.classifiers .metadata input[type=text], .classifiers select {
  width: 15rem;
}
.classifiers ul {
  list-style: none;
  margin: 0;
}
.classifiers tag-panel {
  margin: 0;
}
.classifiers tag-panel action-buttons hr {
  display: none;
}

.temporary-for-demo-hacky-get-rid-off-border {
  margin-right: 1rem;
}
.temporary-for-demo-hacky-get-rid-off-border pr-dropdown button {
  border-bottom: none;
}

.new-alert-inbox-test {
  padding-left: 1rem;
}

alert-inbox .alert-inbox-sheet {
  position: absolute;
  top: 3.125rem;
  left: 8.4375rem;
  right: 0.3125rem;
  bottom: 0.625rem;
}
alert-inbox .alert-inbox-sheet .inbox-container {
  display: flex;
  flex-direction: column;
}
alert-inbox .alert-inbox-sheet .inbox-container .inbox-container-header-child {
  min-height: 2.1875rem;
  flex-basis: 5%;
  display: flex;
}
alert-inbox .alert-inbox-sheet .inbox-container .inbox-container-header-child .alert-sort-header {
  flex-basis: 30%;
  background-color: #FFFFFF;
}
alert-inbox .alert-inbox-sheet .inbox-container .inbox-container-header-child .alert-state-header {
  flex-basis: 70%;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
alert-inbox .alert-inbox-sheet .inbox-container .inbox-container-alerts-child {
  flex-basis: 95%;
  min-height: 95%;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container {
  display: flex;
  flex-direction: row;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .alerts-container-content-child {
  flex-basis: 70%;
  min-width: 25rem;
  padding: 0.3125rem;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .alerts-container-content-child.refresh {
  padding: 0.3125rem 0.75rem 0 1rem;
  box-shadow: inset 9px 0px 0px -5px #FAFAFA;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .alert-info-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .alert-info-content-container .dismissal-detail {
  padding-left: 0.5rem;
  padding-bottom: 0.625rem;
  display: flex;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .alerts-container-list-child {
  flex-basis: 30%;
}
alert-inbox .alert-inbox-sheet .inbox-container .alerts-container .left-alert-list-container {
  display: flex;
  flex-direction: column;
}
alert-inbox .loading-text {
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  color: #757575;
}
alert-inbox .scrolling-panel-text {
  padding: 0.625rem;
}
alert-inbox .alert-list-item {
  font-size: 0.875rem;
  border-bottom: 1px solid #E0E0E0;
  padding: 0.5rem 0 0.5rem 0.375rem;
  border-left: 0.375rem solid transparent;
}
alert-inbox .alert-list-item:hover, alert-inbox .alert-list-item.active {
  background-color: #FAFAFA;
  border-left: 0.375rem solid #2F9ACD;
}
alert-inbox .alert-list-item .alert-badge {
  margin: 0.1875rem 0.375rem 0 0;
  background-color: #FFFFFF;
  color: #757575;
  border: solid 0.0625rem #757575;
  padding-top: 0.375rem;
}
alert-inbox .info-title {
  color: #616161;
  font-size: 1rem;
  float: left;
}
alert-inbox .limit-results-height {
  max-height: 25rem;
}
alert-inbox .alert-state-info-section {
  padding-bottom: 0.75rem;
  border-left: 0.375rem solid transparent;
}
alert-inbox .alert-state-info-section:hover, alert-inbox .alert-state-info-section.active {
  border-left: 0.375rem solid #2F9ACD;
}
alert-inbox .alert-state-info-section.refresh {
  padding-bottom: 0.75rem;
  border: 1px solid #E0E0E0;
  margin-left: 0.3125rem;
  margin-bottom: 1.375rem;
}
alert-inbox .alert-state-info-section.refresh:hover, alert-inbox .alert-state-info-section.refresh.active {
  box-shadow: -0.375rem 0px 0 #2F9ACD;
  margin-left: 0.375rem;
  border-left: none;
}
alert-inbox .state-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem;
  max-height: 2.1875rem;
}
alert-inbox .state-bar alert-state-info-bar span {
  padding-left: 0.3125rem;
  display: inline-flex;
  align-items: baseline;
}
alert-inbox .state-bar .icon {
  color: #757575;
  margin: 0 0.9375rem 0 0.9375rem;
}
alert-inbox alert-state-changer .button {
  padding-top: 0;
  padding-bottom: 0;
}
alert-inbox alert-state-changer .button:hover {
  text-decoration: none;
}
alert-inbox #cause-text {
  font-weight: 500;
  color: #212121;
  margin-bottom: 0.625rem;
}
alert-inbox #alert-timestamp {
  float: right;
  text-align: right;
  font-size: 0.75rem;
  word-wrap: break-word;
}
alert-inbox .inbox-zero {
  width: 100%;
  text-align: center;
  align-self: center;
}
alert-inbox .inbox-zero .inbox-zero-header {
  font-weight: 500;
}
alert-inbox .inbox-zero #inbox-zero-img-box {
  width: 18.75rem;
  height: 12.5rem;
  display: inline-block;
}
alert-inbox .inbox-zero #inbox-zero-img-box svg {
  fill: #2F9ACD;
}
alert-inbox .trigger-graph-container {
  margin-top: 0;
}
alert-inbox hr {
  margin: 0.625rem 0;
}
alert-inbox .sort-dropdown {
  margin-left: 1.5rem;
}
alert-inbox .sort-dropdown [pr-dropdown-trigger] {
  padding-top: 0.5rem;
}

trigger-header #trigger-header {
  display: block;
  background-color: #FFFFFF;
  padding: 0.3125rem 0.5rem 0 0.25rem;
}
trigger-header .sticky {
  position: sticky;
  top: 0;
  width: 100%;
  align-items: center;
  z-index: 10;
}

single-org-alert-list dismiss-button button.secondary {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.sequence-alert-result-step-arrow {
  fill: #FAFAFA;
  line-height: 0.75rem;
  height: 0.75rem;
  text-align: left;
}

.sequence-alert-result-step-container {
  background-color: #FAFAFA;
  padding: 1rem;
}
.sequence-alert-result-step-container .step-title {
  color: #757575;
  font-size: 0.875rem;
  font-weight: 600;
  width: 7.1875rem;
  text-align: left;
}
.sequence-alert-result-step-container .step-query {
  flex: 1;
  text-align: left;
}
.sequence-alert-result-step-container .step-trigger-time {
  text-align: right;
  color: #212121;
}

.sequence-alert-result-matches {
  padding-left: 1rem;
}
.sequence-alert-result-matches .matches-title {
  color: #616161;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  margin-top: 0.75rem;
}
.sequence-alert-result-matches .sequence-alert-step-match-entry {
  margin-bottom: 0.25rem;
}
.sequence-alert-result-matches .sequence-alert-step-match-entry .match-key {
  color: #212121;
  font-size: 0.875rem;
  width: 6.25rem;
  text-align: left;
}
.sequence-alert-result-matches .sequence-alert-step-match-entry .match-key-value-split {
  font-size: 0.875rem;
  margin: 0 0.9375rem;
  font-weight: 600;
  color: #212121;
}
.sequence-alert-result-matches .sequence-alert-step-match-entry .match-value {
  color: #2F9ACD;
  font-size: 0.875rem;
  font-weight: 600;
}

single-trigger-display trigger-alerts-table .alert-table-header {
  position: sticky;
  top: 45px;
  width: 100%;
  align-items: center;
  z-index: 911;
  background-color: #FFFFFF;
  padding: 8px 0;
}
single-trigger-display trigger-alerts-table .alert-table-column-headers {
  position: sticky;
  top: 92px;
  width: 100%;
  align-items: center;
  z-index: 910;
}

trigger-alerts-table .top-border {
  border-top: 1px solid #FAFAFA;
}
trigger-alerts-table #load-more-alerts {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
trigger-alerts-table .alert-view-link, trigger-alerts-table .story-view-link {
  display: inline;
  align-items: center;
}
trigger-alerts-table .alert-view-link:hover, trigger-alerts-table .story-view-link:hover {
  text-decoration: none;
}
trigger-alerts-table .alert-view-link .fa-search, trigger-alerts-table .story-view-link .fa-search {
  padding-right: 0.5rem;
}
trigger-alerts-table .alert-view-link .fa-list, trigger-alerts-table .story-view-link .fa-list {
  padding-left: 0.5rem;
}
trigger-alerts-table .risk-factor-list {
  font-size: 0.875rem;
  margin-bottom: 0;
}
trigger-alerts-table .hover-table .timestamp-cell, trigger-alerts-table .grouped-table .timestamp-cell {
  width: 20%;
}

dismiss-form-modal #dismissal-header {
  margin-bottom: 1.5rem;
}
dismiss-form-modal textarea {
  margin-top: 1.25rem;
}

fraud-inbox-page alert-inbox-header {
  padding-left: 0.5rem;
}
fraud-inbox-page alert-inbox-header .pr-dropdown-padding {
  padding-left: 0.5rem;
}
fraud-inbox-page .inbox-main-sheet {
  display: flex;
  position: absolute;
  background-color: #FFFFFF;
  top: 3.125rem;
  left: 8.4375rem;
  right: 0.3125rem;
  bottom: 0.625rem;
}
fraud-inbox-page .inbox-main-sheet.story-mode {
  left: 1.125rem;
  right: 1.5rem;
}
fraud-inbox-page .inbox-main-sheet.alert-details-view {
  position: revert;
  margin-right: 8px;
}
fraud-inbox-page .inbox-main-sheet .inbox-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
fraud-inbox-page .inbox-left-header {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin: 0 8px;
}
fraud-inbox-page .inbox-left-header {
  background-color: #FFFFFF;
}
fraud-inbox-page .inbox-left-container {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
}
fraud-inbox-page .inbox-right-container {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  min-width: 25rem;
  box-shadow: inset 9px 0 0 -5px #FAFAFA;
}
fraud-inbox-page .inbox-right-container .inbox-right-scrollable {
  overflow-y: auto;
  height: 100%;
  padding: 0 12px 0 16px;
}
fraud-inbox-page .info-title {
  color: #616161;
  font-size: 1rem;
  float: left;
}
fraud-inbox-page .scrolling-panel {
  overflow-y: auto;
}
fraud-inbox-page .vh-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
fraud-inbox-page alert-state-changer .button {
  padding-top: 0;
  padding-bottom: 0;
}
fraud-inbox-page alert-state-changer .button:hover {
  text-decoration: none;
}

single-alert-display trigger-header #trigger-header {
  border-bottom: none;
}
single-alert-display #primary-term-container {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
single-alert-display #primary-term-container .primary-term {
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
single-alert-display search-results-table .important-field-key {
  color: #212121;
  font-weight: 500;
}
single-alert-display search-results-table search-results-table-detailed-value threat-rating pr-dropdown > span {
  height: 20px;
}

single-alert-view {
  width: 100%;
}
single-alert-view events-table search-results-table {
  margin-bottom: 16px;
}
single-alert-view trigger-header #trigger-header {
  padding: 0;
  border-bottom: none;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
single-alert-view trigger-header #trigger-header #edit-trigger {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 16px;
}
single-alert-view single-alert-view-header ds-button.hyperlink-button button {
  padding-top: 0;
  padding-bottom: 0;
  height: unset;
}
single-alert-view alert-state-changer .button {
  margin: 0 4px;
  padding: 0 4px;
}
single-alert-view alert-state-changer .button.link.secondary {
  color: #757575;
}
single-alert-view alert-state-changer .button svg-icon.flag-fraud .mat-icon {
  fill: #757575;
}
single-alert-view important-fields-container ds-dropdown.important-field-selector .trigger-container {
  border-bottom: transparent;
  color: #2F9ACD;
}
single-alert-view important-fields-container ds-dropdown.important-field-selector .trigger-container:active, single-alert-view important-fields-container ds-dropdown.important-field-selector .trigger-container:focus {
  border-bottom: transparent;
}
single-alert-view important-fields-container ds-dropdown.important-field-selector .trigger-container i {
  font-size: 0.75rem;
}
single-alert-view important-fields-container ds-dropdown.important-field-selector #ds-dropdown-trigger {
  flex-grow: unset;
}
single-alert-view .sub-header-action-container ds-button button {
  padding-right: 0;
}

.important-field-cdk-pane ul.ds-dropdown-body {
  max-height: min-content;
}
.important-field-cdk-pane ul.ds-dropdown-body li:hover {
  background-color: transparent;
}

svg-icon.flag-fraud .mat-icon {
  height: 14px;
  width: 14px;
  fill: #616161;
}

svg-icon.flag-fraud-sidebar .mat-icon {
  height: 14px;
  width: 10.5px;
  color: #000000;
  margin-bottom: -2px;
}

alert-story-mode-viewer .button-group, alert-state-changer .button-group {
  display: flex;
}
alert-story-mode-viewer .button-group li, alert-state-changer .button-group li {
  margin: 0;
}
alert-story-mode-viewer .button-group li .button.borderless, alert-state-changer .button-group li .button.borderless {
  border-color: transparent;
}
alert-story-mode-viewer .button-group .action-icon:hover, alert-state-changer .button-group .action-icon:hover {
  color: #2F9ACD;
}
alert-story-mode-viewer .button-group button .action-icon:hover, alert-state-changer .button-group button .action-icon:hover {
  color: #2F9ACD;
}
alert-story-mode-viewer .button-group button #escalated-icon, alert-state-changer .button-group button #escalated-icon {
  display: flex;
  align-items: baseline;
}
alert-story-mode-viewer .button-group button #escalated-icon:hover, alert-state-changer .button-group button #escalated-icon:hover {
  color: #2F9ACD;
}
alert-story-mode-viewer .button-group button .bulk-lines, alert-state-changer .button-group button .bulk-lines {
  display: inline-block;
  align-self: center;
  width: 0.875rem;
  margin-right: 0.1875rem;
}
alert-story-mode-viewer .button-group .disabled-button.secondary, alert-state-changer .button-group .disabled-button.secondary {
  border-color: transparent;
  cursor: not-allowed;
}
alert-story-mode-viewer .button-group .disabled-button i, alert-state-changer .button-group .disabled-button i {
  color: #757575;
}
alert-story-mode-viewer .button-group .disabled-button i:not(:focus):hover, alert-state-changer .button-group .disabled-button i:not(:focus):hover {
  color: #757575;
}
alert-story-mode-viewer .button-group #escalate-button, alert-state-changer .button-group #escalate-button {
  padding: 0 0.625rem 0 1rem;
}
alert-story-mode-viewer .button-group #escalate-button:hover, alert-state-changer .button-group #escalate-button:hover {
  text-decoration: none;
}
alert-story-mode-viewer .button-group .recent-title, alert-state-changer .button-group .recent-title {
  padding-bottom: 0.1875rem;
  font-weight: 500;
}
alert-story-mode-viewer .button-group .recent-title:hover, alert-state-changer .button-group .recent-title:hover {
  background-color: #FFFFFF;
}

alert-story-mode-viewer .inbox-left-container {
  flex-basis: 25%;
}
alert-story-mode-viewer .inbox-right-container {
  flex-basis: 75%;
  padding: 5px 16px 0 16px;
}
alert-story-mode-viewer .inbox-right-container section {
  height: 100%;
}
alert-story-mode-viewer header.inbox-left-header {
  font-size: 12px;
  font-weight: 500;
  color: #616161;
  justify-content: flex-start;
  text-align: left;
  margin: 0.5rem 0 0 1rem;
}
alert-story-mode-viewer header.content-header {
  position: absolute;
  top: -2.5rem;
  right: -1.25rem;
  border-bottom: none;
}
alert-story-mode-viewer h2 {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
alert-story-mode-viewer h2 .aggregate-title {
  color: #616161;
  margin-right: 0.25rem;
}
alert-story-mode-viewer pr-dropdown.export-dropdown *[pr-dropdown-trigger] {
  border-bottom: none;
}
alert-story-mode-viewer pr-dropdown.export-dropdown *[pr-dropdown-trigger]:hover, alert-story-mode-viewer pr-dropdown.export-dropdown *[pr-dropdown-trigger].active, alert-story-mode-viewer pr-dropdown.export-dropdown *[pr-dropdown-trigger]:focus {
  border-bottom: none;
}
alert-story-mode-viewer pr-dropdown.export-dropdown span {
  color: #616161;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 12px;
}
alert-story-mode-viewer pr-dropdown.export-dropdown li {
  font-weight: 400;
  padding: 8px 16px;
}
alert-story-mode-viewer .story-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0.25rem 0;
}
alert-story-mode-viewer .story-table-header .tab-group {
  display: flex;
  margin-left: 0;
  box-shadow: inset 0px -1px 0px #E0E0E0;
}
alert-story-mode-viewer .story-table-header .table-title {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  cursor: pointer;
}
alert-story-mode-viewer .story-table-header .table-title:first-child {
  margin-right: 24px;
}
alert-story-mode-viewer .story-table-header .table-title.active {
  box-shadow: inset 0px -4px 0px #2F9ACD;
}
alert-story-mode-viewer .story-table-header .title-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
alert-story-mode-viewer .story-table-header .table-count {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: #616161;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 12px;
  margin-left: 10px;
  padding: 2px 8px 0px 8px;
}
alert-story-mode-viewer .story-table-header .button-group {
  align-items: center;
}
alert-story-mode-viewer .story-table-header .button-group .link-text, alert-story-mode-viewer .story-table-header .button-group alert-inbox .scrolling-panel-text, alert-inbox alert-story-mode-viewer .story-table-header .button-group .scrolling-panel-text {
  font-size: 14px;
  line-height: 16px;
}
alert-story-mode-viewer .story-table-header .button-group ds-button button {
  margin: 0;
  padding-right: 0.5rem;
}
alert-story-mode-viewer .story-table-header .vertical-line {
  width: 1.375rem;
  border: 0.5px solid #BDBDBD;
  transform: rotate(90deg);
}
alert-story-mode-viewer .alert-table-footer {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 28px;
  background-color: #FFFFFF;
  box-shadow: inset 9px 0px 0px -5px #FAFAFA, inset 0px 3px 0px rgba(0, 0, 0, 0.07);
}
alert-story-mode-viewer .aggregate-list-item {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
  padding: 8px 0 8px 6px;
  border-left: 6px solid transparent;
}
alert-story-mode-viewer .aggregate-list-item:hover, alert-story-mode-viewer .aggregate-list-item.active {
  background-color: #FAFAFA;
  border-left: 4px solid #2F9ACD;
}
alert-story-mode-viewer .aggregate-list-item:hover div.clearfix, alert-story-mode-viewer .aggregate-list-item.active div.clearfix {
  padding-left: 2px;
}
alert-story-mode-viewer .aggregate-list-item .alert-badge {
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0px 8px;
  margin: 0px 4px;
}
alert-story-mode-viewer .aggregate-selector-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
alert-story-mode-viewer .aggregate-selector-container .return-to-top-container {
  padding: 12px;
  box-shadow: inset 0px 3px 0px rgba(0, 0, 0, 0.07);
}
alert-story-mode-viewer .aggregate-selector-container .return-to-top-icon {
  vertical-align: middle;
}
alert-story-mode-viewer alert-story-alerts-table table:not(.results), alert-story-mode-viewer search-results-table table:not(.results) {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
alert-story-mode-viewer alert-story-alerts-table table:not(.results) tr, alert-story-mode-viewer search-results-table table:not(.results) tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
alert-story-mode-viewer alert-story-alerts-table table:not(.results) thead, alert-story-mode-viewer search-results-table table:not(.results) thead {
  position: sticky;
  top: 0;
  z-index: 910;
}
alert-story-mode-viewer alert-story-alerts-table table:not(.results) tbody td, alert-story-mode-viewer search-results-table table:not(.results) tbody td {
  border-top: revert;
}
alert-story-mode-viewer alert-story-alerts-table table.results, alert-story-mode-viewer search-results-table table.results {
  margin-left: 40px;
}
alert-story-mode-viewer search-results-table, alert-story-mode-viewer alert-story-alerts-table table:not(.results) {
  height: calc(100% - 178px);
}

fraud-offering-page .header, risk-offering-page .header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-self: center;
}
fraud-offering-page p, risk-offering-page p {
  font-size: 14px;
}
fraud-offering-page .section-container, risk-offering-page .section-container {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1rem;
}
fraud-offering-page .template-container, risk-offering-page .template-container {
  display: contents;
}
fraud-offering-page #branding-icon, risk-offering-page #branding-icon {
  width: 10rem;
  align-self: center;
}
fraud-offering-page #branding-icon svg, risk-offering-page #branding-icon svg {
  fill: #757575;
}

threatmatch-page svg-icon.ds-badge .mat-icon {
  width: 20px;
  height: 18px;
}

uba-panels .ds-dropdown-trigger {
  color: #2F9ACD;
}
uba-panels .trigger-container {
  border-bottom: 0px !important;
}
uba-panels #footer-row-container {
  padding: 0px;
}

single-alert-view .sub-header-action-container {
  float: right;
}
single-alert-view .sub-header-action-container .separator-column {
  color: #BDBDBD;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1rem;
  margin: 0 16px;
}
single-alert-view .info-notification {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 16px;
  background: #FFFCF7;
  border: 1px solid #FED08F;
  border-radius: 2px;
  align-self: stretch;
}
single-alert-view .info-notification .fa-info-circle {
  font-size: 1.5rem;
  color: #FEA82F;
}
single-alert-view .info-notification .info-text-container {
  display: flex;
  flex-direction: column;
}
single-alert-view .info-notification .info-text-container .no-events-available-text {
  font-weight: 500;
}
single-alert-view .info-notification .info-text-container .expired-events-text {
  font-weight: normal;
}

.assets-list ul[pr-dropdown-body].overflow-auto {
  overflow-y: auto;
}
.assets-list .asset-count {
  font-size: 0.8125rem;
  font-weight: 500;
}
.assets-list .heartbeat-header {
  font-size: 1.5625rem;
}

asset-page-search-bar autocomplete-input {
  margin-left: 8px;
}
asset-page-search-bar autocomplete-input input[type=text] {
  height: 28px;
}

.asset-add-edit-modal .mac-addresses h3, .asset-add-edit-modal .ip-addresses h3 {
  margin-bottom: 1rem;
}
.asset-add-edit-modal .mac-addresses ul, .asset-add-edit-modal .ip-addresses ul {
  list-style: none;
}
.asset-add-edit-modal .mac-addresses ul li input, .asset-add-edit-modal .mac-addresses ul li a, .asset-add-edit-modal .ip-addresses ul li input, .asset-add-edit-modal .ip-addresses ul li a {
  display: inline-block;
}
.asset-add-edit-modal .mac-addresses ul li input[type=text], .asset-add-edit-modal .ip-addresses ul li input[type=text] {
  width: calc(100% - 2.5rem);
}
.asset-add-edit-modal .mac-addresses ul li a, .asset-add-edit-modal .ip-addresses ul li a {
  width: 2rem;
  text-align: center;
}

.asset-details-modal table {
  margin-top: 1rem;
  width: 100%;
}
.asset-details-modal .ip-mac-table {
  margin-right: auto;
  margin-left: auto;
  max-width: 31.25rem;
}
.asset-details-modal .add-button {
  font-size: 1rem;
  background-color: #2F9ACD;
  color: #FFFFFF;
}
.asset-details-modal .add-button:hover {
  color: #FFFFFF !important;
}
.asset-details-modal .button-col {
  text-align: center;
  width: 3rem;
}

.delete-assets-modal p {
  text-align: center;
}
.delete-assets-modal table {
  min-width: 40%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.delete-assets-modal table td {
  text-align: center;
}

#asset-settings .setting-input {
  float: right;
  margin-top: -0.6875rem;
  width: 4.375rem;
}
#asset-settings .settings-select {
  text-align: right;
}
#asset-settings .cidr-error {
  color: #DF5146;
  width: auto;
  padding: 0.25rem;
  display: block;
  font-size: 0.75rem;
}
#asset-settings .cidr-x {
  float: right;
}
#asset-settings .cidr-text {
  display: inline;
  width: 20rem;
}
#asset-settings .cidr-button {
  float: right;
  background-color: #2F9ACD;
  color: #FFFFFF;
}

ul.button-group {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
ul.button-group li {
  margin: 0;
}
ul.button-group button {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.settings-users h3 {
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 0.125rem;
}
.settings-users .description {
  font-size: 0.75rem;
}
.settings-users .two-factor-required {
  cursor: default;
}
.settings-users table {
  width: 100%;
}

.settings-sources .create-token-warning {
  background-color: #FFFCF7;
  color: #CF7A01;
  border: 1px solid #CF7A01;
  margin: 1.25rem -0.3125rem 0 -0.3125rem;
  padding: 0 0.3125rem 0 0.3125rem;
}
.settings-sources .copy-icon-container {
  color: #212121;
  font-size: 18px;
  width: 36px;
}
.settings-sources create-token-modal .no-margin-top {
  margin-top: 0;
}
.settings-sources create-token-modal label.no-margin-top, .settings-sources create-token-modal ticket-add-edit-modal dt.no-margin-top, ticket-add-edit-modal .settings-sources create-token-modal dt.no-margin-top, .settings-sources create-token-modal add-edit-ticket-modal dt.no-margin-top, add-edit-ticket-modal .settings-sources create-token-modal dt.no-margin-top, .settings-sources create-token-modal label:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), .settings-sources create-token-modal ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), ticket-add-edit-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), .settings-sources create-token-modal add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), add-edit-ticket-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) {
  padding: 0;
  border: none;
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  color: #616161;
}
.settings-sources create-token-modal label.no-margin-top div .title-header, .settings-sources create-token-modal ticket-add-edit-modal dt.no-margin-top div .title-header, ticket-add-edit-modal .settings-sources create-token-modal dt.no-margin-top div .title-header, .settings-sources create-token-modal add-edit-ticket-modal dt.no-margin-top div .title-header, add-edit-ticket-modal .settings-sources create-token-modal dt.no-margin-top div .title-header, .settings-sources create-token-modal label:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) div .title-header, .settings-sources create-token-modal ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) div .title-header, ticket-add-edit-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) div .title-header, .settings-sources create-token-modal add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) div .title-header, add-edit-ticket-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) div .title-header {
  margin: 0;
}
.settings-sources create-token-modal label:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), .settings-sources create-token-modal ticket-add-edit-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), ticket-add-edit-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), .settings-sources create-token-modal add-edit-ticket-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top), add-edit-ticket-modal .settings-sources create-token-modal dt:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) {
  margin: 1.25rem 0 0 0;
}
.settings-sources create-token-modal label.no-margin-top, .settings-sources create-token-modal ticket-add-edit-modal dt.no-margin-top, ticket-add-edit-modal .settings-sources create-token-modal dt.no-margin-top, .settings-sources create-token-modal add-edit-ticket-modal dt.no-margin-top, add-edit-ticket-modal .settings-sources create-token-modal dt.no-margin-top {
  margin: 0 0 0 0;
}
.settings-sources create-token-modal input[type=text][readonly], .settings-sources create-token-modal input[type=text], .settings-sources create-token-modal input[type=email], .settings-sources create-token-modal input[type=number], .settings-sources create-token-modal input[type=password] {
  margin: 0;
  padding: 0.125rem 0 0 0;
  height: 1.75rem;
  border: none;
  box-shadow: none;
  font-size: 0.875rem;
  font-weight: 500;
}
.settings-sources create-token-modal input[type=text][readonly]::-webkit-input-placeholder, .settings-sources create-token-modal input[type=text]::-webkit-input-placeholder, .settings-sources create-token-modal input[type=email]::-webkit-input-placeholder, .settings-sources create-token-modal input[type=number]::-webkit-input-placeholder, .settings-sources create-token-modal input[type=password]::-webkit-input-placeholder {
  color: #757575;
  font-weight: 400;
}
.settings-sources create-token-modal input[type=text][readonly]:-moz-placeholder, .settings-sources create-token-modal input[type=text]:-moz-placeholder, .settings-sources create-token-modal input[type=email]:-moz-placeholder, .settings-sources create-token-modal input[type=number]:-moz-placeholder, .settings-sources create-token-modal input[type=password]:-moz-placeholder {
  color: #757575;
  font-weight: 400;
}
.settings-sources create-token-modal input[type=text][readonly]::-moz-placeholder, .settings-sources create-token-modal input[type=text]::-moz-placeholder, .settings-sources create-token-modal input[type=email]::-moz-placeholder, .settings-sources create-token-modal input[type=number]::-moz-placeholder, .settings-sources create-token-modal input[type=password]::-moz-placeholder {
  color: #757575;
  font-weight: 400;
}
.settings-sources create-token-modal input[type=text][readonly]:-ms-input-placeholder, .settings-sources create-token-modal input[type=text]:-ms-input-placeholder, .settings-sources create-token-modal input[type=email]:-ms-input-placeholder, .settings-sources create-token-modal input[type=number]:-ms-input-placeholder, .settings-sources create-token-modal input[type=password]:-ms-input-placeholder {
  color: #757575;
  font-weight: 400;
}
.settings-sources create-token-modal input[type=text][readonly] {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

report-edit-page .reorder-fragment-button {
  padding: 0.5rem;
}
report-edit-page .delete-button {
  float: right;
  top: -0.25rem;
  right: 0.25rem;
}
report-edit-page .hover-panel {
  border-left: 0.375rem solid transparent;
  padding: 0.625rem;
}
report-edit-page .hover-panel.contents-handle-padding {
  padding: 0;
}
report-edit-page .hover-panel h1 {
  padding: 0;
  font-size: 1rem;
}
report-edit-page .hover-panel p {
  font-size: 0.9375rem;
  margin-bottom: 0;
}
report-edit-page .hover-panel:hover {
  background-color: #FAFAFA;
  border-color: #2F9ACD;
}
report-edit-page .perforated-border {
  border: 1px dashed #757575;
  margin: 0.25rem;
  min-height: 12.5rem;
}
report-edit-page .indent {
  padding-left: 1rem;
}
report-edit-page .page-width {
  max-width: 50rem;
}

chart-edit-page .chart-edit {
  min-height: 12.5rem;
}
chart-edit-page .chart-edit .badge-number {
  padding: 50px 30px;
}

.table-chart {
  overflow: auto;
}
.table-chart table {
  width: 90%;
  margin: 0 auto;
}
.table-chart th.table-chart-term-header {
  text-transform: none;
}
.table-chart th.table-chart-count-header {
  border-left: 1px solid #757575;
  text-align: center;
  text-transform: none;
}
.table-chart td {
  overflow-wrap: break-word;
}
.table-chart td.table-chart-term {
  text-align: right;
  background-color: #E0E0E0;
  border: 0px;
  border-top: 1px solid #757575;
  font-size: 0.75rem;
  font-weight: 600;
  color: #424242;
  overflow-wrap: break-word;
}
.table-chart td.table-chart-count {
  text-align: center;
  font-weight: 600;
  color: #424242;
  font-size: 0.75rem;
}

div#reveal-view > div {
  top: initial !important;
  left: initial !important;
}

reveal-dashboard code {
  border: unset;
  padding: unset;
  background-color: unset;
}
reveal-dashboard input[type=text] {
  transition: unset;
}

@media print {
  @page {
    size: 8.5in 11in;
    margin: 0.5in;
  }
}
report-view-page {
  position: absolute;
  top: 0.5rem;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 1rem 0.5rem;
  overflow-y: auto;
}
@media print {
  report-view-page {
    font-size: 12pt !important;
  }
  report-view-page h1 {
    font-size: 28pt;
  }
  report-view-page h2 {
    font-size: 22pt;
  }
  report-view-page h3 {
    font-size: 18pt;
  }
  report-view-page h4 {
    font-size: 16pt;
  }
  report-view-page .report {
    width: 100%;
  }
  report-view-page .report article {
    page-break-inside: avoid;
  }
  report-view-page report-header {
    position: relative;
    top: 450px;
    font-size: 28pt;
  }
  report-view-page report-header img.logo {
    max-width: 220px !important;
  }
  report-view-page report-note .note-with-markdown {
    margin-top: 15px;
  }
}
report-view-page report-header > div {
  max-width: 1070px;
}
report-view-page report-header img.logo {
  max-width: 150px;
}
report-view-page report-header .report-dates {
  font-style: italic;
  margin-top: 15px;
}
report-view-page .printing-section {
  position: relative;
}
report-view-page .printing-section .printing-section-contents {
  position: fixed;
  text-align: right;
  top: 100px;
  left: 90px;
  width: 100%;
}
report-view-page .printing-section .printing-section-contents .printing-section-restore {
  position: fixed;
  left: 115px;
  top: 50px;
}
report-view-page .printing-section .printing-section-contents .printing-section-versions {
  position: fixed;
  right: 24px;
  top: 70px;
}
report-view-page .printing-section .printing-section-contents .printing-section-versions button {
  margin-right: 4px;
}
report-view-page .printing-section .printing-section-contents .printing-section-versions label, report-view-page .printing-section .printing-section-contents .printing-section-versions ticket-add-edit-modal dt, ticket-add-edit-modal report-view-page .printing-section .printing-section-contents .printing-section-versions dt, report-view-page .printing-section .printing-section-contents .printing-section-versions add-edit-ticket-modal dt, add-edit-ticket-modal report-view-page .printing-section .printing-section-contents .printing-section-versions dt {
  display: inline-block;
  margin-left: 8px;
}
report-view-page .report {
  max-width: 1070px;
}
report-view-page .report article {
  margin-top: 50px;
}
report-view-page .report h2 {
  border-bottom: none;
}
report-view-page .report h3 {
  font-weight: bold;
  margin-bottom: 15px;
}
report-view-page .report h4 {
  font-weight: bold;
  margin-bottom: 15px;
}
report-view-page .report h6.chart-title {
  margin-top: 20px;
}
report-view-page .report .badge-number {
  padding: 4.375rem;
}
report-view-page .report .top-10-values-msg {
  text-align: right;
}
report-view-page report-note textarea {
  margin-top: 15px;
  margin-bottom: 0px;
}
report-view-page report-note .note-with-markdown {
  margin-top: 15px;
  border: 1px solid #757575;
  padding: 5px;
  min-height: 137px;
  background: #FFFFFF;
}
report-view-page report-note .notes-help {
  float: left;
}
@media (max-height: 2700px) {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: 300px;
    min-height: 100px;
  }
}
@media (max-height: 1600px) {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: 300px;
    min-height: 100px;
  }
}
@media (max-height: 1250px) {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: 300px;
    min-height: 100px;
  }
}
@media (max-height: 900px) {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: 300px;
    min-height: 100px;
  }
}
@media (max-height: 750px) {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: 300px;
    min-height: 100px;
  }
}
@media print {
  report-view-page .table-chart.media-chart-heights {
    height: initial;
    max-height: initial;
    width: 1070px;
    min-height: 100px;
  }
}

ffiec-cat-page .content-header, policy-list .content-header {
  padding: 4px 16px;
}
ffiec-cat-page .sheet, policy-list .sheet {
  margin-top: 0.3125rem;
  margin-bottom: 0.625rem;
}

.fixed-width-severity {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.policies-policy #policy-metadata-row {
  position: relative;
}
.policies-policy #policy-metadata-row #policy-metadata, .policies-policy #policy-metadata-row #policy-stats {
  display: inline-block;
}
.policies-policy #policy-metadata-row #policy-metadata .metadata-row {
  display: table-row;
  font-size: 0.8125rem;
}
.policies-policy #policy-metadata-row #policy-metadata .metadata-row > * {
  display: table-cell;
  padding-right: 0.3125rem;
  line-height: 150%;
}
.policies-policy #policy-metadata-row #policy-metadata .metadata-row > .header {
  font-weight: bold;
}
.policies-policy #policy-metadata-row #policy-stats {
  position: absolute;
  right: 0;
  width: 7rem;
}

.policy-group-table .trigger-cell {
  width: 8%;
}
.policy-group-table .incident-cell {
  width: 8%;
}
.policy-group-table .coverage-cell {
  width: 20%;
}

cat-policies-sub-list .top-padded-button-group {
  padding-top: 0.375rem;
}
cat-policies-sub-list .top-padded-button-group [pr-dropdown-trigger] {
  padding-top: 0;
}
cat-policies-sub-list .top-padded-button-group [pr-dropdown-trigger] i {
  vertical-align: middle;
}

cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] {
  margin-top: -0.85rem;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li {
  padding-right: 1.25rem;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li a {
  display: inline-flex;
  width: 100%;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] .policy-version-name {
  flex: 1;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] .lock-icon-space {
  width: 1.5rem;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  text-align: center;
  display: inline-block;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li.recent-archived-labels {
  cursor: default;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] li.recent-archived-labels:hover {
  background-color: #FFFFFF;
}
cat-policy-version-dropdown pr-dropdown:not([ng-version]) ul[pr-dropdown-body] hr {
  margin: 0;
}

cat-policy-version-list .datepicker {
  width: auto;
}
cat-policy-version-list date-picker .mat-datepicker-toggle button.mat-mdc-icon-button {
  margin-top: 14px;
}

cat-policy-page .content-sheet, cat-risk-profile .content-sheet, cat-risk-profile-page .content-sheet, framework-view-page .content-sheet {
  position: absolute;
  top: 88px;
  left: 8.4375rem;
  right: 0.3125rem;
  bottom: 0.625rem;
  padding: 16px;
  margin-right: 16px;
  overflow-y: auto;
}
cat-policy-page h2, cat-risk-profile h2, cat-risk-profile-page h2, framework-view-page h2 {
  border: 0;
}
cat-policy-page h3, cat-risk-profile h3, cat-risk-profile-page h3, framework-view-page h3 {
  font-weight: 600;
}
cat-policy-page h3.extra-margin, cat-risk-profile h3.extra-margin, cat-risk-profile-page h3.extra-margin, framework-view-page h3.extra-margin {
  margin-top: 10px;
}
cat-policy-page p, cat-policy-page ul, cat-risk-profile p, cat-risk-profile ul, cat-risk-profile-page p, cat-risk-profile-page ul, framework-view-page p, framework-view-page ul {
  font-size: 0.875rem;
}
cat-policy-page .sub-header, cat-risk-profile .sub-header, cat-risk-profile-page .sub-header, framework-view-page .sub-header {
  margin-left: 0;
  margin-top: 0;
}
cat-policy-page .line-item-number-label, cat-risk-profile .line-item-number-label, cat-risk-profile-page .line-item-number-label, framework-view-page .line-item-number-label {
  color: #757575;
  text-align: right;
}
cat-policy-page .no-underline:hover, cat-risk-profile .no-underline:hover, cat-risk-profile-page .no-underline:hover, framework-view-page .no-underline:hover {
  text-decoration: none;
}
cat-policy-page .level-name, cat-risk-profile .level-name, cat-risk-profile-page .level-name, framework-view-page .level-name {
  font-weight: 600;
  font-size: 0.875rem;
  color: #212121;
  vertical-align: middle;
}

framework-tier-target ds-dropdown .ds-dropdown-trigger.disabled-trigger .trigger-container {
  background-color: transparent !important;
}

cat-policy-page pr-dropdown ul, cat-risk-profile pr-dropdown ul, cat-risk-profile-page pr-dropdown ul {
  margin-left: 0px;
  margin-right: 0px;
}
cat-policy-page .level-completion, cat-risk-profile .level-completion, cat-risk-profile-page .level-completion {
  text-transform: none;
  vertical-align: middle;
  padding-left: 0.3125rem;
}
cat-policy-page .cat-policy-img-caption, cat-risk-profile .cat-policy-img-caption, cat-risk-profile-page .cat-policy-img-caption {
  text-align: center;
}
cat-policy-page .cat-policy-img, cat-risk-profile .cat-policy-img, cat-risk-profile-page .cat-policy-img {
  display: block;
  margin: 0 auto;
}
cat-policy-page .collapse-icon, cat-risk-profile .collapse-icon, cat-risk-profile-page .collapse-icon {
  font-size: 1.25rem;
  padding-top: 0.3125rem;
  max-width: 1.875rem;
}
cat-policy-page .lineitems-options, cat-risk-profile .lineitems-options, cat-risk-profile-page .lineitems-options {
  font-weight: inherit;
}
cat-policy-page .align-dropdown, cat-risk-profile .align-dropdown, cat-risk-profile-page .align-dropdown {
  padding-top: 0.1875rem;
}
cat-policy-page .line-item-number-label, cat-risk-profile .line-item-number-label, cat-risk-profile-page .line-item-number-label {
  vertical-align: top;
  max-width: 120px;
  word-wrap: break-word;
}
cat-policy-page .lineitem-option-button, cat-risk-profile .lineitem-option-button, cat-risk-profile-page .lineitem-option-button {
  margin: 0 5px;
  font-size: 0.8125rem;
  padding: 4px 8px;
}
cat-policy-page .top, cat-risk-profile .top, cat-risk-profile-page .top {
  vertical-align: top;
}
cat-policy-page .policy-sections-table, cat-risk-profile .policy-sections-table, cat-risk-profile-page .policy-sections-table {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
cat-policy-page .policy-sections-table p, cat-risk-profile .policy-sections-table p, cat-risk-profile-page .policy-sections-table p {
  margin-bottom: 0.625rem;
}

cat-risk-profile-results .results-header-cell {
  width: 10%;
  text-align: center;
}
cat-risk-profile-results h2 {
  padding-left: 0;
}

cat-policy-results {
  font-size: 0.75rem;
}
cat-policy-results .vertical-line {
  stroke: #616161;
  stroke-opacity: 0.25;
  shape-rendering: crispEdges;
}
cat-policy-results .horizontal-line {
  stroke: #2F9ACD;
  stroke-width: 3;
  stroke-dasharray: 3 3;
  shape-rendering: crispEdges;
}
cat-policy-results .horizontal-level-end-circle {
  fill: #2F9ACD;
}
cat-policy-results .factor-title {
  fill: #616161;
  text-transform: uppercase;
}
cat-policy-results .column-title {
  fill: #2F9ACD;
  text-transform: uppercase;
}

cat-evidence a {
  color: #616161;
}
cat-evidence .evidence-label {
  font-weight: 500;
  color: #757575;
}

#cat-select-line-items-table .inner-content-padder {
  width: 10px;
  border: none;
}
#cat-select-line-items-table .inner-table-container {
  padding: 0;
}
#cat-select-line-items-table .inner-table-container table tr:first-child td {
  border-top: 0;
}
#cat-select-line-items-table .inner-table-container table tr:last-child td {
  border-bottom: 0;
}

.cat-banded-block {
  padding: 0.5rem;
}

.cat-banded-block-title {
  margin-bottom: 0.3125rem;
}

.cat-banded-block-content {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.75rem;
  margin-bottom: 0;
  clear: both;
}

span.cat-banded-block-content p {
  margin-bottom: 0.625rem;
  margin-left: 1rem;
  font-size: 0.75rem;
}

framework-edit-page .fixed-page-container, mapping-framework-edit-page .fixed-page-container {
  overflow-y: auto;
}
framework-edit-page button, mapping-framework-edit-page button {
  margin-top: 0.1rem;
}
framework-edit-page form, mapping-framework-edit-page form {
  display: flex;
}
framework-edit-page section, mapping-framework-edit-page section {
  flex-grow: 1;
}
framework-edit-page .new-element, mapping-framework-edit-page .new-element {
  border: #BDBDBD 2px dashed;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
}
framework-edit-page .new-element:hover, mapping-framework-edit-page .new-element:hover {
  border: rgba(47, 154, 205, 0.7) 2px dashed;
  background-color: #FAFAFA;
  cursor: pointer;
}
framework-edit-page .inner-sheet:first-of-type, framework-edit-page .fixed-right-content, mapping-framework-edit-page .inner-sheet:first-of-type, mapping-framework-edit-page .fixed-right-content {
  margin-top: 0.1rem;
}
framework-edit-page .inner-sheet, mapping-framework-edit-page .inner-sheet {
  margin: 0.5rem 0.1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
framework-edit-page label.no-margin-top, framework-edit-page ticket-add-edit-modal dt.no-margin-top, ticket-add-edit-modal framework-edit-page dt.no-margin-top, framework-edit-page add-edit-ticket-modal dt.no-margin-top, add-edit-ticket-modal framework-edit-page dt.no-margin-top, mapping-framework-edit-page label.no-margin-top, mapping-framework-edit-page ticket-add-edit-modal dt.no-margin-top, ticket-add-edit-modal mapping-framework-edit-page dt.no-margin-top, mapping-framework-edit-page add-edit-ticket-modal dt.no-margin-top, add-edit-ticket-modal mapping-framework-edit-page dt.no-margin-top {
  margin-top: 0;
}
framework-edit-page .fixed-scrolling-content h3.sub-header, mapping-framework-edit-page .fixed-scrolling-content h3.sub-header {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1rem;
}
framework-edit-page .fixed-scrolling-content h3.sub-header.extra-margin-top, mapping-framework-edit-page .fixed-scrolling-content h3.sub-header.extra-margin-top {
  margin-top: 1rem;
}
framework-edit-page .fixed-scrolling-content h3.sub-header.extra-margin-bottom, mapping-framework-edit-page .fixed-scrolling-content h3.sub-header.extra-margin-bottom {
  margin-bottom: 1rem;
}
framework-edit-page .fixed-page-container .fixed-scrolling-content, mapping-framework-edit-page .fixed-page-container .fixed-scrolling-content {
  overflow-y: auto;
}
framework-edit-page button, mapping-framework-edit-page button {
  margin-top: 0.2rem;
}
framework-edit-page .section-header-container, mapping-framework-edit-page .section-header-container {
  display: flex;
}
framework-edit-page .section-header-container h3, mapping-framework-edit-page .section-header-container h3 {
  flex-grow: 1;
}
framework-edit-page h3.section-collapsed, mapping-framework-edit-page h3.section-collapsed {
  margin-bottom: 0;
  border-bottom: none;
}
framework-edit-page .section-edit-action, mapping-framework-edit-page .section-edit-action {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
framework-edit-page .section-delete-action, mapping-framework-edit-page .section-delete-action {
  padding-left: 0.5rem;
  padding-right: 1rem;
}

framework-maturity-tier-add-edit .page-section {
  background: white;
  padding: 16px 24px 24px;
  margin-left: 16px;
  border: hidden;
}
framework-maturity-tier-add-edit .sub-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 0;
}
framework-maturity-tier-add-edit .sub-header.section-header {
  margin-top: 16px;
  margin-bottom: 16px;
}
framework-maturity-tier-add-edit h3 ds-toggle {
  font-size: 18px;
}
framework-maturity-tier-add-edit .sub-section {
  margin-left: 16px;
  margin-right: 16px;
}
framework-maturity-tier-add-edit .tier-name {
  font-size: 12px;
  color: #616161;
}
framework-maturity-tier-add-edit .small-padding {
  padding: 4px;
}
framework-maturity-tier-add-edit .sidebar-short-input {
  width: 109px;
  display: inline-block;
}
framework-maturity-tier-add-edit .sidebar-long-input {
  width: 218px;
  display: inline-block;
}
framework-maturity-tier-add-edit .sidebar-long-input.editing {
  width: 169px;
}

framework-preview .framework-name-container h1 {
  margin-left: 8px;
}
framework-preview .framework-name-container h1:hover {
  color: #2F9ACD;
}

.markup-formatting p {
  margin-bottom: 0;
}

evidence-comments .hover-table, evidence-comments .grouped-table, evidence-tickets .hover-table, evidence-tickets .grouped-table, evidence-task-schedules .hover-table, evidence-task-schedules .grouped-table, evidence-triggers .hover-table, evidence-triggers .grouped-table {
  border: 1px #BDBDBD solid;
}

evidence-comments .action-header {
  margin-top: 0.2rem;
}

evidence-tickets .action-header, evidence-task-schedules .action-header, evidence-triggers .action-header {
  margin-bottom: 0.4rem;
}

integration-delete-button {
  display: inline-block;
  margin: 0 0.5rem;
}
integration-delete-button i.ion {
  font-size: 1.25rem;
}

#help-text {
  margin-top: 1.5625rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

azure-integration-page .azure-orgs-container {
  text-align: center;
}

finxact-integration-page .finxact-integrations-container {
  text-align: center;
}

sales-force-integration-page .salesForce-orgs-container {
  text-align: center;
}

.source-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.source-list li {
  margin: 0.9375rem;
  margin-left: 1.5625rem;
}

.multi-box {
  margin-top: 1.25rem;
  height: 12.625rem;
  box-shadow: 0.625rem -0.625rem 0 #FAFAFA, 1.25rem -1.25rem 0 #E0E0E0;
}
.multi-box section div .title-footer {
  padding-top: 1.25rem;
}

.display-box {
  border: 1px solid #E0E0E0;
}
.display-box .header {
  background-color: #FAFAFA;
  color: #212121;
}
.display-box .header .integrated {
  background-color: #8BB741;
  height: 100%;
}
.display-box .header .active-header {
  background-color: #8BB741;
}
.display-box .header .expired-header {
  background-color: #DF5146;
  color: #FFFFFF;
}
.display-box .logo-icon svg-icon mat-icon {
  width: 100%;
  height: 100%;
}
.display-box .time-box {
  border: 1px solid #E0E0E0;
  border-top: solid 0.125rem #E0E0E0;
}
.display-box .time-box .time-title {
  color: #757575;
  float: left;
  padding: 0.1875rem;
}
.display-box .time-box .time-display {
  float: right;
  padding: 0.1875rem;
  color: #757575;
}

.activate-open-dns {
  text-align: center;
  margin: 1.25rem;
}

.delete-warning-section {
  text-align: center;
}
.delete-warning-section .warning-text {
  margin: 0.9375rem;
}
.delete-warning-section .delete-azure-org {
  margin: 1.25rem;
  background-color: #FFFFFF;
  border: solid 1px #616161;
  color: #2F9ACD;
  font-weight: 500;
  line-height: 100%;
}
.delete-warning-section .delete-azure-org:hover {
  background-color: #DF5146;
  color: #FFFFFF;
}
.delete-warning-section .delete-salesforce-org {
  margin: 1.25rem;
  background-color: #FFFFFF;
  border: solid 1px #616161;
  color: #2F9ACD;
  font-weight: 500;
  line-height: 100%;
}
.delete-warning-section .delete-salesforce-org:hover {
  background-color: #DF5146;
  color: #FFFFFF;
}
.delete-warning-section #deletion-enabled {
  font-weight: 600;
  color: #DF5146;
  margin: 2.5rem;
}

.delete-org .modal-footer button.commit.default {
  background-color: #DF5146;
}

#default-trigger-modal .query span, #default-triggers-modal .query span {
  font-size: 0.875rem;
}

.ds-dropdown-body .blue-link-options-container li[pr-dropdown-close-on-click] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.library-trigger-group-options-button button {
  padding-top: 16px;
}

library-trigger-group .loading-analysis .loading-container {
  text-align: left !important;
  font-size: 6px;
}
library-trigger-group .loading-analysis .loading-container i {
  margin-top: 2px;
  margin-bottom: 0;
  margin-right: 8px;
}

trigger-view {
  font-size: 0.875rem;
}
trigger-view .trigger-conditions {
  margin-top: 0.625rem;
  display: inline-block;
}
trigger-view .instructional-text {
  font-style: italic;
  padding: 0.125rem 1.25rem;
}
trigger-view ul.button-group {
  height: 2.5rem;
}
trigger-view new-policies-section .section-container {
  padding: 0 14px;
}

edit-trigger .type-table {
  border: 0px;
  margin-bottom: 1.25rem;
}
edit-trigger .type-table td {
  padding: 0.125rem 1.25rem 0 0;
}
edit-trigger .dropdown-cell {
  white-space: nowrap;
}

new-trigger-type-selector .panel-container {
  display: flex;
  align-items: stretch;
}
new-trigger-type-selector .panel {
  margin: 0.5rem;
  flex: 1;
  transition: background-color 300ms ease-out 0s;
}
new-trigger-type-selector .panel h1 {
  padding: 0;
}
new-trigger-type-selector .panel:hover {
  background-color: #d9d9d9;
}
new-trigger-type-selector .big-pad-bottom {
  margin-bottom: 1.5625rem;
}

trigger-sidebar {
  display: block;
  margin-bottom: 24px;
}
trigger-sidebar ds-button.trigger-version-button button {
  float: right;
  margin-top: -8px;
  padding-right: 0;
}

trigger-notifications ds-button button.black-link, trigger-info-panel ds-button button.black-link {
  color: #616161;
}
trigger-notifications ds-button button.black-link:hover, trigger-info-panel ds-button button.black-link:hover {
  color: #DF5146;
}
trigger-notifications ds-button button.black-link:active, trigger-info-panel ds-button button.black-link:active {
  color: #C02D21;
}
trigger-notifications trigger-pause-control ds-dropdown .ds-dropdown-trigger, trigger-info-panel trigger-pause-control ds-dropdown .ds-dropdown-trigger {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
trigger-notifications trigger-pause-control ds-dropdown .ds-dropdown-trigger div, trigger-info-panel trigger-pause-control ds-dropdown .ds-dropdown-trigger div {
  font-weight: 400 !important;
}

trigger-important-fields .section-container {
  padding: 0 14px;
}
trigger-important-fields ds-button button {
  padding: 14px 0 0 0;
}

trigger-aggregation-selector .ion-ios-help {
  font-size: 1.25rem;
  color: #616161;
  margin-left: 0.75rem;
  position: relative;
  top: 0.125rem;
}

trigger-threshold-selector .interval-type {
  width: 7.5rem;
}

interval-selector .interval-unit {
  width: 7.5rem;
}

sequence-trigger-steps .add-query-container ds-button button {
  padding-top: 0;
}
sequence-trigger-steps .add-query-container button {
  padding-top: 4px;
}

trigger-configuration .trigger-configuration-section:not(:last-of-type) {
  margin-bottom: 32px;
}
trigger-configuration .trigger-configuration-section .config-info-message {
  color: #616161;
}
trigger-configuration .trigger-configuration-section .read-only-trigger-name {
  font-weight: 500;
}
trigger-configuration .trigger-configuration-section .trigger-type-description {
  color: #616161;
  font-size: 0.75rem;
  line-height: 1rem;
}
trigger-configuration .hint-text {
  color: #616161;
}

trigger-query-display saved-query-summary search-link {
  width: 80%;
}

ticket-escalation-settings-modal-view person-picker ds-select, incident-details-modal person-picker ds-select {
  width: 50%;
}
ticket-escalation-settings-modal-view .state-selector, incident-details-modal .state-selector {
  display: inline-block;
  margin-left: 4px;
}
ticket-escalation-settings-modal-view .state-selector ds-dropdown .trigger-container, incident-details-modal .state-selector ds-dropdown .trigger-container {
  border-bottom: 1px solid transparent;
}
ticket-escalation-settings-modal-view .state-selector ds-dropdown .trigger-container:active, ticket-escalation-settings-modal-view .state-selector ds-dropdown .trigger-container:focus, incident-details-modal .state-selector ds-dropdown .trigger-container:active, incident-details-modal .state-selector ds-dropdown .trigger-container:focus {
  border-bottom: 1px solid transparent;
}
ticket-escalation-settings-modal-view .state-selector ds-dropdown .trigger-container #ds-dropdown-trigger, ticket-escalation-settings-modal-view .state-selector ds-dropdown .trigger-container i, incident-details-modal .state-selector ds-dropdown .trigger-container #ds-dropdown-trigger, incident-details-modal .state-selector ds-dropdown .trigger-container i {
  font-size: 0.75rem;
  font-weight: normal;
  color: #2F9ACD;
}

trigger-list .resync-button button {
  height: 16px !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 8px;
}

task-schedule-edit-page .save-footer {
  height: 4.6875rem;
  right: 0;
  left: 0.3125rem;
  bottom: 0;
  margin: 0 0.9375rem;
  background-color: #FFFFFF;
}
task-schedule-edit-page .bottom-padded {
  padding-bottom: 5rem;
}

task-schedule-new-page interval-selector select[name=interval-unit] {
  width: calc(12.75rem - 5px);
}

task-schedule-edit-form .query-name-margin-bottom {
  margin-bottom: 0.625rem;
}
task-schedule-edit-form .no-margin-top {
  margin-top: 0;
}
task-schedule-edit-form .section-margin-bottom {
  margin-bottom: 1.2rem;
}

button.inline-add {
  color: #2F9ACD;
  font-size: 0.875rem;
  padding: 0;
}
button.inline-add.with-padding {
  padding-top: 1rem;
}
button.inline-add i {
  font-size: 0.875rem;
  padding-right: 0.1875rem;
}
button.inline-add[disabled] {
  color: #2F9ACD;
  text-decoration: none;
}

parent-and-subschedules .loading-spinner {
  margin: unset;
}

svg-icon.trash-icon .mat-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
  fill: #757575;
}
svg-icon.trash-icon.fill-warning .mat-icon {
  fill: #CF7A01;
}

ds-button button, a.button {
  font-weight: 500;
  border-radius: 0.125rem;
  vertical-align: top;
}
ds-button button:focus, a.button:focus {
  outline: none;
}
ds-button button:disabled, a.button:disabled {
  pointer-events: none;
  opacity: 0.4;
}
ds-button button:focus-visible, a.button:focus-visible {
  outline: none;
  border-radius: 0.25rem;
}
ds-button button.large, a.button.large {
  height: 2.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
}
ds-button button.large:focus-visible, a.button.large:focus-visible {
  height: 2.6875rem;
}
ds-button button.medium, a.button.medium {
  height: 2rem;
  font-size: 0.875rem;
  line-height: 1rem;
}
ds-button button.medium:focus-visible, a.button.medium:focus-visible {
  height: 2.1875rem;
}
ds-button button.small, a.button.small {
  height: 1.5rem;
  padding: 0.3125rem 1rem;
  margin: 0 0.0625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}
ds-button button.small:focus-visible, a.button.small:focus-visible {
  height: 1.6875rem;
}
ds-button button.standard, a.button.standard {
  background: #2F9ACD;
}
ds-button button.standard:hover, a.button.standard:hover {
  background: #24769E;
}
ds-button button.standard:focus-visible, a.button.standard:focus-visible {
  background: #2F9ACD;
  border: 0.1875rem solid #DFF0F8;
}
ds-button button.standard:active, a.button.standard:active {
  background: #19536E;
}
ds-button button.standard:disabled, a.button.standard:disabled {
  background: #5EB2DA;
}
ds-button button.standard-green, a.button.standard-green {
  background: #8BB741;
}
ds-button button.standard-green:hover, a.button.standard-green:hover {
  background: #6B8D32;
}
ds-button button.standard-green:focus-visible, a.button.standard-green:focus-visible {
  background: #8BB741;
  border: 0.1875rem solid #EDF4E1;
}
ds-button button.standard-green:active, a.button.standard-green:active {
  background: #4B6323;
}
ds-button button.standard-green:disabled, a.button.standard-green:disabled {
  background: #A6CA6A;
}
ds-button button.delete, a.button.delete {
  background: #DF5146;
}
ds-button button.delete:hover, a.button.delete:hover {
  background: #C02D21;
}
ds-button button.delete:focus-visible, a.button.delete:focus-visible {
  background: #DF5146;
  border: 0.1875rem solid #F5C9C6;
}
ds-button button.delete:active, a.button.delete:active {
  background: #871F17;
}
ds-button button.warn, a.button.warn {
  background: #FEA82F;
}
ds-button button.warn:hover, a.button.warn:hover {
  background: #CF7A01;
}
ds-button button.warn:focus-visible, a.button.warn:focus-visible {
  background: #DF5146;
  border: 0.1875rem solid #F5C9C6;
}
ds-button button.warn:active, a.button.warn:active {
  background: #871F17;
}
ds-button button.cancel, a.button.cancel {
  background: none;
  color: #616161;
}
ds-button button.cancel:hover, a.button.cancel:hover {
  background: #F5F5F5;
}
ds-button button.cancel:focus-visible, a.button.cancel:focus-visible {
  background: #FFFFFF;
  border: 0.1875rem solid #E0E0E0;
}
ds-button button.cancel:active, a.button.cancel:active {
  background: #E0E0E0;
}
ds-button button.outlined, a.button.outlined {
  background: #FFFFFF;
  color: #2F9ACD;
  border: 0.0625rem solid #2F9ACD !important;
  box-sizing: border-box;
}
ds-button button.outlined:hover, a.button.outlined:hover {
  background: #DFF0F8;
}
ds-button button.outlined:focus-visible, a.button.outlined:focus-visible {
  background: #FFFFFF;
  border: 0.1875rem solid #DFF0F8;
}
ds-button button.outlined:active, a.button.outlined:active {
  background: #DFF0F8;
}
ds-button button.text, a.button.text {
  color: #2F9ACD;
}
ds-button button.text:hover, a.button.text:hover {
  background: #F7FBFD;
}
ds-button button.text:focus-visible, a.button.text:focus-visible {
  background: none;
  border: 0.1875rem solid #DFF0F8;
}
ds-button button.text:active, a.button.text:active {
  background: #DFF0F8;
}
ds-button button.blue-link, a.button.blue-link {
  color: #2F9ACD;
}
ds-button button.black-link, a.button.black-link {
  color: #212121;
}
ds-button button.red-link, a.button.red-link {
  color: #DF5146;
}
ds-button button.blue-link, ds-button button.black-link, ds-button button.red-link, a.button.blue-link, a.button.black-link, a.button.red-link {
  background: none;
  padding-left: 0;
}
ds-button button.blue-link, ds-button button.black-link, a.button.blue-link, a.button.black-link {
  background: none;
}
ds-button button.blue-link:hover, ds-button button.black-link:hover, a.button.blue-link:hover, a.button.black-link:hover {
  color: #24769E;
}
ds-button button.blue-link:focus-visible, ds-button button.black-link:focus-visible, a.button.blue-link:focus-visible, a.button.black-link:focus-visible {
  color: #2F9ACD;
}
ds-button button.blue-link:active, ds-button button.black-link:active, a.button.blue-link:active, a.button.black-link:active {
  color: #19536E;
}
ds-button button.red-link, a.button.red-link {
  background: none;
}
ds-button button.red-link:hover, a.button.red-link:hover {
  color: #C02D21;
}
ds-button button.red-link:focus-visible, a.button.red-link:focus-visible {
  color: #DF5146;
}
ds-button button.red-link:active, a.button.red-link:active {
  color: #871F17;
}
ds-button button.icon, a.button.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
ds-button button.icon.large, a.button.icon.large {
  width: 2.5rem;
}
ds-button button.icon.large i, a.button.icon.large i {
  font-size: 24px;
}
ds-button button.icon.medium, a.button.icon.medium {
  width: 2rem;
}
ds-button button.icon.medium i, a.button.icon.medium i {
  font-size: 1.25rem;
}
ds-button button.icon.small, a.button.icon.small {
  width: 1.5rem;
}

ds-button-bar {
  height: 100%;
}
ds-button-bar .button-container {
  display: flex;
}
ds-button-bar[size=small] > div {
  height: 24px;
}
ds-button-bar ds-button {
  margin-right: -3px;
}
ds-button-bar ds-button button.outlined {
  border: 1px solid #BDBDBD !important;
  border-radius: 0;
  color: #2F9ACD;
}
ds-button-bar ds-button.active button.outlined {
  background-color: #2F9ACD;
  border: 1px solid #2F9ACD !important;
  color: #FFFFFF;
}
ds-button-bar ds-button:first-child button {
  border-radius: 2px 0px 0px 2px;
}
ds-button-bar ds-button:last-child button {
  border-radius: 0px 2px 2px 0px;
}
ds-button-bar .vertical-bar {
  flex-direction: column;
}
ds-button-bar .vertical-bar ds-button:first-child button {
  border-radius: 2px 2px 0px 0px;
}
ds-button-bar .vertical-bar ds-button:last-child button {
  border-radius: 0px 0px 2px 2px;
}

risk-score-group:last-child .risk-score-container {
  margin-bottom: 0;
}
risk-score-group .risk-score-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}
risk-score-group .risk-score-container .term-label {
  font-weight: normal;
  width: 10rem;
}
risk-score-group .risk-score-container .risk-score-actions {
  margin-left: 24px;
  padding-top: 8px;
  align-self: flex-start;
}
risk-score-group .risk-score-container .risk-score-actions .context-button {
  padding: 4px;
  font-size: 16px;
}
risk-score-group .context-button {
  align-self: baseline;
  margin-left: 8px;
}
risk-score-group .fa-sticky-note {
  color: #616161;
}

risk-score .risk-active-circle {
  visibility: visible;
}
risk-score .inactive-score .risk-active-circle {
  visibility: hidden;
}
risk-score svg-icon {
  width: 78px;
  height: 26px;
  margin-left: 1px;
}
risk-score .risk-score-controls {
  display: flex;
}
risk-score .single-risk {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  align-items: center;
  color: #212121;
}
risk-score .single-risk.inactive-score, risk-score .single-risk.recommended-score {
  color: #757575;
}
risk-score .single-risk .INSIGNIFICANT-color, risk-score .single-risk .COMPLETE-color, risk-score .single-risk .DECREASING_RAPIDLY-color {
  color: #4B6323;
}
risk-score .single-risk .LOW-color, risk-score .single-risk .STRONG-color, risk-score .single-risk .DECREASING-color {
  color: #8BB741;
}
risk-score .single-risk .MODERATE-color {
  color: #FEA82F;
}
risk-score .single-risk .STABLE-color {
  color: #24769E;
}
risk-score .single-risk .HIGH-color, risk-score .single-risk .WEAK-color, risk-score .single-risk .INCREASING-color {
  color: #DF5146;
}
risk-score .single-risk .EXTREME-color, risk-score .single-risk .INEFFECTIVE-color, risk-score .single-risk .INCREASING_RAPIDLY-color {
  color: #871F17;
}
risk-score .single-risk.inactive-score svg-icon, risk-score .single-risk.recommended-score svg-icon {
  opacity: 50%;
}

.risk-module-icon .risk-icon-background {
  fill: #FFFFFF;
}
.risk-module-icon.module-nonpurchased .risk-icon-color {
  fill: #757575;
}
.risk-module-icon.module-purchased .risk-icon-color {
  fill: #2F9ACD;
}
.risk-module-icon.module-purchased.module-selected .risk-icon-background {
  fill: #2F9ACD;
}
.risk-module-icon.module-purchased.module-selected .risk-icon-text {
  fill: white;
}
.risk-module-icon.module-selected .risk-icon-background {
  fill: #757575;
}
.risk-module-icon.module-selected .risk-icon-text {
  fill: white;
}

risk-edit-sidebar .term-label {
  font-weight: 400;
  font-size: 0.75rem;
}

.risk-activity-log-item-list {
  margin-bottom: 0.5rem;
}
.risk-activity-log-item-list ul {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: inherit;
}
.risk-activity-log-item-list li {
  margin-top: 0.5rem;
}

policy-evidence .view-evidence-icon {
  margin-right: 5px;
}
policy-evidence .evidence-extra-space-top {
  display: block;
  padding-top: 26px;
}
policy-evidence .evidence-extra-space-top pr-dropdown *[pr-dropdown-trigger] {
  padding-top: unset;
}

.risk-suggestions-list .action-button {
  margin-left: 1rem;
}
.risk-suggestions-list .suggested-items-list .ds-item-list-item {
  color: #616161;
  line-height: 1.2rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.risk-suggestions-list .fa-spinner {
  padding: 9px;
}
.risk-suggestions-list .more-suggestions {
  font-weight: 600;
  padding-bottom: 0.75rem;
  color: #616161;
}

.threat-type-filter-dropdown ul.ds-dropdown-body {
  max-height: none;
}
.threat-type-filter-dropdown .dropdown-footer {
  min-width: 300px;
}
.threat-type-filter-dropdown #ds-dropdown-footer-row {
  display: flex;
  justify-content: space-between;
}

assessments-page risk-assessment-filters .dropdown-label {
  padding-right: 2px;
  color: #616161;
  font-weight: 400;
}
assessments-page risk-assessment-filters .dropdown-value {
  font-weight: 500;
}
assessments-page risk-assessment-filters i.dropdown {
  margin-left: 4px;
  font-size: 0.5rem;
}
assessments-page risk-assessment-filters div.trigger-container:not(threat-details-form div) {
  border-bottom: none !important;
}
assessments-page upgraded-risk-assessment-header .new-assessment-dropdown #ds-dropdown-trigger, assessments-page inventory-assessment-header .new-assessment-dropdown #ds-dropdown-trigger {
  margin-bottom: 8px !important;
}
assessments-page upgraded-risk-assessment-header .export-dropdown #ds-dropdown-trigger, assessments-page inventory-assessment-header .export-dropdown #ds-dropdown-trigger {
  margin-bottom: 8px !important;
}

ds-button-bar.score-colors ds-button:nth-child(n+1) button {
  color: #4B6323;
}
ds-button-bar.score-colors ds-button:nth-child(n+2) button {
  color: #8BB741;
}
ds-button-bar.score-colors ds-button:nth-child(n+3) button {
  color: #FEA82F;
}
ds-button-bar.score-colors ds-button:nth-child(n+4) button {
  color: #DF5146;
}
ds-button-bar.score-colors ds-button:nth-child(n+5) button {
  color: #871F17;
}
ds-button-bar.score-colors ds-button:hover:nth-child(n+1) button, ds-button-bar.score-colors ds-button.recommended:nth-child(n+1) button {
  background-color: #C1DA97;
}
ds-button-bar.score-colors ds-button:hover:nth-child(n+2) button, ds-button-bar.score-colors ds-button.recommended:nth-child(n+2) button {
  background-color: #EDF4E1;
}
ds-button-bar.score-colors ds-button:hover:nth-child(n+3) button, ds-button-bar.score-colors ds-button.recommended:nth-child(n+3) button {
  background-color: #FFF2DF;
}
ds-button-bar.score-colors ds-button:hover:nth-child(n+4) button, ds-button-bar.score-colors ds-button.recommended:nth-child(n+4) button {
  background-color: #FAE4E3;
}
ds-button-bar.score-colors ds-button:hover:nth-child(n+5) button, ds-button-bar.score-colors ds-button.recommended:nth-child(n+5) button {
  background-color: #FAE4E3;
}
ds-button-bar.score-colors ds-button.active:nth-child(n+1) button {
  background-color: #4B6323;
  border: 1px solid #4B6323 !important;
  color: #FFFFFF;
}
ds-button-bar.score-colors ds-button.active:nth-child(n+2) button {
  background-color: #8BB741;
  border: 1px solid #8BB741 !important;
  color: #FFFFFF;
}
ds-button-bar.score-colors ds-button.active:nth-child(n+3) button {
  background-color: #FEA82F;
  border: 1px solid #FEA82F !important;
  color: #FFFFFF;
}
ds-button-bar.score-colors ds-button.active:nth-child(n+4) button {
  background-color: #DF5146;
  border: 1px solid #DF5146 !important;
  color: #FFFFFF;
}
ds-button-bar.score-colors ds-button.active:nth-child(n+5) button {
  background-color: #871F17;
  border: 1px solid #871F17 !important;
  color: #FFFFFF;
}

ds-button-bar.direction-score-colors ds-button:nth-child(n+1) button {
  color: #4B6323;
}
ds-button-bar.direction-score-colors ds-button:nth-child(n+2) button {
  color: #8BB741;
}
ds-button-bar.direction-score-colors ds-button:nth-child(n+3) button {
  color: #24769E;
}
ds-button-bar.direction-score-colors ds-button:nth-child(n+4) button {
  color: #DF5146;
}
ds-button-bar.direction-score-colors ds-button:nth-child(n+5) button {
  color: #871F17;
}
ds-button-bar.direction-score-colors ds-button:hover:nth-child(n+1) button, ds-button-bar.direction-score-colors ds-button.recommended:nth-child(n+1) button {
  background-color: #C1DA97;
}
ds-button-bar.direction-score-colors ds-button:hover:nth-child(n+2) button, ds-button-bar.direction-score-colors ds-button.recommended:nth-child(n+2) button {
  background-color: #EDF4E1;
}
ds-button-bar.direction-score-colors ds-button:hover:nth-child(n+3) button, ds-button-bar.direction-score-colors ds-button.recommended:nth-child(n+3) button {
  background-color: #DFF0F8;
}
ds-button-bar.direction-score-colors ds-button:hover:nth-child(n+4) button, ds-button-bar.direction-score-colors ds-button.recommended:nth-child(n+4) button {
  background-color: #FAE4E3;
}
ds-button-bar.direction-score-colors ds-button:hover:nth-child(n+5) button, ds-button-bar.direction-score-colors ds-button.recommended:nth-child(n+5) button {
  background-color: #FAE4E3;
}
ds-button-bar.direction-score-colors ds-button.active:nth-child(n+1) button {
  background-color: #4B6323;
  border: 1px solid #4B6323 !important;
  color: #FFFFFF;
}
ds-button-bar.direction-score-colors ds-button.active:nth-child(n+2) button {
  background-color: #8BB741;
  border: 1px solid #8BB741 !important;
  color: #FFFFFF;
}
ds-button-bar.direction-score-colors ds-button.active:nth-child(n+3) button {
  background-color: #24769E;
  border: 1px solid #24769E !important;
  color: #FFFFFF;
}
ds-button-bar.direction-score-colors ds-button.active:nth-child(n+4) button {
  background-color: #DF5146;
  border: 1px solid #DF5146 !important;
  color: #FFFFFF;
}
ds-button-bar.direction-score-colors ds-button.active:nth-child(n+5) button {
  background-color: #871F17;
  border: 1px solid #871F17 !important;
  color: #FFFFFF;
}

ds-button-bar.inverted-score-colors ds-button:nth-child(n+1) button {
  color: #871F17;
}
ds-button-bar.inverted-score-colors ds-button:nth-child(n+2) button {
  color: #DF5146;
}
ds-button-bar.inverted-score-colors ds-button:nth-child(n+3) button {
  color: #FEA82F;
}
ds-button-bar.inverted-score-colors ds-button:nth-child(n+4) button {
  color: #8BB741;
}
ds-button-bar.inverted-score-colors ds-button:nth-child(n+5) button {
  color: #4B6323;
}
ds-button-bar.inverted-score-colors ds-button:hover:nth-child(n+1) button, ds-button-bar.inverted-score-colors ds-button.recommended:nth-child(n+1) button {
  background-color: #FAE4E3;
}
ds-button-bar.inverted-score-colors ds-button:hover:nth-child(n+2) button, ds-button-bar.inverted-score-colors ds-button.recommended:nth-child(n+2) button {
  background-color: #FAE4E3;
}
ds-button-bar.inverted-score-colors ds-button:hover:nth-child(n+3) button, ds-button-bar.inverted-score-colors ds-button.recommended:nth-child(n+3) button {
  background-color: #FFF2DF;
}
ds-button-bar.inverted-score-colors ds-button:hover:nth-child(n+4) button, ds-button-bar.inverted-score-colors ds-button.recommended:nth-child(n+4) button {
  background-color: #EDF4E1;
}
ds-button-bar.inverted-score-colors ds-button:hover:nth-child(n+5) button, ds-button-bar.inverted-score-colors ds-button.recommended:nth-child(n+5) button {
  background-color: #C1DA97;
}
ds-button-bar.inverted-score-colors ds-button.active:nth-child(n+1) button {
  background-color: #871F17;
  border: 1px solid #871F17 !important;
  color: #FFFFFF;
}
ds-button-bar.inverted-score-colors ds-button.active:nth-child(n+2) button {
  background-color: #DF5146;
  border: 1px solid #DF5146 !important;
  color: #FFFFFF;
}
ds-button-bar.inverted-score-colors ds-button.active:nth-child(n+3) button {
  background-color: #FEA82F;
  border: 1px solid #FEA82F !important;
  color: #FFFFFF;
}
ds-button-bar.inverted-score-colors ds-button.active:nth-child(n+4) button {
  background-color: #8BB741;
  border: 1px solid #8BB741 !important;
  color: #FFFFFF;
}
ds-button-bar.inverted-score-colors ds-button.active:nth-child(n+5) button {
  background-color: #4B6323;
  border: 1px solid #4B6323 !important;
  color: #FFFFFF;
}

.risk-state-badge {
  margin: 0;
}

.fixed-page-container.below-top-nav-and-header-edit {
  top: 80px;
}
.fixed-page-container.below-top-nav-and-header-view {
  top: 88px; /* Originally, 5rem but need 8px of margin on the bottom */
}

.actions-margin {
  margin-top: 16px;
}

risk-selection-modal .risk-event-type-dropdown pr-dropdown ul {
  max-height: 140px;
}
risk-selection-modal ds-dropdown > pr-dropdown > ul[pr-dropdown-body] {
  overflow-y: revert;
}

svg-icon.extremely-large .mat-icon {
  height: 260px;
  width: 290px;
}

risk-assessment-scores-modal .score-undefined, edit-risk-score-modal .score-undefined, risk-score-group .score-undefined, contextual-risk-score .score-undefined, inventory-assessment-scores-modal .score-undefined {
  color: #212121;
}
risk-assessment-scores-modal .score-insignificant, edit-risk-score-modal .score-insignificant, risk-score-group .score-insignificant, contextual-risk-score .score-insignificant, inventory-assessment-scores-modal .score-insignificant {
  color: #2B3814;
}
risk-assessment-scores-modal .score-low, edit-risk-score-modal .score-low, risk-score-group .score-low, contextual-risk-score .score-low, inventory-assessment-scores-modal .score-low {
  color: #8BB741;
}
risk-assessment-scores-modal .score-medium, edit-risk-score-modal .score-medium, risk-score-group .score-medium, contextual-risk-score .score-medium, inventory-assessment-scores-modal .score-medium {
  color: #CF7A01;
}
risk-assessment-scores-modal .score-stable, edit-risk-score-modal .score-stable, risk-score-group .score-stable, contextual-risk-score .score-stable, inventory-assessment-scores-modal .score-stable {
  color: #24769E;
}
risk-assessment-scores-modal .score-high, edit-risk-score-modal .score-high, risk-score-group .score-high, contextual-risk-score .score-high, inventory-assessment-scores-modal .score-high {
  color: #C02D21;
}
risk-assessment-scores-modal .score-extreme, edit-risk-score-modal .score-extreme, risk-score-group .score-extreme, contextual-risk-score .score-extreme, inventory-assessment-scores-modal .score-extreme {
  color: #4D120D;
}

risk-edit-threat-details-modal .ds-dropdown-trigger {
  padding-top: 6px;
  margin-bottom: 4px;
}

risk-list-header .create-risk-exception-dropdown .ds-dropdown-trigger {
  margin-bottom: 2px;
}

risk-assessment-view .warning-icon, risk-score-group .warning-icon {
  margin-left: 2px;
  color: #FEA82F;
}
risk-assessment-view .warning-icon:hover, risk-score-group .warning-icon:hover {
  color: #CF7A01;
}

.ds-dropdown-body .blue-link-options-container li[pr-dropdown-close-on-click] {
  padding-top: 3px;
  padding-bottom: 3px;
}

active-risk-schedule-view .edit-button button {
  padding-top: 0;
}

inventory-list .review-status-late, internal-control-list .review-status-late, operational-risk-list .review-status-late, active-risk-schedule-view .review-status-late {
  color: #C02D21;
}
inventory-list .review-status-almost-due, internal-control-list .review-status-almost-due, operational-risk-list .review-status-almost-due, active-risk-schedule-view .review-status-almost-due {
  color: #CF7A01;
}
inventory-list .review-status-early, internal-control-list .review-status-early, operational-risk-list .review-status-early, active-risk-schedule-view .review-status-early {
  color: #6B8D32;
}

@media print {
  inventory-view-page .main-page-container {
    overflow-y: visible !important;
  }
  inventory-view-page .main-page-content {
    overflow-y: visible !important;
  }
  inventory-view-page .main-page-sidebar {
    overflow-y: visible !important;
  }
  inventory-view-page a[href]:after {
    content: none !important;
  }
  inventory-view-page button, inventory-view-page input, inventory-view-page select, inventory-view-page pr-dropdown, inventory-view-page ds-dropdown, inventory-view-page file-uploader {
    display: none !important;
  }
  inventory-view-page a {
    text-decoration: none;
  }
  inventory-view-page .operational-risk-section {
    display: block !important;
  }
}

default-assessment-view risk-heatmap td:not(.left-main-label):not(.left-sub-labels):not(.bottom-sub-labels):not(.bottom-main-label) {
  opacity: 0.3;
}

activity-log-section .loading-spinner {
  opacity: 0.6;
  margin: 2rem 0;
}

global-tag-search-tabs .mat-mdc-tab {
  min-width: 200px;
}
global-tag-search-tabs .mdc-tab__content {
  transform: translateX(-20%);
}

add-indicators-by-typing-section autocomplete-input > input.ng-invalid {
  border-bottom: 1px solid #DF5146 !important;
  background: #FEF8F8 !important;
}

watchlist-list td.indicator-count-column > ds-button > button {
  padding-right: 0;
}

view-edit-indicators-modal indicators-list-header h1 {
  border-bottom: none !important;
  text-transform: none !important;
}

threat-level-icon img {
  width: 24px;
}

info-panel li threat-level-icon img {
  width: 20px;
}

info-panel a {
  color: #2F9ACD;
}
info-panel a:hover, info-panel a:focus {
  color: #2884b0;
}

.zen-mode-panel {
  margin-top: 56px;
  background-color: #FAFAFA;
}
.zen-mode-panel > :first-child {
  width: 100%;
}

.side-panel-panel {
  margin-top: 56px;
  display: flex;
  justify-content: flex-end;
}
.side-panel-panel > :first-child {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

sort-order-arrows {
  font-size: 0.875rem;
  line-height: 100%;
  color: #2F9ACD;
}

.reveal-modal {
  z-index: 1050;
}

.reveal-modal-bg {
  z-index: 1040;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: AvenirNextLTPro;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: AvenirNextLTPro;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: AvenirNextLTPro;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: AvenirNextLTPro;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: AvenirNextLTPro;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: AvenirNextLTPro;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: AvenirNextLTPro;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: AvenirNextLTPro;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: AvenirNextLTPro;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: AvenirNextLTPro;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: AvenirNextLTPro;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: AvenirNextLTPro;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-selected-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-flat-disabled-selected-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-selected-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-flat-disabled-selected-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: AvenirNextLTPro;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: AvenirNextLTPro;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: AvenirNextLTPro;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #3f51b5;
  --mat-slider-hover-state-layer-color: rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color: rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #ff4081;
  --mat-slider-hover-state-layer-color: rgba(255, 64, 129, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 64, 129, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mdc-slider-label-label-text-font: AvenirNextLTPro;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: AvenirNextLTPro;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: AvenirNextLTPro;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: AvenirNextLTPro;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: AvenirNextLTPro;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px AvenirNextLTPro;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: AvenirNextLTPro;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: AvenirNextLTPro;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: AvenirNextLTPro;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
  --mat-text-button-state-layer-color: #3f51b5;
  --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
  --mat-text-button-state-layer-color: #ff4081;
  --mat-text-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3f51b5;
  --mat-outlined-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ff4081;
  --mat-outlined-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: AvenirNextLTPro;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: AvenirNextLTPro;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: AvenirNextLTPro;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: AvenirNextLTPro;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-icon-button-state-layer-color: #3f51b5;
  --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-icon-button-state-layer-color: #ff4081;
  --mat-icon-button-ripple-color: rgba(255, 64, 129, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3f51b5;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #ff4081;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: AvenirNextLTPro;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mdc-snackbar-supporting-text-font: AvenirNextLTPro;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: AvenirNextLTPro;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: AvenirNextLTPro;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: AvenirNextLTPro;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: AvenirNextLTPro;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: AvenirNextLTPro;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: AvenirNextLTPro;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: AvenirNextLTPro;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: AvenirNextLTPro;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: AvenirNextLTPro;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: AvenirNextLTPro;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: AvenirNextLTPro;
  --mat-stepper-header-label-text-font: AvenirNextLTPro;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: AvenirNextLTPro;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: AvenirNextLTPro;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-color: #212121;
  --mdc-snackbar-container-color: #FAFAFA;
  --mdc-snackbar-supporting-text-font: AvenirNextLTPro, serif;
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
  margin: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #E0E0E0;
  --mdc-dialog-subhead-font: AvenirNextLTPro, sans-serif;
  --mdc-dialog-subhead-size: 1.375rem;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-supporting-text-font: AvenirNextLTPro, sans-serif;
  --mdc-dialog-supporting-text-tracking: 0px;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-color: #212121;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding-top: 1.5rem !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  display: flex;
  align-items: center;
  padding: 8px 24px 7px;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 1.5rem;
  background-color: #FFFFFF;
  height: calc(100% - 115px);
  max-height: 75vh;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content h1 {
  display: block;
  margin: 0.5rem 0 0.25rem 0;
  padding: 0;
  border-bottom: 1px solid #E0E0E0;
  font-size: 0.6875rem;
  line-height: 150%;
  font-weight: 500;
  text-transform: uppercase;
  color: #616161;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content section {
  margin: 0.25rem 1rem 1rem 1rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content p {
  font-size: 0.9375rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content ul {
  font-size: 0.875rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content .extra-margin-top {
  margin-top: 1.25rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-content .extra-padding-left {
  padding-left: 1rem;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: 24px;
  padding-bottom: 12px;
  padding-right: 24px;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-size: .625rem;
}

.mat-mdc-tab {
  --mat-tab-header-label-text-tracking: normal;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #E0E0E0;
}

.mat-mdc-tab-group {
  --mat-tab-header-active-label-text-color: #212121;
  --mat-tab-header-active-focus-label-text-color: #212121;
  --mat-tab-header-active-hover-label-text-color: #212121;
  --mat-tab-header-active-focus-indicator-color: #2F9ACD;
  --mat-tab-header-active-hover-indicator-color: #2F9ACD;
  --mdc-tab-indicator-active-indicator-color: #2F9ACD;
  --mat-tab-header-active-ripple-color: #2F9ACD;
  --mat-tab-header-inactive-ripple-color: #2F9ACD;
  --mat-tab-header-inactive-label-text-color: #757575 ;
}

.mat-mdc-tab-header-pagination-controls-enabled button.mat-mdc-tab-header-pagination {
  background-color: unset;
}
.mat-mdc-tab-header-pagination-controls-enabled button.mat-mdc-tab-header-pagination:hover, .mat-mdc-tab-header-pagination-controls-enabled button.mat-mdc-tab-header-pagination:focus {
  background-color: #DFF0F8;
}

.mat-calendar {
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 500;
}
.mat-calendar .mat-calendar-table-header th {
  font-size: 12px;
  color: #616161;
  border-bottom: none;
  text-transform: uppercase;
}
.mat-calendar .mat-mdc-button {
  font-family: inherit;
  font-weight: inherit;
}
.mat-calendar .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #2F9ACD;
  background: #F7FBFD;
}
.mat-calendar .mat-calendar-body-cell-content {
  font-size: 0.75rem;
}
.mat-calendar .mat-calendar-body-label, .mat-calendar .mat-calendar-period-button {
  font-size: 1rem;
}
.mat-calendar .mat-calendar-body-selected {
  border-color: unset;
  background-color: #2F9ACD;
}
.mat-calendar .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar .cdk-keyboard-focused .mat-calendar-body-active .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-calendar .cdk-program-focused .mat-calendar-body-active .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #DFF0F8;
}
.mat-calendar button:hover, .mat-calendar button:focus {
  background-color: unset;
}

.date-time-range-container, .date-range-picker-container, .mat-datepicker-content {
  border-radius: 0 !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.04), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 3px 6px -4px rgba(0, 0, 0, 0.08), inset 0px 4px 0px #2F9ACD;
}

.mat-calendar-table {
  border: unset;
}

.mat-calendar-table-header {
  background-color: unset;
}

date-picker .date-picker-container {
  display: flex;
  max-width: 160px;
  height: 32px;
}
date-picker input[type=text]:hover + mat-datepicker-toggle button.mat-mdc-icon-button {
  color: #2F9ACD;
}
date-picker input[type=text]:focus + mat-datepicker-toggle button.mat-mdc-icon-button {
  color: #24769E;
}
date-picker .mat-datepicker-toggle.mat-datepicker-toggle-active {
  color: #19536E;
}
date-picker .mat-datepicker-toggle button.mat-mdc-icon-button, date-picker .mat-datepicker-toggle button.mdc-icon-button {
  background-color: unset;
  color: #616161;
  line-height: 16px;
  height: 24px;
  width: 24px;
  margin-left: -20px;
  padding: 0;
}
date-picker .mat-datepicker-toggle button.mat-mdc-icon-button mat-icon, date-picker .mat-datepicker-toggle button.mdc-icon-button mat-icon {
  line-height: 16px;
}
date-picker .mat-datepicker-toggle button.mat-mdc-icon-button:hover, date-picker .mat-datepicker-toggle button.mdc-icon-button:hover {
  color: #2F9ACD;
}
date-picker .mat-datepicker-toggle button.mat-mdc-icon-button:focus, date-picker .mat-datepicker-toggle button.mdc-icon-button:focus {
  color: #24769E;
}

.mat-badge-content {
  --mat-badge-background-color: #8EC9E5;
}

.pr-dropdown-overflow-visible .mat-mdc-dialog-content, .pr-dropdown-overflow-visible .mat-mdc-dialog-container, .pr-dropdown-overflow-visible .mat-mdc-dialog-surface,
.pr-dropdown-overflow-visible .mat-mdc-tab-body.mat-mdc-tab-body-active, .pr-dropdown-overflow-visible .mat-mdc-tab-body-content, .pr-dropdown-overflow-visible .mat-mdc-tab-body-wrapper {
  overflow: visible;
}

.view-page .section.pr-dropdown-overflow-visible {
  overflow: visible;
}

.date-range-picker-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1020;
  margin-top: -0.5rem;
  background: #FFFFFF;
  transform: scale(0.9);
}
.date-range-picker-container .date-range-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.75rem 0.25rem 1.75rem;
  border-bottom: 1px solid #BDBDBD;
}
.date-range-picker-container .date-range-picker-header ds-button svg {
  width: 0.75rem;
  height: 0.75rem;
}
.date-range-picker-container .date-range-picker-header ds-button svg.prev-icon-2 {
  margin-left: -0.35rem;
}
.date-range-picker-container .date-range-picker-header ds-button svg.next-icon-2 {
  margin-right: -0.35rem;
}
.date-range-picker-container .date-range-picker-header .next-container, .date-range-picker-container .date-range-picker-header .prev-container {
  display: flex;
  justify-content: space-between;
}
.date-range-picker-container .date-range-picker-header .prev-selectors, .date-range-picker-container .date-range-picker-header .next-selectors {
  display: flex;
  flex-wrap: nowrap;
}
.date-range-picker-container .date-range-picker-header .calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.date-range-picker-container .date-range-picker-header .prev-button button, .date-range-picker-container .date-range-picker-header .next-button button {
  padding-right: 0;
  padding-left: 0;
}
.date-range-picker-container .calendars-container {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem 0 0.5rem;
}
.date-range-picker-container mat-calendar {
  margin: 1rem 0.25rem -1rem 0.25rem;
}
.date-range-picker-container mat-calendar .mat-calendar-header {
  display: none;
}
.date-range-picker-container mat-calendar .mat-calendar-body-cell-container {
  padding: 1rem;
}
.date-range-picker-container mat-calendar .mat-calendar-body-label {
  opacity: 0;
}
.date-range-picker-container mat-calendar .mat-calendar-body-label[colspan="7"] {
  display: none;
}
.date-range-picker-container mat-calendar tbody:first-child {
  text-align: right;
}
.date-range-picker-container mat-calendar .mat-calendar-table-header-divider {
  display: none;
}
.date-range-picker-container mat-calendar .mat-calendar-body-in-range::before {
  background: #DFF0F8;
}
.date-range-picker-container mat-calendar .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
  border-top: dashed 1px;
  border-bottom: dashed 1px;
}
.date-range-picker-container mat-calendar .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  border-left: dashed 1px;
}
[dir=rtl] .date-range-picker-container mat-calendar .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
  border-left: 0;
  border-right: dashed 1px;
}
.date-range-picker-container mat-calendar .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  border-right: dashed 1px;
}
[dir=rtl] .date-range-picker-container mat-calendar .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  border-right: 0;
  border-left: dashed 1px;
}

date-range-picker .date-range-picker-form, date-range-picker .date-range-inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
date-range-picker .date-range-picker-form {
  max-width: 216px;
  border-bottom: 1px solid #757575;
}
date-range-picker .date-range-picker-form.has-error {
  border-bottom: 1px solid #DF5146;
}
date-range-picker .date-range-picker-form.has-error:hover:not(:disabled) {
  background-color: #FEF8F8;
}
date-range-picker .date-range-picker-form.has-error:hover:not(:disabled) ds-button button {
  color: #DF5146;
}
date-range-picker .date-range-picker-form:hover:not(:disabled) {
  background-color: #F7FBFD;
}
date-range-picker .date-range-picker-form:hover:not(:disabled) ds-button button {
  color: #24769E;
}
date-range-picker .date-range-picker label.date-range-error, date-range-picker .date-range-picker ticket-add-edit-modal dt.date-range-error, ticket-add-edit-modal date-range-picker .date-range-picker dt.date-range-error, date-range-picker .date-range-picker add-edit-ticket-modal dt.date-range-error, add-edit-ticket-modal date-range-picker .date-range-picker dt.date-range-error {
  font-size: 12px;
  color: #DF5146;
  margin-top: 8px;
}
date-range-picker .date-range-picker input {
  width: 80px;
  text-align: center;
  border-bottom: none;
  background-color: transparent;
}
date-range-picker .date-range-picker input:hover:not(:disabled), date-range-picker .date-range-picker input:focus:not(:disabled), date-range-picker .date-range-picker input.ng-invalid.ng-touched:not(:focus):not(form):not(.dont-add-error-border) {
  border-bottom: none;
  background-color: transparent;
}
date-range-picker .date-range-picker span {
  margin: 0 2px;
}
date-range-picker .date-range-picker svg.calendar-icon {
  width: 12px;
  height: 12px;
}
date-range-picker .next-container, date-range-picker .prev-container {
  width: 32%;
}
date-range-picker ds-button button {
  padding-right: 0 !important;
}
date-range-picker ds-button.active button {
  color: #19536E;
}

date-range-selector .range-selector-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}
date-range-selector date-range-calendars {
  margin: -1rem -1.59375rem -1rem -1.59375rem;
}
date-range-selector ul.date-range-controls {
  display: flex;
  white-space: nowrap;
  margin: 0 0.75rem 0 0;
  z-index: 10;
}
date-range-selector ul.date-range-controls li:first-of-type {
  border-radius: 0.125rem 0 0 0.125rem;
}
date-range-selector ul.date-range-controls li:last-of-type {
  border-radius: 0 0.125rem 0.125rem 0;
}
date-range-selector .date-range-displays {
  display: flex;
  align-items: center;
}
date-range-selector.dashboards .date-range-calendars-dropdown .date-time-range-container {
  top: 3.25rem;
}
date-range-selector.edit-chart-template .range-selector-container {
  margin: 0.5rem 0 0 0;
}
date-range-selector.edit-chart-template .date-range-calendars-dropdown .date-time-range-container {
  top: 4.25rem;
}
date-range-selector .date-range-calendars-dropdown {
  display: flex;
  align-items: center;
}
date-range-selector .date-range-calendars-dropdown span.range-label {
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}
date-range-selector .date-range-calendars-dropdown svg {
  width: 0.75rem;
  height: 0.75rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  top: 2.25rem;
  background: #FFFFFF;
  z-index: 1020;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars {
  display: flex;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars .date-range-picker-container {
  position: revert;
  box-shadow: none;
  box-sizing: border-box;
  background: revert;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars .date-range-picker-container .next-container, date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars .date-range-picker-container .prev-container {
  width: 30%;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars .date-range-picker-container .prev-selectors button {
  padding-right: 1rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-range-calendars .date-range-picker-container .next-selectors button {
  padding-right: 0;
  padding-left: 1rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.25rem;
  padding: 0 1rem;
  border-left: 1px solid #BDBDBD;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container input {
  text-align: center;
  width: 3vw;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container {
  display: flex;
  flex-direction: column;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container label.date-range-error, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container ticket-add-edit-modal dt.date-range-error, ticket-add-edit-modal date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container dt.date-range-error, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container add-edit-ticket-modal dt.date-range-error, add-edit-ticket-modal date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container dt.date-range-error, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container label.date-range-error, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container ticket-add-edit-modal dt.date-range-error, ticket-add-edit-modal date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container dt.date-range-error, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container add-edit-ticket-modal dt.date-range-error, add-edit-ticket-modal date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container dt.date-range-error {
  font-size: 0.5rem;
  color: #DF5146;
  margin-top: 0.25rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container span, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container span {
  font-size: 0.75rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container span.date, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container span.date {
  font-weight: 500;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container .time-begin-controls, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container .time-end-controls, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container .time-begin-controls, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container .time-end-controls {
  display: flex;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container .time-begin-controls span, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-begin-container .time-end-controls span, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container .time-begin-controls span, date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container .time-end-controls span {
  margin: 0 0.5rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .time-container .time-end-container {
  margin-top: 1rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-controls {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #BDBDBD;
  padding: 1rem 0.75rem;
}
date-range-selector .date-range-calendars-dropdown .date-time-range-container .date-time-controls button {
  margin: 0 0.25rem;
}
date-range-selector .date-range-display {
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0 0.5rem;
  font-family: "AvenirNextLTPro", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
}
date-range-selector .date-range-display .prev-period button {
  padding-right: 0;
}
date-range-selector .date-range-display ds-button svg {
  width: 0.75rem;
  height: 0.75rem;
  color: #212121;
}
date-range-selector .date-range-display span {
  margin: 0 0.25rem 0 0.25rem;
  white-space: nowrap;
}
date-range-selector .date-range-picker-container {
  margin-top: 0;
  left: 5.5rem;
}

date-range-dropdown div[pr-dropdown-body] {
  margin-top: -4px;
  border-top: 4px solid #2F9ACD;
}
date-range-dropdown ul.date-range-controls {
  display: flex;
  justify-content: center;
  margin: 12px 8px 12px 8px;
}
date-range-dropdown ul.date-range-controls .range-pr-button {
  white-space: nowrap;
}
date-range-dropdown .date-range-picker-container {
  margin-top: -16px;
  position: revert;
  box-shadow: none;
  box-sizing: border-box;
  background: revert;
}
date-range-dropdown .date-range-picker-container .calendars-container {
  padding: 0;
}
date-range-dropdown .date-range-picker-container .calendars-container .mat-calendar-content {
  padding-bottom: 0;
}
date-range-dropdown .date-range-picker-container .next-container, date-range-dropdown .date-range-picker-container .prev-container {
  width: 30%;
}
date-range-dropdown .date-range-picker-container .next-selectors button {
  padding-right: 0;
  padding-left: 16px;
}

/**
  Overrides for mat-select
 */
.mat-mdc-select {
  font-family: inherit;
  font-weight: 500;
  border-bottom: 1px solid #BFBFBF;
}

.mat-mdc-option {
  --mat-option-hover-state-layer-color: #DFF0F8;
  --mat-option-label-text-tracking: normal;
}

.autocomplete-input-panel.mat-mdc-autocomplete-panel {
  border-top: 2px solid #2F9ACD;
  padding: 0;
}

.fa-angle-down::before,
.fa-columns::before {
  font-weight: 300;
}